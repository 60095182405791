"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterSection = function FilterSection(_ref) {
  var children = _ref.children,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("SearchFiltersFilterSection");

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      {
        className: getClassName({
          descendantName: "title",
          utilities: "typographySmallCaps"
        })
      },
      title
    ),
    _react2.default.createElement(
      "ul",
      { className: getClassName({ descendantName: "items" }) },
      children
    )
  );
};

FilterSection.displayName = "SearchFilters.FilterSection";

FilterSection.propTypes = {
  /**
   * The children of this section. Should be a `SearchFilters.FilterItem` or `SearchFilters.FilterGroup`.
   */
  children: _propTypes2.default.node,

  /**
   * The title of this group.
   */
  title: _propTypes2.default.string
};

exports.default = FilterSection;