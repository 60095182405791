"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _NavList = require("./components/NavList");

var _NavList2 = _interopRequireDefault(_NavList);

var _NavItem = require("./components/NavItem");

var _NavItem2 = _interopRequireDefault(_NavItem);

var _SiteFooterSearch = require("./SiteFooterSearch");

var _SiteFooterSearch2 = _interopRequireDefault(_SiteFooterSearch);

var _setOpenMenuContext = require("./setOpenMenuContext");

var _currentMenuContext = require("./currentMenuContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SiteFooter = function (_Component) {
  _inherits(SiteFooter, _Component);

  /* eslint-disable max-len */
  function SiteFooter(props) {
    _classCallCheck(this, SiteFooter);

    var _this = _possibleConstructorReturn(this, (SiteFooter.__proto__ || Object.getPrototypeOf(SiteFooter)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(SiteFooter.displayName);

    _this.state = {
      currentMenu: null
    };

    _this.setOpenMenu = _this.setOpenMenu.bind(_this);
    return _this;
  }
  /* eslint-enable max-len */

  _createClass(SiteFooter, [{
    key: "setOpenMenu",
    value: function setOpenMenu(menuId) {
      this.setState({
        currentMenu: menuId
      });
    }
  }, {
    key: "render",
    value: function render() {
      var children = this.props.children;


      return _react2.default.createElement(
        _setOpenMenuContext.Provider,
        { value: this.setOpenMenu },
        _react2.default.createElement(
          _currentMenuContext.Provider,
          { value: this.state.currentMenu },
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName(),
              "data-search-form-action": this.props.searchFormAction,
              "data-search-form-method": this.props.searchFormMethod,
              "data-search-input-name": this.props.searchInputName,
              "data-typeahead-url": this.props.typeaheadUrl
            },
            _react2.default.createElement(
              "div",
              { className: this.getClassName({ descendantName: "inner" }) },
              _react2.default.createElement(_SiteFooterSearch2.default, {
                getClassName: this.getClassName,
                searchFormAction: this.props.searchFormAction,
                searchFormMethod: this.props.searchFormMethod,
                searchInputName: this.props.searchInputName,
                typeaheadUrl: this.props.typeaheadUrl
              }),
              _react2.default.createElement(
                "nav",
                { className: this.getClassName({ descendantName: "lists" }) },
                _react2.default.createElement(
                  "ul",
                  {
                    className: this.getClassName({
                      descendantName: "listsInner"
                    })
                  },
                  children
                )
              )
            )
          )
        )
      );
    }
  }]);

  return SiteFooter;
}(_react.Component);

SiteFooter.displayName = "SiteFooter";
SiteFooter.propTypes = {
  /**
   * The SiteFooter contents. These should be `SiteFooter.List`s
   */
  children: _propTypes2.default.node,

  /**
   * Whether or not the component is rehydrating. Do not use this.
   */
  isRehydrating: _propTypes2.default.bool,

  /**
   * Search form action
   */
  searchFormAction: _propTypes2.default.string.isRequired,

  /**
   * Search form method
   */
  searchFormMethod: _propTypes2.default.string,

  /**
   * Search input name
   */
  searchInputName: _propTypes2.default.string,

  /**
   * Search Typeahead URL
   */
  typeaheadUrl: _propTypes2.default.string.isRequired
};
SiteFooter.defaultProps = {
  isRehydrating: false,
  searchFormMethod: "get",
  searchInputName: "q"
};


SiteFooter.NavList = _NavList2.default;
SiteFooter.NavItem = _NavItem2.default;

exports.default = SiteFooter;