"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _cornerRightRound = require("@wel-ui/icons/lib/ui/corner-right-round");

var _cornerRightRound2 = _interopRequireDefault(_cornerRightRound);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SocialDropdown = function (_Component) {
  _inherits(SocialDropdown, _Component);

  function SocialDropdown(props) {
    _classCallCheck(this, SocialDropdown);

    var _this = _possibleConstructorReturn(this, (SocialDropdown.__proto__ || Object.getPrototypeOf(SocialDropdown)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(SocialDropdown.displayName);

    _this.state = {
      expanded: false
    };

    _this.handleBlur = _this.handleBlur.bind(_this);
    _this.handleButtonClicked = _this.handleButtonClicked.bind(_this);
    _this.handleRef = _this.handleRef.bind(_this);
    return _this;
  }

  _createClass(SocialDropdown, [{
    key: "handleButtonClicked",
    value: function handleButtonClicked() {
      this.setState({
        expanded: !this.state.expanded
      });
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
        return;
      }

      this.setState({
        expanded: false
      });
    }
  }, {
    key: "handleRef",
    value: function handleRef(ref) {
      this.ref = ref;
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            states: (0, _classnames2.default)({ expanded: this.state.expanded })
          }),
          onBlur: this.handleBlur,
          ref: this.handleRef
        },
        _react2.default.createElement(
          "button",
          {
            className: this.getClassName({ descendantName: "button" }),
            onClick: this.handleButtonClicked
          },
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "buttonInner" }) },
            _react2.default.createElement(_cornerRightRound2.default, null)
          )
        ),
        _react2.default.createElement(
          "ul",
          { className: this.getClassName({ descendantName: "items" }) },
          this.props.children
        )
      );
    }
  }]);

  return SocialDropdown;
}(_react.Component);

SocialDropdown.displayName = "SocialDropdown";
SocialDropdown.propTypes = {
  /**
   * Child nodes, made up of <SocialDropdown.Item />.
   */
  children: _propTypes2.default.node
};


SocialDropdown.Item = _Item2.default;

exports.default = SocialDropdown;