"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = exports.loginRehydrator = exports.cartRehydrator = exports.baseItemRehydrator = undefined;

var _BaseItem = require("./components/BaseItem/BaseItem.rehydrator");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _Cart = require("./components/Cart/Cart.rehydrator");

var _Cart2 = _interopRequireDefault(_Cart);

var _GlobalHeader = require("./GlobalHeader");

var _GlobalHeader2 = _interopRequireDefault(_GlobalHeader);

var _Login = require("./components/Login/Login.rehydrator");

var _Login2 = _interopRequireDefault(_Login);

var _GlobalHeader3 = require("./GlobalHeader.rehydrator");

var _GlobalHeader4 = _interopRequireDefault(_GlobalHeader3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableGlobalHeader = (0, _componentRehydratable2.default)(_GlobalHeader2.default);

RehydratableGlobalHeader.BaseItem = _GlobalHeader2.default.BaseItem;
RehydratableGlobalHeader.Cart = _GlobalHeader2.default.Cart;
RehydratableGlobalHeader.Column = _GlobalHeader2.default.Column;
RehydratableGlobalHeader.Item = _GlobalHeader2.default.Item;
RehydratableGlobalHeader.Login = _GlobalHeader2.default.Login;
RehydratableGlobalHeader.NavList = _GlobalHeader2.default.NavList;
RehydratableGlobalHeader.NavItem = _GlobalHeader2.default.NavItem;

exports.default = RehydratableGlobalHeader;
exports.baseItemRehydrator = _BaseItem2.default;
exports.cartRehydrator = _Cart2.default;
exports.loginRehydrator = _Login2.default;
exports.rehydrator = _GlobalHeader4.default;