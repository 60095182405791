"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getFormattedMoment = function getFormattedMoment(theMoment, showTime) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  var formatToUse = void 0;

  // If user specifies showTime and uses default format, render time
  if (showTime && format === "LL") {
    formatToUse = "LLL";
  } else {
    formatToUse = format;
  }

  return theMoment.format(formatToUse);
};

var DateTime = function DateTime(_ref) {
  var currentTime = _ref.currentTime,
      dateTime = _ref.dateTime,
      format = _ref.format,
      lang = _ref.lang,
      relative = _ref.relative,
      showTime = _ref.showTime;

  var theMoment = (0, _moment2.default)(dateTime);

  if (lang) {
    theMoment.locale(lang);
  }

  var content = void 0;

  if (relative) {
    content = theMoment.from(currentTime);
  } else {
    content = getFormattedMoment(theMoment, showTime, format);
  }

  return _react2.default.createElement(
    "time",
    { dateTime: dateTime },
    content
  );
};

DateTime.displayName = "DateTime";

/* eslint-disable max-len */
DateTime.propTypes = {
  /**
   * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time. Defaults to the time that the current page loaded; you should set this if you want the time to dynamically update as time progresses.
   */
  currentTime: _propTypes2.default.string,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: _propTypes2.default.string.isRequired,
  /**
   * A [moment.js-compatible format](http://momentjs.com/docs/#/displaying/). Use is discouraged. Default switches between `LL` and `LLL` based on `showTime` property.
   */
  format: _propTypes2.default.string,
  /**
   * IETF Language code, i.e. `en`, `fr`, `en-NZ`. Default is [moment.js global language](http://momentjs.com/docs/#/i18n/).
   */
  lang: _propTypes2.default.string,
  /**
   * Show relative time instead of a formatted date, i.e. 4 minutes ago.
   */
  relative: _propTypes2.default.bool,
  /**
   * Show or hide time in the output.
   */
  showTime: _propTypes2.default.bool
};
/* eslint-enable max-len */

DateTime.defaultProps = {
  currentTime: new Date().toISOString(),
  format: "LL",
  lang: "en",
  showTime: false
};

exports.default = DateTime;