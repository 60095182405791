"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _SiteHeader = require("./SiteHeader");

var _SiteHeader2 = _interopRequireDefault(_SiteHeader);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_SiteHeader2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("SiteHeader");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "menus" }));
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var logoLinkNode = domNode.querySelector("a." + getClassName({ descendantName: "logo" }));
    var siteNameNode = domNode.querySelector("." + getClassName({ descendantName: "siteName" }));

    var props = {
      logoHref: logoLinkNode.href,
      logoText: logoLinkNode.textContent,
      search: domNode.getAttribute("data-search") === "true",
      searchFormAction: domNode.getAttribute("data-search-form-action") || null,
      searchFormMethod: domNode.getAttribute("data-search-form-method"),
      searchInputName: domNode.getAttribute("data-search-input-name"),
      siteName: siteNameNode ? siteNameNode.innerText : null,
      typeaheadUrl: domNode.getAttribute("data-typeahead-url") || null,
      isRehydrating: true
    };

    return _react2.default.createElement(
      _SiteHeader2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;