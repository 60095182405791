"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _QuoteCard = require("./QuoteCard");

var _QuoteCard2 = _interopRequireDefault(_QuoteCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _QuoteCard2.default;