"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _PrivacyControl = require("./PrivacyControl");

var _PrivacyControl2 = _interopRequireDefault(_PrivacyControl);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_PrivacyControl2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("Form.PrivacyControl");

    var consentTextNode = domNode.querySelector("." + getClassName({ descendantName: "consent" }));

    var consentText = (0, _componentRehydratable.rehydrateChildren)(consentTextNode, extra);
    var props = {
      compliantCountries: JSON.parse(domNode.getAttribute("data-compliant-countries")),
      consentName: domNode.getAttribute("data-consent-name"),
      consentText: consentText,
      dependsOn: domNode.getAttribute("data-depends-on"),
      explicitConsentText: domNode.getAttribute("data-explicit-consent-text"),
      isRehydrating: true
    };

    return _react2.default.createElement(_PrivacyControl2.default, props);
  }
};

exports.default = rehydrator;