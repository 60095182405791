"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentForm = require("@wel-ui/component-form");

var _componentForm2 = _interopRequireDefault(_componentForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PrivacyControl = function (_Component) {
  _inherits(PrivacyControl, _Component);

  function PrivacyControl(props) {
    _classCallCheck(this, PrivacyControl);

    var _this = _possibleConstructorReturn(this, (PrivacyControl.__proto__ || Object.getPrototypeOf(PrivacyControl)).call(this, props));

    _this.state = {
      hasGivenExplicitConsent: "undefined",
      showExplicitConsent: false
    };

    _this.myRef = _react2.default.createRef();

    _this.getClassName = (0, _utilityClassNames2.default)(PrivacyControl.displayName);

    _this.getCountryDropdown = _this.getCountryDropdown.bind(_this);
    _this.handleCountryChange = _this.handleCountryChange.bind(_this);
    _this.handleExplicitConsentChange = _this.handleExplicitConsentChange.bind(_this);
    return _this;
  }

  _createClass(PrivacyControl, [{
    key: "getCountryDropdown",
    value: function getCountryDropdown() {
      var name = this.props.dependsOn;

      var countryDropdown = this.myRef.current.closest("form").querySelector("[name=" + name + "]");

      if (countryDropdown === null) {
        throw new Error("Could not find the country dropdown specified in dependsOn prop");
      }

      return countryDropdown;
    }
  }, {
    key: "handleCountryChange",
    value: function handleCountryChange(event) {
      var selectedCountry = event.target.value;

      if (this.props.compliantCountries.includes(selectedCountry)) {
        this.setState({
          hasGivenExplicitConsent: false,
          showExplicitConsent: true
        });
      } else {
        this.setState({
          hasGivenExplicitConsent: "undefined",
          showExplicitConsent: false
        });
      }
    }
  }, {
    key: "handleExplicitConsentChange",
    value: function handleExplicitConsentChange(event) {
      var target = event.target;
      var value = target.checked;

      this.setState({
        hasGivenExplicitConsent: value
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var countryDropdown = this.getCountryDropdown();

      countryDropdown.addEventListener("change", this.handleCountryChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      var countryDropdown = this.getCountryDropdown();

      countryDropdown.removeEventListener("change", this.handleCountryChange);
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          compliantCountries = _props.compliantCountries,
          consentName = _props.consentName,
          consentText = _props.consentText,
          dependsOn = _props.dependsOn,
          explicitConsentText = _props.explicitConsentText;
      var _state = this.state,
          hasGivenExplicitConsent = _state.hasGivenExplicitConsent,
          showExplicitConsent = _state.showExplicitConsent;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName(),
          "data-compliant-countries": JSON.stringify(compliantCountries),
          "data-consent-name": consentName,
          "data-depends-on": dependsOn,
          "data-explicit-consent-text": explicitConsentText,
          "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(PrivacyControl),
          "data-rehydratable-in-place": true,
          ref: this.myRef
        },
        showExplicitConsent ? _react2.default.createElement(_componentForm2.default.CheckboxControl, {
          checked: hasGivenExplicitConsent,
          id: "explicitConsentCheckbox",
          labelText: explicitConsentText,
          onChange: this.handleExplicitConsentChange
        }) : _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "consent" }) },
          consentText
        ),
        _react2.default.createElement("input", {
          name: consentName,
          type: "hidden",
          value: hasGivenExplicitConsent
        })
      );
    }
  }]);

  return PrivacyControl;
}(_react.Component);

PrivacyControl.displayName = "Form.PrivacyControl";
PrivacyControl.defaultProps = {
  consentName: "consent"
};
PrivacyControl.propTypes = {
  /**
   * List of all countries that require strict privacy control
   */
  compliantCountries: _propTypes2.default.array.isRequired,
  /**
   * Name attribute of the hidden input that passes the consent value
   */
  consentName: _propTypes2.default.string.isRequired,
  /**
   * Default consent message
   */
  consentText: _propTypes2.default.node.isRequired,
  /**
   * Name attribute of the country dropdown Privacy Control depends on
   */
  dependsOn: _propTypes2.default.string.isRequired,
  /**
   * Explicitly opt-in consent for GDPR-affected countries
   */
  explicitConsentText: _propTypes2.default.string.isRequired
};
exports.default = PrivacyControl;