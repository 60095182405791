"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _CardBase = require("../CardBase");

var _CardBase2 = _interopRequireDefault(_CardBase);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

var _componentHeading = require("@wel-ui/component-heading");

var _componentHeading2 = _interopRequireDefault(_componentHeading);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var TextCardBase = function TextCardBase(_ref) {
  var anchor = _ref.anchor,
      borderless = _ref.borderless,
      description = _ref.description,
      eyebrow = _ref.eyebrow,
      footer = _ref.footer,
      heading = _ref.heading,
      headingRank = _ref.headingRank,
      href = _ref.href,
      largeHeading = _ref.largeHeading,
      size = _ref.size,
      renderContent = _ref.renderContent;

  var getClassName = (0, _utilityClassNames2.default)(TextCardBase.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)(_defineProperty({}, anchor, true))
      })
    },
    _react2.default.createElement(
      _CardBase2.default,
      { anchor: anchor, borderless: borderless, href: href, size: size },
      renderContent(_react2.default.createElement(
        "div",
        {
          className: getClassName({
            descendantName: "content"
          })
        },
        eyebrow ? _react2.default.createElement(
          "div",
          {
            className: getClassName({
              descendantName: "eyebrow"
            })
          },
          _react2.default.createElement(_componentEyebrow2.default, { text: eyebrow })
        ) : null,
        _react2.default.createElement(
          "div",
          {
            className: getClassName({
              descendantName: "body"
            })
          },
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "heading" }) },
            _react2.default.createElement(
              _componentHeading2.default,
              { rank: headingRank, type: largeHeading ? "l" : "xs" },
              _react2.default.createElement(
                "strong",
                null,
                heading
              )
            )
          ),
          description ? _react2.default.createElement(
            "div",
            {
              className: getClassName({ descendantName: "description" })
            },
            description
          ) : null
        ),
        footer ? _react2.default.createElement(
          "div",
          {
            className: getClassName({
              descendantName: "footer"
            })
          },
          footer
        ) : null
      ))
    )
  );
};

TextCardBase.displayName = "TextCardBase";

TextCardBase.sizes = _CardBase2.default.sizes;
TextCardBase.headingRanks = _componentHeading2.default.ranks;

/* eslint-disable max-len */
TextCardBase.propTypes = {
  /**
   * Anchor the eyebrow, heading and description to the top or bottom of the card.
   */
  anchor: _propTypes2.default.oneOf(["bottom", "top"]),
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Description text
   */
  description: _propTypes2.default.node,
  /**
   * Eyebrow text
   */
  eyebrow: _propTypes2.default.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: _propTypes2.default.node,
  /**
   * Heading text
   */
  heading: _propTypes2.default.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: _propTypes2.default.oneOf(TextCardBase.headingRanks),
  /**
   * Card URL
   */
  href: _propTypes2.default.string,
  /**
   * Enable large heading font size
   */
  largeHeading: _propTypes2.default.bool,
  /**
   * A method to wrap the rendered card content. Use to apply a different visual treatment to the content, such as adding a background image. Usage: `renderContent={content => {<div>{content}</div>}}`.
   */
  renderContent: _propTypes2.default.func,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: _propTypes2.default.oneOf(TextCardBase.sizes).isRequired
};
/* eslint-enable max-len */

TextCardBase.defaultProps = {
  anchor: "top",
  headingRank: "3",
  renderContent: function renderContent(content) {
    return content;
  } // eslint-disable-line react/display-name
};

exports.default = TextCardBase;