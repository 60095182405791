"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactTwitterWidgets = require("react-twitter-widgets");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/*
 * This is broken out from TwitterCard, so that we don't have an extra
 * `<div>` wrapping the whole card - which would interfere with Grid, and
 * result in a card that has a different height from the other cards.
 *
 * It also means that rehydration is scoped to only the part of the TwitterCard
 * that actually needs rehydrating.
 */
var TwitterCardTimeline = function (_Component) {
  _inherits(TwitterCardTimeline, _Component);

  function TwitterCardTimeline(props) {
    _classCallCheck(this, TwitterCardTimeline);

    var _this = _possibleConstructorReturn(this, (TwitterCardTimeline.__proto__ || Object.getPrototypeOf(TwitterCardTimeline)).call(this, props));

    _this.state = {
      isMounted: false
    };
    return _this;
  }

  _createClass(TwitterCardTimeline, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.setState({
        isMounted: true
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          timelineDataSource = _props.timelineDataSource,
          timelineOptions = _props.timelineOptions;


      return _react2.default.createElement(
        "div",
        {
          "data-timeline-data-source": JSON.stringify(timelineDataSource),
          "data-timeline-options": JSON.stringify(timelineOptions)
        },
        this.state.isMounted ? _react2.default.createElement(_reactTwitterWidgets.Timeline, { dataSource: timelineDataSource, options: timelineOptions }) : null
      );
    }
  }]);

  return TwitterCardTimeline;
}(_react.Component);

TwitterCardTimeline.propTypes = {
  /**
   * Data source for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
   */
  timelineDataSource: _propTypes2.default.object.isRequired,
  /**
   * Display options for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
   */
  timelineOptions: _propTypes2.default.object
};
TwitterCardTimeline.displayName = "TwitterCardTimeline";
exports.default = TwitterCardTimeline;