"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _RehydratableVideoPlayerCore = require("./RehydratableVideoPlayerCore");

var _RehydratableVideoPlayerCore2 = _interopRequireDefault(_RehydratableVideoPlayerCore);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var VideoPlayer = function VideoPlayer(_ref) {
  var description = _ref.description,
      duration = _ref.duration,
      name = _ref.name,
      thumbnailUrl = _ref.thumbnailUrl,
      uploadDate = _ref.uploadDate,
      videoProps = _objectWithoutProperties(_ref, ["description", "duration", "name", "thumbnailUrl", "uploadDate"]);

  var getClassName = (0, _utilityClassNames2.default)(VideoPlayer.displayName);

  var jsonLd = (description || duration || name || thumbnailUrl || uploadDate) && {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    description: description,
    duration: duration,
    name: name,
    thumbnailUrl: thumbnailUrl,
    uploadDate: uploadDate
  };

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    jsonLd && _react2.default.createElement(
      "script",
      { type: "application/ld+json" },
      JSON.stringify(jsonLd)
    ),
    _react2.default.createElement(_RehydratableVideoPlayerCore2.default, _extends({ duration: duration }, videoProps))
  );
};

VideoPlayer.propTypes = _extends({
  /**
   * A short description of the video.  Schema.org only - not surfaced
   * visually. For more details, see http://schema.org/video.
   */
  description: _propTypes2.default.string,

  /**
   * The length of the video, as an ISO 8601 duration (see
   * https://en.wikipedia.org/wiki/ISO_8601). Schema.org and surfaced
   * visually in a (hh:)mm:ss format. For more details, see http://schema.org/video.
   */
  duration: _propTypes2.default.string.isRequired,

  /**
   * The name of the video. Schema.org only - not surfaced visually.
   * For more details, see http://schema.org/video.
   */
  name: _propTypes2.default.string,

  /**
   * The URL for the thumbnail. Schema.org only - not surfaced visually.
   * For more details, see http://schema.org/video.
   */
  thumbnailUrl: _propTypes2.default.string,

  /**
   * The date the video was uploaded. Schema.org only - not surfaced visually.
   * For more details, see http://schema.org/video.
   */
  uploadDate: _propTypes2.default.string

}, _RehydratableVideoPlayerCore2.default.propTypes);

VideoPlayer.displayName = "VideoPlayer";

exports.default = VideoPlayer;