"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseItemRehydrator = undefined;

var _BaseItem = require("./BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _BaseItem3 = require("./BaseItem.rehydrator");

var _BaseItem4 = _interopRequireDefault(_BaseItem3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BaseItem2.default;
exports.baseItemRehydrator = _BaseItem4.default;