"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ChapterDivider = require("./ChapterDivider");

var _ChapterDivider2 = _interopRequireDefault(_ChapterDivider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ChapterDivider2.default;