"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

var _componentStandfirst = require("@wel-ui/component-standfirst");

var _componentStandfirst2 = _interopRequireDefault(_componentStandfirst);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Header = function Header(_ref) {
  var align = _ref.align,
      callToAction = _ref.callToAction,
      eyebrow = _ref.eyebrow,
      heading = _ref.heading,
      standfirst = _ref.standfirst;

  var getClassName = (0, _utilityClassNames2.default)(Header.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)(_defineProperty({}, align, align !== "left"))
      })
    },
    eyebrow && _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "eyebrow" }) },
      _react2.default.createElement(_componentEyebrow2.default, { text: eyebrow })
    ),
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "heading" }) },
      heading
    ),
    standfirst && _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "standfirst" }) },
      _react2.default.createElement(
        _componentStandfirst2.default,
        null,
        standfirst
      )
    ),
    callToAction && _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "callToAction" }) },
      callToAction
    )
  );
};

Header.alignments = ["center", "left"];

Header.defaultProps = { align: "left" };

Header.displayName = "Header";

Header.propTypes = {
  align: _propTypes2.default.oneOf(Header.alignments),
  eyebrow: _propTypes2.default.string,
  heading: _propTypes2.default.node.isRequired,
  callToAction: _propTypes2.default.node,
  standfirst: _propTypes2.default.node
};

exports.default = Header;