"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _downshift = require("downshift");

var _downshift2 = _interopRequireDefault(_downshift);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MultiDownshift = function (_React$Component) {
  _inherits(MultiDownshift, _React$Component);

  function MultiDownshift() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MultiDownshift);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MultiDownshift.__proto__ || Object.getPrototypeOf(MultiDownshift)).call.apply(_ref, [this].concat(args))), _this), _this.state = { selectedItems: [] }, _this.stateReducer = function (state, changes) {
      switch (changes.type) {
        case _downshift2.default.stateChangeTypes.keyDownEnter:
        case _downshift2.default.stateChangeTypes.clickItem:
          return _extends({}, changes, {
            highlightedIndex: state.highlightedIndex,
            isOpen: true
          });
        default:
          return changes;
      }
    }, _this.handleSelection = function (selectedItem, downshift) {
      var callOnChange = function callOnChange() {
        var onChange = _this.props.onChange;
        var selectedItems = _this.state.selectedItems;


        if (onChange) {
          onChange(selectedItems, _this.getStateAndHelpers(downshift));
        }
      };

      if (_this.state.selectedItems.some(function (i) {
        return _this.clean(i) === _this.clean(selectedItem);
      })) {
        _this.removeItem(selectedItem, callOnChange);
      } else {
        _this.addSelectedItem(selectedItem, callOnChange);
      }
    }, _this.clean = function (item) {
      var itemToString = _this.props.itemToString;


      return itemToString ? itemToString(item) : item;
    }, _this.removeItem = function (item, cb) {
      var onChange = _this.props.onChange;

      var callback = function callback() {
        var selectedItems = _this.state.selectedItems;


        if (cb) {
          cb();

          return;
        }

        // try to call onChange
        if (onChange) {
          onChange(selectedItems);
        }
      };

      _this.setState(function (_ref2) {
        var selectedItems = _ref2.selectedItems;

        return {
          selectedItems: selectedItems.filter(function (i) {
            return _this.clean(i) !== _this.clean(item);
          })
        };
      }, callback);
    }, _this.getRemoveButtonProps = function () {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _onClick = _ref3.onClick,
          item = _ref3.item,
          props = _objectWithoutProperties(_ref3, ["onClick", "item"]);

      return _extends({
        onClick: function onClick(e) {
          if (_onClick) {
            _onClick(e);
          }
          e.stopPropagation();
          _this.removeItem(item);
        }
      }, props);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MultiDownshift, [{
    key: "addSelectedItem",
    value: function addSelectedItem(item, cb) {
      this.setState(function (_ref4) {
        var selectedItems = _ref4.selectedItems;
        return {
          selectedItems: [].concat(_toConsumableArray(selectedItems), [item])
        };
      }, cb);
    }
  }, {
    key: "getStateAndHelpers",
    value: function getStateAndHelpers(downshift) {
      var selectedItems = this.state.selectedItems;
      var getRemoveButtonProps = this.getRemoveButtonProps,
          removeItem = this.removeItem;


      return _extends({
        getRemoveButtonProps: getRemoveButtonProps,
        removeItem: removeItem,
        selectedItems: selectedItems
      }, downshift);
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          render = _props.render,
          _props$children = _props.children,
          children = _props$children === undefined ? render : _props$children,
          props = _objectWithoutProperties(_props, ["render", "children"]);

      var selectedItems = this.state.selectedItems;


      return _react2.default.createElement(
        _downshift2.default,
        _extends({}, props, {
          stateReducer: this.stateReducer,
          onChange: this.handleSelection,
          selectedItem: selectedItems
        }),
        function (downshift) {
          return children(_this2.getStateAndHelpers(downshift));
        }
      );
    }
  }]);

  return MultiDownshift;
}(_react2.default.Component);

MultiDownshift.displayName = "TagFilter.MultDownshift";

MultiDownshift.propTypes = {
  children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
  itemToString: _propTypes2.default.func.isRequired,
  onChange: _propTypes2.default.func,
  render: _propTypes2.default.func
};

exports.default = MultiDownshift;