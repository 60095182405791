"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentLink = require("@wel-ui/component-link");

var _componentLink2 = _interopRequireDefault(_componentLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Table = function Table(_ref) {
  var data = _ref.data;

  var getClassName = (0, _utilityClassNames2.default)(Table.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "table",
      null,
      data.caption && data.caption.content && _react2.default.createElement(
        "caption",
        null,
        data.caption.content
      ),
      data.head && data.head.rows && data.head.rows.length && _react2.default.createElement(
        "thead",
        null,
        data.head.rows.map(function (row, indexRow) {
          return _react2.default.createElement(
            "tr",
            {
              key: (typeof row.key === "undefined" ? indexRow : row.key).toString()
            },
            row.cells && row.cells.map(function (cell, indexCell) {
              return _react2.default.createElement(
                "th",
                {
                  key: (typeof cell.key === "undefined" ? indexCell : cell.key).toString()
                },
                cell.content
              );
            })
          );
        })
      ),
      data.bodies && data.bodies.length && data.bodies.map(function (body, indexBody) {
        return _react2.default.createElement(
          "tbody",
          {
            key: (typeof body.key === "undefined" ? indexBody : body.key).toString()
          },
          body.rows && body.rows.length && body.rows.map(function (row, indexRow) {
            return row.hidden !== true && _react2.default.createElement(
              "tr",
              {
                key: (typeof row.key === "undefined" ? indexRow : row.key).toString()
              },
              row.cells && row.cells.map(function (cell, indexCell) {
                return _react2.default.createElement(
                  "td",
                  {
                    key: (typeof cell.key === "undefined" ? indexCell : cell.key).toString()
                  },
                  cell.link && cell.link.href ? _react2.default.createElement(
                    _componentLink2.default,
                    { href: cell.link.href },
                    cell.content
                  ) : cell.content
                );
              })
            );
          })
        );
      })
    )
  );
};

Table.displayName = "TableWithFilters.Table";

Table.propTypes = {
  /**
   * Initialize <input> with a value
   */
  data: _propTypes2.default.object
};

exports.default = Table;