"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _SocialDropdown = require("./SocialDropdown");

var _SocialDropdown2 = _interopRequireDefault(_SocialDropdown);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

var _SocialDropdown3 = require("./SocialDropdown.rehydrator");

var _SocialDropdown4 = _interopRequireDefault(_SocialDropdown3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableSocialDropdown = (0, _componentRehydratable2.default)(_SocialDropdown2.default);

RehydratableSocialDropdown.Item = _Item2.default;

exports.default = RehydratableSocialDropdown;
exports.rehydrator = _SocialDropdown4.default;