"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _contextTypes = require("./contextTypes");

var _contextTypes2 = _interopRequireDefault(_contextTypes);

var _Panel = require("./components/Panel");

var _Panel2 = _interopRequireDefault(_Panel);

var _Tab = require("./components/Tab");

var _Tab2 = _interopRequireDefault(_Tab);

var _TabList = require("./components/TabList");

var _TabList2 = _interopRequireDefault(_TabList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Tabs = function (_Component) {
  _inherits(Tabs, _Component);

  _createClass(Tabs, null, [{
    key: "getTabs",
    value: function getTabs(children) {
      var tabs = [];

      _react2.default.Children.forEach(children, function (child) {
        if (child.type === _Panel2.default) {
          tabs.push({
            title: child.props.tabTitle,
            relatedId: child.props.id
          });
        }
      });

      return tabs;
    }
  }]);

  function Tabs(props) {
    _classCallCheck(this, Tabs);

    var _this = _possibleConstructorReturn(this, (Tabs.__proto__ || Object.getPrototypeOf(Tabs)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(Tabs.displayName);

    var tabs = Tabs.getTabs(props.children);

    _this.state = {
      currentOpenPanel: props.defaultTab || tabs[0] && tabs[0].relatedId || null,
      tabs: tabs
    };

    _this.updateFromHash = _this.updateFromHash.bind(_this);
    _this.handleTabClick = _this.handleTabClick.bind(_this);
    _this.handleTabListKeyDown = _this.handleTabListKeyDown.bind(_this);
    _this.setOpenPanel = _this.setOpenPanel.bind(_this);
    return _this;
  }

  _createClass(Tabs, [{
    key: "getChildContext",
    value: function getChildContext() {
      // We carefully considered the use of context here. In the case of Tabs,
      // it simplifies the logic for rehydration, reduces coupling between
      // components, and eliminates hidden props. React have suggested that this
      // API may change in the future; if and when that happens, we need to
      // update our implementation to match.
      return {
        currentOpenPanel: this.state.currentOpenPanel
      };
    }
  }, {
    key: "setOpenPanel",
    value: function setOpenPanel(panelId) {
      this.setState({
        currentOpenPanel: panelId
      });

      Tabs.setLocationHash(panelId);

      var tabIndex = this.state.tabs.findIndex(function (tab) {
        return tab.relatedId === panelId;
      });
      var tab = this.state.tabs[tabIndex];

      this.props.analytics("Tab navigation", "Click " + (tabIndex + 1), tab.title);
    }
  }, {
    key: "handleTabListKeyDown",
    value: function handleTabListKeyDown(e) {
      var _this2 = this;

      var currentOpenPanelIndex = this.state.tabs.findIndex(function (tab) {
        return tab.relatedId === _this2.state.currentOpenPanel;
      });

      var desktopViewportWidth = 768;
      var direction = getComputedStyle(this.ref).direction;

      var nextKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
      var previousKey = direction === "ltr" ? "ArrowLeft" : "ArrowRight";
      var focusPanelKey = "ArrowDown";

      if (this.props.layoutVariant === "verticalAtDesktop" && window.innerWidth >= desktopViewportWidth) {
        nextKey = "ArrowDown";
        previousKey = "ArrowUp";
        focusPanelKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
      }

      if ([nextKey, previousKey].includes(e.key)) {
        var newOpenPanel = void 0;

        if (e.key === previousKey) {
          newOpenPanel = this.state.tabs[Math.max(0, currentOpenPanelIndex - 1)].relatedId;
        } else {
          newOpenPanel = this.state.tabs[Math.min(this.state.tabs.length - 1, currentOpenPanelIndex + 1)].relatedId;
        }

        this.setOpenPanel(newOpenPanel);
      } else if (e.key === focusPanelKey) {
        // See https://inclusive-components.design/tabbed-interfaces/#aproblemreadingpanels
        this.ref.querySelector("#" + this.state.currentOpenPanel).focus();
      }
    }
  }, {
    key: "handleTabClick",
    value: function handleTabClick(e, relatedId) {
      e.preventDefault();

      this.setOpenPanel(relatedId);
    }
  }, {
    key: "updateFromHash",
    value: function updateFromHash() {
      var locationHash = Tabs.getLocationHash();

      if (locationHash && locationHash !== this.state.currentOpenPanel && this.state.tabs.map(function (tab) {
        return tab.relatedId;
      }).includes(locationHash)) {
        this.setOpenPanel(locationHash);
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.updateFromHash();

      if (window) {
        window.addEventListener("hashchange", this.updateFromHash);
      }
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var newState = {
        tabs: Tabs.getTabs(nextProps.children)
      };

      if (newState.tabs.length === 0) {
        newState.currentOpenPanel = null;
      } else if (!newState.tabs.map(function (tab) {
        return tab.relatedId;
      }).includes(this.state.currentOpenPanel)) {
        // The previously open tab no longer exists. Open either the default tab,
        // or the first tab if no default is set.
        newState.currentOpenPanel = nextProps.defaultTab || newState.tabs[0].relatedId;
      }

      this.setState(newState);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (window) {
        window.removeEventListener("hashchange", this.updateFromHash);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          children = _props.children,
          defaultTab = _props.defaultTab,
          layoutVariant = _props.layoutVariant;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({ modifiers: (0, _classnames2.default)(layoutVariant) }),
          "data-default-tab": defaultTab,
          "data-layout-variant": layoutVariant,
          ref: function ref(_ref) {
            return _this3.ref = _ref;
          }
        },
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "list" }) },
          _react2.default.createElement(
            _TabList2.default,
            { layoutVariant: layoutVariant },
            this.state.tabs.map(function (tab) {
              return _react2.default.createElement(_Tab2.default, _extends({}, tab, {
                key: tab.relatedId,
                layoutVariant: layoutVariant,
                onClick: _this3.handleTabClick,
                onKeyDown: _this3.handleTabListKeyDown,
                selected: tab.relatedId === _this3.state.currentOpenPanel
              }));
            })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "panel" }) },
          children
        )
      );
    }
  }]);

  return Tabs;
}(_react.Component);

Tabs.displayName = "Tabs";
Tabs.childContextTypes = _contextTypes2.default;
Tabs.propTypes = {
  /**
   * An analytics function, accepting category, action, and label as arguments.
   */
  analytics: _propTypes2.default.func,

  /**
   * The content of the tabs. These should be of type `Tabs.Picker` or `Tabs.Panel`.
   */
  children: _propTypes2.default.node,

  /**
   * The ID of the default tab to open. This will be used if no ID was found in the URL that exists in this set of tabs. If none is specified, the first tab panel will be used as the default.
   */
  defaultTab: _propTypes2.default.string,

  /**
   * Optional modifier to switch layout variants
   */
  layoutVariant: _propTypes2.default.oneOf(["verticalAtDesktop"])
};
Tabs.defaultProps = {
  analytics: function analytics() {}
};

Tabs.getLocationHash = function () {
  if (window && window.location && window.location.hash) {
    return window.location.hash.substr(1);
  }

  return null;
};

Tabs.setLocationHash = function (hash) {
  if (window && window.location) {
    window.location.hash = hash;
  }
};

Tabs.Panel = _Panel2.default;

exports.default = Tabs;