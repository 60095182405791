"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _NavList = require("./NavList");

var _NavList2 = _interopRequireDefault(_NavList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _NavList2.default;