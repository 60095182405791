"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fireFormSubmitFailure = exports.fireFormSubmitSuccess = exports.fireFormStart = exports.ensureFormObjectInitialized = undefined;

var _blueimpMd = require("blueimp-md5");

var _blueimpMd2 = _interopRequireDefault(_blueimpMd);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var canFireEvents = function canFireEvents() {
  return typeof window !== "undefined" && window.digitalData && window.PubSub;
};

var ensureFormObjectInitialized = exports.ensureFormObjectInitialized = function ensureFormObjectInitialized() {
  if (!window.digitalData.page) {
    window.digitalData.page = {};
  }

  if (!window.digitalData.page.form) {
    window.digitalData.page.form = {};
  }
};

var fireFormStart = exports.fireFormStart = function fireFormStart(_ref) {
  var _ref$name = _ref.name,
      name = _ref$name === undefined ? "" : _ref$name,
      _ref$type = _ref.type,
      type = _ref$type === undefined ? "" : _ref$type;

  if (canFireEvents()) {
    ensureFormObjectInitialized();

    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;

    window.PubSub.publish("analytics.formStart", {});
  }
};

var fireFormSubmitSuccess = exports.fireFormSubmitSuccess = function fireFormSubmitSuccess(_ref2) {
  var _ref2$hashableIdentif = _ref2.hashableIdentifier,
      hashableIdentifier = _ref2$hashableIdentif === undefined ? false : _ref2$hashableIdentif,
      _ref2$name = _ref2.name,
      name = _ref2$name === undefined ? "" : _ref2$name,
      _ref2$type = _ref2.type,
      type = _ref2$type === undefined ? "" : _ref2$type;

  if (canFireEvents()) {
    ensureFormObjectInitialized();

    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;

    if (hashableIdentifier) {
      window.digitalData.page.form.formEmail = (0, _blueimpMd2.default)(hashableIdentifier);
    }

    window.PubSub.publish("analytics.formSuccess", {});
  }
};

var fireFormSubmitFailure = exports.fireFormSubmitFailure = function fireFormSubmitFailure(_ref3) {
  var _ref3$invalidFieldNam = _ref3.invalidFieldNames,
      invalidFieldNames = _ref3$invalidFieldNam === undefined ? [] : _ref3$invalidFieldNam,
      _ref3$name = _ref3.name,
      name = _ref3$name === undefined ? "" : _ref3$name,
      _ref3$type = _ref3.type,
      type = _ref3$type === undefined ? "" : _ref3$type;

  if (canFireEvents()) {
    ensureFormObjectInitialized();

    window.digitalData.page.form.formName = name;
    window.digitalData.page.form.formType = type;
    window.digitalData.page.form.formErrors = invalidFieldNames.join(",");

    window.PubSub.publish("analytics.formErrors", {});
  }
};