"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Item = function Item(_ref) {
  var children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)("Grid");

  return _react2.default.createElement(
    "div",
    { className: getClassName({ descendantName: "item" }) },
    children
  );
};

/* eslint-disable max-len */
Item.propTypes = {
  /**
   * The contents of the item.
   */
  children: _propTypes2.default.node
};
/* eslint-enable max-len */

Item.displayName = "Grid.Item";

exports.default = Item;