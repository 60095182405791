"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SelectedGroup = require("./SelectedGroup");

var _SelectedGroup2 = _interopRequireDefault(_SelectedGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SelectedGroup2.default;