"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _AuthState = require("../AuthState");

var _AuthState2 = _interopRequireDefault(_AuthState);

var _callEach = require("../utilities/callEach");

var _callEach2 = _interopRequireDefault(_callEach);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var namespace = "tr.auth.FakeLoginService.";

var getItem = function getItem(key) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (typeof window !== "undefined" && window.localStorage) {
    return window.localStorage.getItem("" + namespace + key) || defaultValue;
  }
};

var setItem = function setItem(key, value) {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.setItem("" + namespace + key, value);
  }
};

var removeItem = function removeItem(key) {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.removeItem("" + namespace + key);
  }
};

/**
 * A fake auth service, which maintains an auth state for a user named John
 * Smith.
 */

var _class = function () {
  function _class() {
    _classCallCheck(this, _class);

    this.active = false;
    this.onChangeCallbacks = [];

    this.addItemToCart = this.addItemToCart.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.removeItemFromCart = this.removeItemFromCart.bind(this);

    if (typeof window !== "undefined") {
      if (!window.localStorage) {
        // Since this is a fake login service, we don't need to be thorough in
        // supporting non-localStorage scenarios.
        // eslint-disable-next-line no-console
        console.warn("localStorage is unavailable. Login may not function as expected.");
      }
    }
  }

  _createClass(_class, [{
    key: "activate",
    value: function activate() {
      if (!this.active) {
        this.active = true;

        this.privateTriggerChange();
      }
    }
  }, {
    key: "addItemToCart",
    value: function addItemToCart() {
      setItem("itemsInCart", this.itemsInCart + 1);

      this.privateTriggerChange();
    }
  }, {
    key: "removeItemFromCart",
    value: function removeItemFromCart() {
      if (this.itemsInCart > 0) {
        setItem("itemsInCart", this.itemsInCart - 1);

        this.privateTriggerChange();
      }
    }
  }, {
    key: "login",
    value: function login() {
      setItem("authState", _AuthState2.default.LOGGED_IN);
      setItem("itemsInCart", 2); // eslint-disable-line no-magic-numbers
      setItem("userName", "John Smith");

      this.privateTriggerChange();
    }
  }, {
    key: "logout",
    value: function logout() {
      if (typeof window !== "undefined" && window.localStorage) {
        removeItem("authState");
        removeItem("itemsInCart");
        removeItem("userName");

        this.privateTriggerChange();
      }
    }
  }, {
    key: "onChange",
    value: function onChange(cb) {
      this.onChangeCallbacks.push(cb);
    }
  }, {
    key: "offChange",
    value: function offChange(cb) {
      if (this.onChangeCallbacks.includes(cb)) {
        this.onChangeCallbacks.splice(this.onChangeCallbacks.indexOf(cb), 1);

        return true;
      }

      return false;
    }
  }, {
    key: "privateTriggerChange",
    value: function privateTriggerChange() {
      (0, _callEach2.default)(this.onChangeCallbacks, this);
    }
  }, {
    key: "authState",
    get: function get() {
      if (this.active) {
        return parseInt(getItem("authState", _AuthState2.default.LOGGED_OUT));
      }

      return _AuthState2.default.UNKNOWN;
    }
  }, {
    key: "itemsInCart",
    get: function get() {
      if (this.active) {
        return parseInt(getItem("itemsInCart", 0));
      }

      return 0;
    }
  }, {
    key: "userName",
    get: function get() {
      if (this.active) {
        return getItem("userName");
      }

      return null;
    }
  }]);

  return _class;
}();

exports.default = _class;