"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Standfirst = require("./Standfirst");

var _Standfirst2 = _interopRequireDefault(_Standfirst);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Standfirst2.default;