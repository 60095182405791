"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentDateTime = require("@wel-ui/component-date-time");

var _componentDateTime2 = _interopRequireDefault(_componentDateTime);

var _clock = require("@wel-ui/icons/lib/ui/clock");

var _clock2 = _interopRequireDefault(_clock);

var _lock = require("@wel-ui/icons/lib/ui/lock");

var _lock2 = _interopRequireDefault(_lock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ArticleCardFooter = function ArticleCardFooter(_ref) {
  var attributionName = _ref.attributionName,
      dateTime = _ref.dateTime,
      locked = _ref.locked,
      readingTime = _ref.readingTime;

  var getClassName = (0, _utilityClassNames2.default)(ArticleCardFooter.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)({})
      })
    },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "dateAndAttribution" }) },
      _react2.default.createElement(_componentDateTime2.default, { dateTime: dateTime, lang: "en" }),
      attributionName ? _react2.default.createElement(
        "span",
        null,
        _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "dotSeparator" }) },
          "\u2022"
        ),
        attributionName
      ) : null
    ),
    readingTime ? _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "readingTime" }) },
      _react2.default.createElement(
        "div",
        { className: getClassName({ descendantName: "readingTimeIcon" }) },
        _react2.default.createElement(_clock2.default, { width: 18 })
      ),
      readingTime
    ) : null,
    locked ? _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "lockIcon" }) },
      _react2.default.createElement(_lock2.default, { width: 18 })
    ) : null
  );
};

ArticleCardFooter.displayName = "ArticleCardFooter";

/* eslint-disable max-len */
ArticleCardFooter.propTypes = {
  /**
   * Name of the article attribution.
   */
  attributionName: _propTypes2.default.string,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: _propTypes2.default.string.isRequired,
  /**
   * Optionally display a locked content indicator
   */
  locked: _propTypes2.default.bool,
  /**
   * Reading time for the article. Use SI and common non-SI format (English: s = seconds, min = minutes, h = hours, d = days, i.e. "15 min" or "1 h". See https://en.wikipedia.org/wiki/International_System_of_Units#Units_and_prefixes.)
   */
  readingTime: _propTypes2.default.string
};
/* eslint-enable max-len */

ArticleCardFooter.defaultProps = {};

exports.default = ArticleCardFooter;