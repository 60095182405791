"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BlockQuote = function BlockQuote(_ref) {
  var attribution = _ref.attribution,
      cite = _ref.cite,
      quotemark = _ref.quotemark,
      small = _ref.small,
      text = _ref.text;

  var getClassName = (0, _utilityClassNames2.default)("LongformBlockQuote");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({ modifiers: (0, _classnames2.default)({ quotemark: quotemark, small: small }) })
    },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "inner" }) },
      _react2.default.createElement(
        "blockquote",
        {
          className: getClassName({ descendantName: "quote" }),
          cite: cite
        },
        text
      )
    ),
    attribution ? _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "attribution" }) },
      attribution
    ) : null
  );
};

BlockQuote.propTypes = {
  /**
   * Attribution component for attributing the quotation
   */
  attribution: _propTypes2.default.node,
  /**
   * URL that designates source document or message for the information quoted.
   */
  cite: _propTypes2.default.string,
  /**
   * Show the decorative quotemark
   */
  quotemark: _propTypes2.default.bool.isRequired,
  /**
   * Optional small variant
   */
  small: _propTypes2.default.bool,
  /**
   * The quotation
   */
  text: _propTypes2.default.string.isRequired
};

BlockQuote.defaultProps = {
  quotemark: true,
  small: false
};

BlockQuote.displayName = "Longform.BlockQuote";

exports.default = BlockQuote;