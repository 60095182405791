"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flyoutsPanelRehydrator = exports.flyoutsRehydrator = undefined;

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

var _Flyouts = require("./Flyouts");

var _Flyouts2 = _interopRequireDefault(_Flyouts);

var _Flyouts3 = require("./Flyouts.rehydrator");

var _Flyouts4 = _interopRequireDefault(_Flyouts3);

var _TabPanel = require("./components/TabPanel");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableFlyouts = (0, _componentRehydratable2.default)(_Flyouts2.default);

RehydratableFlyouts.TabPanel = _Flyouts2.default.TabPanel;

exports.default = RehydratableFlyouts;
exports.flyoutsRehydrator = _Flyouts4.default;
exports.flyoutsPanelRehydrator = _TabPanel.tabPanelRehydrator;