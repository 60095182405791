"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _TextCardBase = require("../../bases/TextCardBase");

var _TextCardBase2 = _interopRequireDefault(_TextCardBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextCard = function TextCard(_ref) {
  var borderless = _ref.borderless,
      description = _ref.description,
      eyebrow = _ref.eyebrow,
      footer = _ref.footer,
      heading = _ref.heading,
      headingRank = _ref.headingRank,
      href = _ref.href,
      size = _ref.size;

  var getClassName = (0, _utilityClassNames2.default)(TextCard.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(_TextCardBase2.default, {
      borderless: borderless,
      description: description,
      eyebrow: eyebrow,
      footer: footer,
      heading: heading,
      headingRank: headingRank,
      href: href,
      size: size
    })
  );
};

TextCard.displayName = "TextCard";

TextCard.sizes = _TextCardBase2.default.sizes;
TextCard.headingRanks = _TextCardBase2.default.headingRanks;

/* eslint-disable max-len */
TextCard.propTypes = {
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Description text
   */
  description: _propTypes2.default.node,
  /**
   * Eyebrow text
   */
  eyebrow: _propTypes2.default.string,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: _propTypes2.default.node.isRequired,
  /**
   * Heading text
   */
  heading: _propTypes2.default.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: _propTypes2.default.oneOf(TextCard.headingRanks),
  /**
   * Card URL
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: _propTypes2.default.oneOf(TextCard.sizes).isRequired
};
/* eslint-enable max-len */

TextCard.defaultProps = {
  headingRank: "3"
};

exports.default = TextCard;