"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Child = require("./Child");

var _Child2 = _interopRequireDefault(_Child);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Child2.default;