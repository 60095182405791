"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (response, json) {
  return Promise.reject({
    message: "Fetch request failed with status: " + json.Status,
    response: response,
    json: json
  });
};