"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _booleanAttributes = require("./booleanAttributes");

var _booleanAttributes2 = _interopRequireDefault(_booleanAttributes);

var _reactAttributes = require("./reactAttributes");

var _reactAttributes2 = _interopRequireDefault(_reactAttributes);

var _specialElementHandlers = require("./specialElementHandlers");

var _specialElementHandlers2 = _interopRequireDefault(_specialElementHandlers);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var getReactFriendlyAttributeName = function getReactFriendlyAttributeName(attributeName) {
  if (_reactAttributes2.default.hasOwnProperty(attributeName.toLowerCase())) {
    return _reactAttributes2.default[attributeName.toLowerCase()];
  }

  return attributeName;
};

var makeRefStyleHandler = function makeRefStyleHandler(styleString) {
  return function (ref) {
    if (ref !== null) {
      ref.setAttribute("style", styleString);
    }
  };
};

var getAttributesWithoutStyle = function getAttributesWithoutStyle(attributes) {
  var style = attributes.style,
      newAttributes = _objectWithoutProperties(attributes, ["style"]);

  newAttributes.ref = makeRefStyleHandler(style);

  return newAttributes;
};

// eslint-disable-next-line react/display-name

exports.default = function (el, recursor) {
  var attributes = {};

  if (el.hasAttributes()) {
    for (var i = 0; i < el.attributes.length; i++) {
      var value = el.attributes[i].value;

      if (_booleanAttributes2.default.includes(el.attributes[i].name) && value === "") {
        // React, and JavaScript, view "" as falsy. However, an empty string on
        // a DOM element's attribute makes it truthy. This compensates for the
        // discrepancy.
        value = true;
      }

      attributes[getReactFriendlyAttributeName(el.attributes[i].name)] = value;
    }
  }

  if (attributes.style) {
    attributes = getAttributesWithoutStyle(attributes);
  }

  var tagName = el.tagName.toLowerCase();
  var handler = _specialElementHandlers2.default[tagName];

  if (handler) {
    attributes = handler(el, attributes);
  }

  var childReactNodes = [];

  // <textarea>'s children will be replaced with the content of its value
  // property, so we should skip parsing them.
  if (tagName !== "textarea") {
    for (var _i = 0; _i < el.childNodes.length; _i++) {
      childReactNodes.push(recursor(el.childNodes[_i]));
    }
  }

  return _react2.default.createElement.apply(_react2.default, [tagName, attributes].concat(childReactNodes));
};