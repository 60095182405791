"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioControl = function RadioControl(_ref) {
  var defaultValue = _ref.defaultValue,
      disabled = _ref.disabled,
      name = _ref.name,
      options = _ref.options,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(RadioControl.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    options.map(function (option) {
      var props = {};

      if (value) {
        // Controlled mode
        props.checked = option.value === value;
      }

      if (defaultValue) {
        // Uncontrolled mode
        props.defaultChecked = option.value === defaultValue;
      }

      return _react2.default.createElement(
        "label",
        {
          className: getClassName({ descendantName: "label" }),
          htmlFor: option.value,
          key: option.value
        },
        _react2.default.createElement("input", _extends({
          className: getClassName({ descendantName: "input" }),
          disabled: disabled,
          id: option.value,
          name: name,
          onChange: option.onChange,
          type: "radio",
          value: option.value
        }, props)),
        _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "labelText" }) },
          option.label
        )
      );
    })
  );
};

RadioControl.displayName = "Form.RadioControl";

RadioControl.propTypes = {
  /**
   * Initialize with selected <input>
   */
  defaultValue: _propTypes2.default.string,
  /**
   * Disable all <input> elements
   */
  disabled: _propTypes2.default.bool,
  /**
   * Name attribute for <input> elements
   */
  name: _propTypes2.default.string.isRequired,
  /**
   * Array of objects with `label` and `value` properties
   */
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string.isRequired,
    onChange: _propTypes2.default.func,
    value: _propTypes2.default.string.isRequired
  })).isRequired,
  /**
   * Explicitly set an input as selected. Will switch into controlled mode.
   */
  value: _propTypes2.default.string
};

exports.default = RadioControl;