"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TableWithFilters = require("./TableWithFilters");

var _TableWithFilters2 = _interopRequireDefault(_TableWithFilters);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_TableWithFilters2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var props = {
      isRehydrating: true,
      data: readData("table-data"),
      filtersHideSelectedOptions: readData("filters-hide-selected-options"),
      filtersNoOptionsMessage: readData("filters-no-options-message"),
      filtersPlaceholder: readData("filters-placeholder"),
      infoText: readData("info-text"),
      noResultsMessage: readData("no-results-message"),
      pagesNavigableOnSide: readData("pages-navigable-on-side"),
      paginationResultSummaryText: readData("pagination-result-summary-text"),
      rowsPerPage: readData("rows-per-page"),
      subtitleHeadingRank: readData("subtitle-heading-rank"),
      title: readData("title"),
      titleHeadingRank: readData("title-heading-rank")
    };

    return _react2.default.createElement(_TableWithFilters2.default, props);
  }
};

exports.default = rehydrator;