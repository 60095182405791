"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _TextCardBase = require("../../bases/TextCardBase");

var _TextCardBase2 = _interopRequireDefault(_TextCardBase);

var _componentImage = require("@wel-ui/component-image");

var _componentImage2 = _interopRequireDefault(_componentImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImageCard = function ImageCard(_ref) {
  var eyebrow = _ref.eyebrow,
      footer = _ref.footer,
      imgAnchor = _ref.imgAnchor,
      imgGradient = _ref.imgGradient,
      imgSrc = _ref.imgSrc,
      imgSrcSet = _ref.imgSrcSet,
      heading = _ref.heading,
      headingRank = _ref.headingRank,
      href = _ref.href,
      largeHeading = _ref.largeHeading,
      size = _ref.size;

  var getClassName = (0, _utilityClassNames2.default)(ImageCard.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(_TextCardBase2.default, {
      anchor: "bottom",
      borderless: true,
      eyebrow: eyebrow,
      footer: footer,
      heading: heading,
      headingRank: headingRank,
      href: href,
      size: size,
      largeHeading: largeHeading,
      renderContent: function renderContent(content) {
        return _react2.default.createElement(
          "div",
          { className: getClassName({ descendantName: "content" }) },
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "background" }) },
            _react2.default.createElement(
              "div",
              {
                className: getClassName({ descendantName: "backgroundImage" })
              },
              _react2.default.createElement(_componentImage2.default, {
                alt: "",
                anchor: imgAnchor,
                fit: "cover",
                overlay: imgGradient ? "scrimBottomDark" : null
                // This assumes the card is used in a 2/3 grid column. We make
                // this assumption as we can't identify the context in which
                // the card is used. This will slightly affect performance, as
                // larger images may be loaded than necessary.
                , sizes: _componentImage2.default.sizes.twoThirds,
                src: imgSrc,
                srcSet: imgSrcSet
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "contentInner" }) },
            content
          )
        );
      }
    })
  );
};

ImageCard.displayName = "ImageCard";

ImageCard.sizes = _TextCardBase2.default.sizes;
ImageCard.headingRanks = _TextCardBase2.default.headingRanks;

/* eslint-disable max-len */
ImageCard.propTypes = {
  /**
   * Eyebrow text
   */
  eyebrow: _propTypes2.default.string.isRequired,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: _propTypes2.default.node,
  /**
   * Heading text
   */
  heading: _propTypes2.default.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: _propTypes2.default.oneOf(ImageCard.headingRanks),
  /**
   * Card URL
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Anchor point for the image.
   */
  imgAnchor: _propTypes2.default.oneOf(_componentImage.anchorPoints),
  /**
   * Apply a gradient to the image. Should not be used if the image has a rasterized gradient.
   */
  imgGradient: _propTypes2.default.bool,
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: _propTypes2.default.string.isRequired,
  /**
   * A comma-separated list indicating a set of possible image sources for the image. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: _propTypes2.default.string,
  /**
   * Enable large heading font size
   */
  largeHeading: _propTypes2.default.bool,
  /**
   * Size of the Card. Will set the minimum height and grow to fit content.
   */
  size: _propTypes2.default.oneOf(ImageCard.sizes).isRequired
};
/* eslint-enable max-len */

ImageCard.defaultProps = {
  headingRank: "3",
  imgGradient: false,
  imgAnchor: "C",
  largeHeading: false
};

exports.default = ImageCard;