import { registerService } from "@wel-ui/utility-rehydrator";
import CampaignTrackingParameterService from "@wel-ui/utility-campaign";
import queryString from "query-string";
import Cookies from "js-cookie";
import getLocation from "../utilities/getLocation";

// There's a possibility of exceptions from JSON parsing in this file. However,
// we don't ever want the site to bail because of campaign tracking parameters.
// Instead of throwing those exceptions, we can use console.error.

/* eslint-disable no-console */

const cookiesEnabled =
  typeof navigator !== "undefined" && navigator.cookieEnabled !== false;

const params = [];
let trackingCookieName = "tr_ewp_tracking_params";

const trackingParamsJson = document.body.getAttribute("data-tracking-params");

trackingCookieName =
  document.body.getAttribute("data-tracking-cookie-name") || trackingCookieName;

if (trackingParamsJson) {
  try {
    params.push(...JSON.parse(trackingParamsJson));
  } catch (e) {
    // Parsing the tracking parameters failed.
    console.error(e);
  }
}

// referrer_url is a special, non-persisted tracked parameter. Add it
// explicitly, because this special behavior shouldn't be configurable.
params.push("page_title", "product_family", "referrer_url", "source_url");

const service = new CampaignTrackingParameterService(params);

let fromCookie = {};

if (cookiesEnabled) {
  try {
    fromCookie = Cookies.getJSON(trackingCookieName) || fromCookie;
  } catch (e) {
    // Trouble reading the cookie; it may have been corrupted.
    console.error(e);
  }
}

service.add(fromCookie);
service.add(queryString.parse(window.location.search));

// referrer_url field
if (document.referrer) {
  const referrerHostname = getLocation(document.referrer).hostname;

  if (referrerHostname && referrerHostname !== document.location.hostname) {
    service.add({ referrer_url: document.referrer }); // eslint-disable-line camelcase
  }
}

if (cookiesEnabled) {
  // js-cookie takes care of serializing the result of `getAll()` to JSON
  Cookies.set(trackingCookieName, {
    ...fromCookie,
    ...service.getAll()
  });
}

// NON-PERSISTENT TRACKING PARAMS
// We don't want these params to persist, so we add them to to the service after
// saving the cookie.
// Some of these have externally-defined names, so camel casing isn't always an option

// product_family field
const productFamilyMeta = document.head.querySelector('meta[name~="w-brand"]');

if (productFamilyMeta) {
  service.add({ product_family: productFamilyMeta.content }); // eslint-disable-line camelcase
}

// source_url field
service.add({ source_url: document.location.href }); // eslint-disable-line camelcase

// Store the page title. This ensures that we always have the right page title available, even when loading forms dynamically (ie. inside a Modal).
service.add({ page_title: document.title }); // eslint-disable-line camelcase

registerService("CampaignTrackingParameterService", service);

export default service;
