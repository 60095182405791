"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Filters = function (_Component) {
  _inherits(Filters, _Component);

  function Filters(props) {
    _classCallCheck(this, Filters);

    var _this = _possibleConstructorReturn(this, (Filters.__proto__ || Object.getPrototypeOf(Filters)).call(this, props));

    _this.state = {
      isExpanded: false
    };

    _this.getClassName = (0, _utilityClassNames2.default)("SearchFiltersFilters");
    _this.handleToggle = _this.handleToggle.bind(_this);
    return _this;
  }

  _createClass(Filters, [{
    key: "handleToggle",
    value: function handleToggle() {
      this.setState({
        isExpanded: !this.state.isExpanded
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            states: this.state.isExpanded ? "expanded" : ""
          }),
          "data-title": this.props.title
        },
        _react2.default.createElement(
          "button",
          {
            className: this.getClassName({ descendantName: "title" }),
            onClick: this.handleToggle
          },
          _react2.default.createElement(
            "span",
            { className: this.getClassName({ descendantName: "titleText" }) },
            this.props.title
          ),
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "toggleIcon" }) },
            this.state.isExpanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
          )
        ),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "inner" }) },
          this.props.children
        )
      );
    }
  }]);

  return Filters;
}(_react.Component);

Filters.propTypes = {
  /**
   * The child sections. Should be of type `SearchFilters.FilterSection`.
   */
  children: _propTypes2.default.node,

  /**
   * The title of this group.
   */
  title: _propTypes2.default.string
};
Filters.defaultProps = {
  title: "Narrow by"
};
Filters.displayName = "SearchFilters.Filters";
exports.default = (0, _componentRehydratable2.default)(Filters);