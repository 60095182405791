"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoPlayerCore = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _VideoPlayerEmbed = require("./VideoPlayerEmbed");

var _VideoPlayerEmbed2 = _interopRequireDefault(_VideoPlayerEmbed);

var _VideoPlayerRegistry = require("./registry/VideoPlayerRegistry");

var _VideoPlayerRegistry2 = _interopRequireDefault(_VideoPlayerRegistry);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _momentDurationFormat = require("moment-duration-format");

var _momentDurationFormat2 = _interopRequireDefault(_momentDurationFormat);

var _jsCookie = require("js-cookie");

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentAjaxContent = require("@wel-ui/component-ajax-content");

var _componentAjaxContent2 = _interopRequireDefault(_componentAjaxContent);

var _componentModal = require("@wel-ui/component-modal");

var _componentModal2 = _interopRequireDefault(_componentModal);

var _componentForm = require("@wel-ui/component-form");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

(0, _momentDurationFormat2.default)(_moment2.default);

var ewpCookieName = "tr_ewp_submitted_forms";

var isFormInCookie = function isFormInCookie(gatedCookieId, gatedFormId) {
  var cookie = _jsCookie2.default.getJSON(gatedCookieId);

  return Boolean(cookie && cookie.forms && cookie.forms.includes(gatedFormId));
};

var propTypes = {
  /**
   * The analytics function to use.
   */
  analytics: _propTypes2.default.func,

  /**
   * Should this video start automatically if it's inside a <Modal />?
   */
  autoplayInModal: _propTypes2.default.bool,

  /**
   * The poster image.
   */
  children: _propTypes2.default.node,

  /**
   * An ISO 8601 duration.
   */
  duration: _propTypes2.default.string.isRequired,

  /**
   * Name of browser cookie where submitted form IDs will be stored.
   */
  gatedCookieName: _propTypes2.default.string,

  /**
   * Path to the form content; loaded via AJAX to present a gated form.
   */
  gatedFormContentLocation: _propTypes2.default.string,

  /**
   * Unique ID used to identify the form and lock/unlock the gated content.
   */
  gatedFormId: _propTypes2.default.string,

  /**
   * The video provider.
   */
  kind: _propTypes2.default.oneOf(["qq", "vidyard", "vimeo", "youtube"]).isRequired,

  /**
   * Callback, called on loading new AJAX form content.
   */
  onAjaxChange: _propTypes2.default.func,

  /**
   * Accessibility label to be used on the "Play" button
   */
  playLabel: _propTypes2.default.string.isRequired,

  /**
   * The ID of the video. Used to retrieve the video from the video provider.
   */
  videoId: _propTypes2.default.string.isRequired
};

var VideoPlayerCore = exports.VideoPlayerCore = function (_Component) {
  _inherits(VideoPlayerCore, _Component);

  function VideoPlayerCore() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, VideoPlayerCore);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = VideoPlayerCore.__proto__ || Object.getPrototypeOf(VideoPlayerCore)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isActive: false
    }, _this.getClassName = (0, _utilityClassNames2.default)(VideoPlayerCore.displayName), _this.handleClickLoad = function () {
      if (!_this.checkIsGateOpen()) {
        _this.setState({ isModalOpen: true });

        return;
      }

      _this.playVideo();
    }, _this.handleCloseModal = function () {
      _this.setState({ isModalOpen: false });
    }, _this.handleFormComplete = function () {
      _this.setState({
        isModalOpen: false
      });

      if (_this.checkIsGateOpen()) {
        _this.playVideo();
      }
    }, _this.checkIsGateOpen = function () {
      var _this$props = _this.props,
          gatedFormContentLocation = _this$props.gatedFormContentLocation,
          gatedCookieName = _this$props.gatedCookieName,
          gatedFormId = _this$props.gatedFormId;


      if (gatedFormContentLocation && gatedCookieName && gatedFormId) {
        return isFormInCookie(gatedCookieName, gatedFormId);
      }

      return true;
    }, _this.playVideo = function () {
      _VideoPlayerRegistry2.default.deactivateAll(_this);
      _this.setState({ isActive: true });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(VideoPlayerCore, [{
    key: "deactivate",
    value: function deactivate() {
      if (this.state.isActive) {
        this.setState({
          isActive: false
        });
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _VideoPlayerRegistry2.default.register(this);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _VideoPlayerRegistry2.default.deregister(this);
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          allowAutoplay = _props.allowAutoplay,
          autoplayInModal = _props.autoplayInModal,
          children = _props.children,
          duration = _props.duration,
          gatedFormContentLocation = _props.gatedFormContentLocation,
          gatedCookieName = _props.gatedCookieName,
          gatedFormId = _props.gatedFormId,
          kind = _props.kind,
          onAjaxChange = _props.onAjaxChange,
          playLabel = _props.playLabel,
          videoId = _props.videoId;
      var _state = this.state,
          isActive = _state.isActive,
          isModalOpen = _state.isModalOpen;

      var formattedDuration = _moment2.default.duration(duration).format("hh:mm:ss", {
        stopTrim: "mm"
      });

      var content = void 0;

      var modal = isModalOpen ? _react2.default.createElement(
        _componentModal2.default,
        {
          ariaLabel: "Gated Video Dialog",
          closeLabel: "Close",
          closeThisComponent: this.handleCloseModal
        },
        _react2.default.createElement(
          _componentForm.FormOnSubmitSuccessContextProvider,
          { value: this.handleFormComplete },
          _react2.default.createElement(_componentAjaxContent2.default, {
            failedContent: "Failed to retrieve content",
            loadingLabel: "Loading",
            location: gatedFormContentLocation,
            onChange: onAjaxChange
          })
        )
      ) : null;

      if (allowAutoplay || isActive) {
        content = _react2.default.createElement(_VideoPlayerEmbed2.default, _extends({ getClassName: this.getClassName }, this.props));
      } else {
        content = _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "poster" }) },
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName({ descendantName: "posterImage" }),
              "data-rehydratable-children": true
            },
            children
          ),
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName({
                descendantName: "duration"
              })
            },
            formattedDuration
          ),
          _react2.default.createElement(
            "button",
            {
              "aria-label": playLabel,
              className: this.getClassName({ descendantName: "loadAction" }),
              onClick: this.handleClickLoad
            },
            _react2.default.createElement("span", {
              className: this.getClassName({
                descendantName: "loadActionInner"
              })
            })
          )
        );
      }

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName(),
          "data-autoplay-in-modal": autoplayInModal,
          "data-duration": duration,
          "data-gated-cookie-name": gatedCookieName,
          "data-gated-form-content-location": gatedFormContentLocation,
          "data-gated-form-id": gatedFormId,
          "data-video-id": videoId,
          "data-kind": kind,
          "data-play-label": playLabel
        },
        content,
        modal
      );
    }
  }]);

  return VideoPlayerCore;
}(_react.Component);

VideoPlayerCore.displayName = "VideoPlayerCore";
VideoPlayerCore.propTypes = _extends({}, propTypes, {

  /**
   * Start playing on mount. Will usually come from context - see VideoPlayerCoreWithContext.
   */
  allowAutoplay: _propTypes2.default.bool
});
VideoPlayerCore.defaultProps = {
  gatedCookieName: ewpCookieName
};


var VideoPlayerCoreWithContext = function VideoPlayerCoreWithContext(_ref2) {
  var autoplayInModal = _ref2.autoplayInModal,
      props = _objectWithoutProperties(_ref2, ["autoplayInModal"]);

  return _react2.default.createElement(
    _componentModal.IsModalOpenConsumer,
    null,
    function (isModalOpen) {
      return _react2.default.createElement(VideoPlayerCore, _extends({}, props, {
        allowAutoplay: autoplayInModal && isModalOpen,
        autoplayInModal: autoplayInModal
      }));
    }
  );
};

VideoPlayerCoreWithContext.propTypes = _extends({}, propTypes);
VideoPlayerCoreWithContext.displayName = "VideoPlayerCore";

exports.default = VideoPlayerCoreWithContext;