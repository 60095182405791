"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getService = exports.registerService = exports.registerRehydrator = exports.rehydratableToReactElement = undefined;

var _rehydrator = require("./rehydrator");

var _rehydrator2 = _interopRequireDefault(_rehydrator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _rehydrator2.default;
exports.rehydratableToReactElement = _rehydrator.rehydratableToReactElement;
exports.registerRehydrator = _rehydrator.registerRehydrator;
exports.registerService = _rehydrator.registerService;
exports.getService = _rehydrator.getService;