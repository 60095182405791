"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginRehydrator = undefined;

var _Login = require("./Login");

var _Login2 = _interopRequireDefault(_Login);

var _Login3 = require("./Login.rehydrator");

var _Login4 = _interopRequireDefault(_Login3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Login2.default;
exports.loginRehydrator = _Login4.default;