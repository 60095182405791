"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Menu = require("./Menu");

var _Menu2 = _interopRequireDefault(_Menu);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Menu.Menu),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("SiteHeaderMenu");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "children" }));

    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var rehydratedProps = {
      isRehydrating: true,
      narrow: domNode.getAttribute("data-narrow") === "true",
      title: domNode.getAttribute("data-title")
    };

    var href = domNode.getAttribute("data-href");
    var fallbackHref = domNode.getAttribute("data-fallback-href");

    if (href && href.length) {
      rehydratedProps.href = href;
    }

    if (fallbackHref && fallbackHref.length) {
      rehydratedProps.fallbackHref = fallbackHref;
    }

    return _react2.default.createElement(
      _Menu2.default,
      rehydratedProps,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;