"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navListRehydrator = exports.rehydrator = undefined;

var _SiteFooter = require("./SiteFooter");

var _SiteFooter2 = _interopRequireDefault(_SiteFooter);

var _SiteFooter3 = require("./SiteFooter.rehydrator");

var _SiteFooter4 = _interopRequireDefault(_SiteFooter3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

var _NavList = require("./components/NavList");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableSiteFooter = (0, _componentRehydratable2.default)(_SiteFooter2.default);

RehydratableSiteFooter.NavList = _SiteFooter2.default.NavList;
RehydratableSiteFooter.NavItem = _SiteFooter2.default.NavItem;

exports.default = RehydratableSiteFooter;
exports.rehydrator = _SiteFooter4.default;
exports.navListRehydrator = _NavList.navListRehydrator;