"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Form = require("./Form");

var _Form2 = _interopRequireDefault(_Form);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Form2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("Form");

    var childrenNode = domNode.querySelector("[data-rehydratable-children]");
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var headerNode = domNode.querySelector("." + getClassName({ descendantName: "header" }));
    var footerNode = domNode.querySelector("." + getClassName({ descendantName: "footer" }));

    var footer = footerNode ? (0, _componentRehydratable.rehydrateChildren)(footerNode, extra) : null;
    var header = headerNode ? (0, _componentRehydratable.rehydrateChildren)(headerNode, extra) : null;

    var props = {
      isRehydrating: true,
      action: domNode.getAttribute("data-action"),
      analyticsName: domNode.getAttribute("data-analytics-name"),
      analyticsType: domNode.getAttribute("data-analytics-type"),
      ajax: domNode.getAttribute("data-ajax") === "true",
      footer: footer,
      header: header,
      submitButtonText: domNode.getAttribute("data-submit-button-text")
    };

    return _react2.default.createElement(
      _Form2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;