"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TwitterCardTimeline = require("./TwitterCardTimeline");

var _TwitterCardTimeline2 = _interopRequireDefault(_TwitterCardTimeline);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_TwitterCardTimeline2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var props = {
      isRehydrating: true,
      timelineDataSource: readData("timeline-data-source"),
      timelineOptions: readData("timeline-options")
    };

    return _react2.default.createElement(_TwitterCardTimeline2.default, props);
  }
};

exports.default = rehydrator;