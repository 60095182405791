"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TagFilter = require("./TagFilter");

var _TagFilter2 = _interopRequireDefault(_TagFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TagFilter2.default;