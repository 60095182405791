"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DateTime = require("./DateTime");

var _DateTime2 = _interopRequireDefault(_DateTime);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _DateTime2.default;