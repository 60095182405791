"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tickerRehydrator = exports.tickerItemRehydrator = undefined;

var _Ticker = require("./Ticker");

var _Ticker2 = _interopRequireDefault(_Ticker);

var _Ticker3 = require("./Ticker.rehydrator");

var _Ticker4 = _interopRequireDefault(_Ticker3);

var _TickerItem = require("./components/TickerItem");

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableTicker = (0, _componentRehydratable2.default)(_Ticker2.default);

RehydratableTicker.Item = _Ticker2.default.Item;

exports.default = RehydratableTicker;
exports.tickerItemRehydrator = _TickerItem.tickerItemRehydrator;
exports.tickerRehydrator = _Ticker4.default;