"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _QQPlayerEmbed = require("./players/QQPlayerEmbed");

var _QQPlayerEmbed2 = _interopRequireDefault(_QQPlayerEmbed);

var _VidyardPlayerEmbed = require("./players/VidyardPlayerEmbed");

var _VidyardPlayerEmbed2 = _interopRequireDefault(_VidyardPlayerEmbed);

var _VimeoPlayerEmbed = require("./players/VimeoPlayerEmbed");

var _VimeoPlayerEmbed2 = _interopRequireDefault(_VimeoPlayerEmbed);

var _YouTubePlayerEmbed = require("./players/YouTubePlayerEmbed");

var _YouTubePlayerEmbed2 = _interopRequireDefault(_YouTubePlayerEmbed);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var VideoPlayerEmbed = function VideoPlayerEmbed(_ref) {
  var kind = _ref.kind,
      frameProps = _objectWithoutProperties(_ref, ["kind"]);

  var frame = void 0;

  switch (kind) {
    case "qq":
      frame = _react2.default.createElement(_QQPlayerEmbed2.default, frameProps);
      break;
    case "vidyard":
      frame = _react2.default.createElement(_VidyardPlayerEmbed2.default, frameProps);
      break;
    case "vimeo":
      frame = _react2.default.createElement(_VimeoPlayerEmbed2.default, frameProps);
      break;
    case "youtube":
      frame = _react2.default.createElement(_YouTubePlayerEmbed2.default, frameProps);
      break;
    default:
      frame = null;
  }

  return _react2.default.createElement(
    "div",
    { className: frameProps.getClassName({ descendantName: "player" }) },
    frame
  );
};

VideoPlayerEmbed.propTypes = {
  getClassName: _propTypes2.default.func.isRequired,
  kind: _propTypes2.default.oneOf(["qq", "vidyard", "vimeo", "youtube"]).isRequired,
  videoId: _propTypes2.default.string.isRequired
};

exports.default = VideoPlayerEmbed;