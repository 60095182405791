"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Asset = require("./components/Asset");

var _Asset2 = _interopRequireDefault(_Asset);

var _BlockQuote = require("./components/BlockQuote");

var _BlockQuote2 = _interopRequireDefault(_BlockQuote);

var _ChapterDivider = require("./components/ChapterDivider");

var _ChapterDivider2 = _interopRequireDefault(_ChapterDivider);

var _ChapterNav = require("./components/ChapterNav");

var _ChapterNav2 = _interopRequireDefault(_ChapterNav);

var _Child = require("./components/Child");

var _Child2 = _interopRequireDefault(_Child);

var _RichText = require("./components/RichText");

var _RichText2 = _interopRequireDefault(_RichText);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Longform = function Longform(_ref) {
  var children = _ref.children,
      id = _ref.id;

  var getClassName = (0, _utilityClassNames2.default)(Longform.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName(), id: id },
    children
  );
};

Longform.displayName = "Longform";

/* eslint-disable max-len */
Longform.propTypes = {
  /**
   * Child nodes to wrap in Longform. Should typically be Longform.Child.
   */
  children: _propTypes2.default.node,
  /**
   * Element id.
   */
  id: _propTypes2.default.string
};
/* eslint-enable max-len */

Longform.defaultProps = {
  id: "longform"
};

Longform.Asset = _Asset2.default;
Longform.BlockQuote = _BlockQuote2.default;
Longform.ChapterDivider = _ChapterDivider2.default;
Longform.ChapterNav = _ChapterNav2.default;
Longform.Child = _Child2.default;
Longform.RichText = _RichText2.default;

exports.default = Longform;