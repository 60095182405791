"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var VIDYARD_SCRIPT_URL = "//play.vidyard.com/v0/api.js";
var VIMEO_SCRIPT_URL = "https://player.vimeo.com/api/player.js";
var YOUTUBE_SCRIPT_URL = "https://www.youtube.com/iframe_api";

exports.VIDYARD_SCRIPT_URL = VIDYARD_SCRIPT_URL;
exports.VIMEO_SCRIPT_URL = VIMEO_SCRIPT_URL;
exports.YOUTUBE_SCRIPT_URL = YOUTUBE_SCRIPT_URL;