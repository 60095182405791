"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Section = function Section(_ref) {
  var children = _ref.children,
      background = _ref.background,
      fullWidth = _ref.fullWidth;

  var getClassName = (0, _utilityClassNames2.default)(Section.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: (0, _classnames2.default)(background) }) },
    fullWidth ? children : _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "inner" }) },
      children
    )
  );
};

Section.displayName = "Section";

Section.backgrounds = ["grayDark", "grayLighter", "grayLightest"];

Section.propTypes = {
  /**
   * Markup elements to render in Section
   */
  children: _propTypes2.default.node,

  /**
   * Set Section to be full width, no Section-inner div
   */
  fullWidth: _propTypes2.default.bool,

  /**
   * Modify Section with background colors
   */
  background: _propTypes2.default.oneOf(Section.backgrounds)
};

exports.default = Section;