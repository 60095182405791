"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _GroupItem = require("./GroupItem");

var _GroupItem2 = _interopRequireDefault(_GroupItem);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Group = function Group(_ref) {
  var children = _ref.children,
      compact = _ref.compact,
      vertical = _ref.vertical;

  var getClassName = (0, _utilityClassNames2.default)(Group.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)({ compact: compact, vertical: vertical })
      })
    },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "inner" }) },
      children
    )
  );
};

Group.displayName = "Group";

/* eslint-disable max-len */
Group.propTypes = {
  /**
   * Group.Item components
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * Decrease the gap between items
   */
  compact: _propTypes2.default.bool,

  /**
   * Display vertically
   */
  vertical: _propTypes2.default.bool
};
/* eslint-enable max-len */

Group.Item = _GroupItem2.default;

exports.default = Group;