"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ConsoleAnalytics = require("./ConsoleAnalytics");

var _ConsoleAnalytics2 = _interopRequireDefault(_ConsoleAnalytics);

var _GtmAnalytics = require("./GtmAnalytics");

var _GtmAnalytics2 = _interopRequireDefault(_GtmAnalytics);

var _StorybookAnalytics = require("./StorybookAnalytics");

var _StorybookAnalytics2 = _interopRequireDefault(_StorybookAnalytics);

var _NullAnalytics = require("./NullAnalytics");

var _NullAnalytics2 = _interopRequireDefault(_NullAnalytics);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getAnalytics = function getAnalytics(engineName) {
  for (var _len = arguments.length, extra = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    extra[_key - 1] = arguments[_key];
  }

  var engine = null;

  switch (engineName) {
    case "storybook":
      engine = new (Function.prototype.bind.apply(_StorybookAnalytics2.default, [null].concat(extra)))();
      break;

    case "gtm":
      engine = new (Function.prototype.bind.apply(_GtmAnalytics2.default, [null].concat(extra)))();
      break;

    case "console":
      engine = new (Function.prototype.bind.apply(_ConsoleAnalytics2.default, [null].concat(extra)))();
      break;

    case "null":
      engine = new (Function.prototype.bind.apply(_NullAnalytics2.default, [null].concat(extra)))();
      break;

    default:
      throw new Error("Unsupported analytics engine type: " + engineName);
  }

  // Enforcing an interface of sorts.
  return function (category, action, label) {
    engine.sendEvent(category, action, label);
  };
};

exports.default = getAnalytics;