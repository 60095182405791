"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuRehydrator = undefined;

var _Menu = require("./Menu");

var _Menu2 = _interopRequireDefault(_Menu);

var _Menu3 = require("./Menu.rehydrator");

var _Menu4 = _interopRequireDefault(_Menu3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Menu2.default;
exports.menuRehydrator = _Menu4.default;