"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _anchorPoints = require("./anchorPoints.js");

var _anchorPoints2 = _interopRequireDefault(_anchorPoints);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Image = function Image(_ref) {
  var _classNames;

  var alt = _ref.alt,
      anchor = _ref.anchor,
      fillColor = _ref.fillColor,
      fit = _ref.fit,
      height = _ref.height,
      overlay = _ref.overlay,
      sizes = _ref.sizes,
      src = _ref.src,
      srcSet = _ref.srcSet,
      width = _ref.width;

  var getClassName = (0, _utilityClassNames2.default)(Image.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)((_classNames = {
          cover: fit === "cover"
        }, _defineProperty(_classNames, "anchor" + anchor, fit === "cover"), _defineProperty(_classNames, "overlay", overlay), _defineProperty(_classNames, "" + overlay, overlay), _classNames))
      }),
      style: _extends({}, fillColor && { backgroundColor: fillColor }, fit === "cover" && { backgroundImage: "url(" + src + ")" }, height && { height: height + "px" }, width && { width: width + "px" })
    },
    _react2.default.createElement("img", {
      className: getClassName({
        descendantName: "img"
      }),
      alt: alt,
      height: height,
      sizes: sizes,
      src: src,
      srcSet: srcSet,
      width: width
    })
  );
};

Image.displayName = "Image";

Image.overlays = ["scrimBottomDark"];

/* eslint-disable max-len */
Image.propTypes = {
  /**
   * Alternative text for the image, shown if the image cannot be displayed.
   */
  alt: _propTypes2.default.string.isRequired,

  /**
   * Anchor point for the image if using fit="cover".
   */
  anchor: _propTypes2.default.oneOf(_anchorPoints2.default),

  /**
   * Fallback hexadecimal fill color. The intent is to increase perceived loading speed. Do not use on images with transparency. Requires that an explicit width and height be set for the image.
   */
  fillColor: _propTypes2.default.string,

  /**
   * Fit of the image.
   */
  fit: _propTypes2.default.oneOf(["cover"]),

  /**
   * Height of the image, in pixels
   */
  height: _propTypes2.default.number,

  /**
   * Apply an overlay to the image, e.g. a scrim gradient for text legibility.
   * Should not be used if the image has a rasterized overlay.
   */
  overlay: _propTypes2.default.oneOf(Image.overlays),

  /**
   * The HTML [sizes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes attribute. Required if srcSet is defined.
   */
  sizes: _propTypes2.default.string,

  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  src: _propTypes2.default.string.isRequired,

  /**
   * A comma-separated list indicating a set of possible image sources for the user agent to use for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  srcSet: _propTypes2.default.string,

  /**
   * Width of the image, in pixels
   */
  width: _propTypes2.default.number
};
/* eslint-enable max-len */

Image.defaultProps = {
  anchor: "C"
};

// Provide sane defaults for sizes props on images in common scenarios.
Image.sizes = {
  // These use the following variables:
  //   75rem = 1200px
  //   48rem = 768px
  //   1.125rem = 18px (half grid gutter)
  //
  // So this is the equivalent of:
  //   (min-width: 75rem): calc(var(--space-section-max-width) - var(--space-grid-gutter))
  //   default: calc(100vw - var(--space-grid-gutter))
  section: "(min-width: 75rem) calc(75rem - 2.25rem), calc(100vw - 2.25rem)",

  // This is the equivalent of:
  //   (min-width: 75rem): calc((var(--space-section-max-width) - (6 * var(--space-grid-gutter-half))) * 1/3)
  //   (min-width: 48rem): calc(100vw - (6 * var(--space-grid-gutter-half))) * 1/3)
  //   default: calc(100vw - var(--space-grid-gutter))
  oneThird: "(min-width: 75rem) calc((75rem - 6.75rem) * (1/3)), (min-width: 48rem) calc((100vw - 6.75rem) * (1/3)), calc(100vw - 2.25rem)",

  // This is the equivalent of:
  //   (min-width: 75rem): calc((var(--space-section-max-width) - (3 * var(--space-grid-gutter-half))) * 2/3)
  //   (min-width: 48rem): calc(100vw - (3 * var(--space-grid-gutter-half))) * 2/3)
  //   default: calc(100vw - var(--space-grid-gutter))
  twoThirds: "(min-width: 75rem) calc((75rem - 3.375rem) * (2/3)), (min-width: 48rem) calc((100vw - 3.375rem) * (2/3)), calc(100vw - 2.25rem)",

  // This is just 100% of the viewport width
  edgeToEdge: "100vw"
};

exports.default = Image;