"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _currentOpenTabPanelContext = require("../../currentOpenTabPanelContext");

var _defaultOpenTabPanelContext = require("../../defaultOpenTabPanelContext");

var _setOpenTabPanelContext = require("../../setOpenTabPanelContext");

var _Panel = require("../../../Panel");

var _Panel2 = _interopRequireDefault(_Panel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TabPanel = function (_Component) {
  _inherits(TabPanel, _Component);

  function TabPanel() {
    _classCallCheck(this, TabPanel);

    return _possibleConstructorReturn(this, (TabPanel.__proto__ || Object.getPrototypeOf(TabPanel)).apply(this, arguments));
  }

  _createClass(TabPanel, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          children = _props.children,
          id = _props.id,
          tabTitle = _props.tabTitle;

      var getClassName = (0, _utilityClassNames2.default)(TabPanel.displayName);

      return _react2.default.createElement(
        _setOpenTabPanelContext.Consumer,
        null,
        function (setOpenPanel) {
          return _react2.default.createElement(
            _currentOpenTabPanelContext.Consumer,
            null,
            function (currentOpenPanel) {
              return _react2.default.createElement(
                _defaultOpenTabPanelContext.Consumer,
                null,
                function (defaultOpenPanel) {
                  return _react2.default.createElement(
                    "div",
                    {
                      "aria-labelledby": "tab-" + id,
                      role: "tabpanel",
                      className: getClassName({
                        states: (0, _classnames2.default)({
                          visibleAtSiteHeaderViewport: currentOpenPanel === id || currentOpenPanel === null && defaultOpenPanel === id
                        })
                      }),
                      "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(TabPanel),
                      "data-rehydratable-in-place": true,
                      "data-tab-title": tabTitle,
                      id: id,
                      tabIndex: "-1"
                    },
                    _react2.default.createElement(
                      _Panel2.default,
                      {
                        onBack: function onBack() {
                          return setOpenPanel(null);
                        },
                        open: currentOpenPanel === id,
                        title: tabTitle
                      },
                      _react2.default.createElement(
                        "div",
                        {
                          className: getClassName({
                            descendantName: "children"
                          })
                        },
                        children
                      )
                    )
                  );
                }
              );
            }
          );
        }
      );
    }
  }]);

  return TabPanel;
}(_react.Component);

TabPanel.propTypes = {
  /**
   * The contents of this panel.
   */
  children: _propTypes2.default.node,

  /**
   * The id of this panel. Should be unique on the current page.
   */
  id: _propTypes2.default.string.isRequired,

  /**
   * The title of this panel, which will be shown in its associated tab.
   */
  tabTitle: _propTypes2.default.string.isRequired
};
TabPanel.displayName = "SiteHeader.Flyouts.TabPanel";
exports.default = TabPanel;