"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _arrowRight = require("@wel-ui/icons/lib/ui/arrow-right");

var _arrowRight2 = _interopRequireDefault(_arrowRight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavItem = function NavItem(_ref) {
  var description = _ref.description,
      href = _ref.href,
      summary = _ref.summary,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("SiteHeaderNavItem");

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)({
          summary: summary
        })
      })
    },
    _react2.default.createElement(
      "a",
      {
        className: getClassName({ descendantName: "link" }),
        href: href,
        role: "menuitem"
      },
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "linkInner" }) },
        _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "linkText" }) },
          title
        ),
        summary ? _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "linkIcon" }) },
          _react2.default.createElement(_arrowRight2.default, null)
        ) : null
      ),
      description && !summary ? _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "linkDescription" }) },
        description
      ) : null
    )
  );
};

NavItem.propTypes = {
  /**
   * The description of this `NavItem`
   */
  description: _propTypes2.default.string,

  /**
   * The destination that this `NavItem` links to
   */
  href: _propTypes2.default.string.isRequired,

  /**
   * Whether or not this is a summary item, like "All Products ->"
   */
  summary: _propTypes2.default.bool,

  /**
   * The title of this `NavItem`
   */
  title: _propTypes2.default.string.isRequired
};

NavItem.displayName = "SiteHeader.NavItem";

exports.default = NavItem;