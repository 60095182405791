"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _jsCookie = require("js-cookie");

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _componentButton = require("@wel-ui/component-button");

var _componentButton2 = _interopRequireDefault(_componentButton);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _LinkItem = require("./components/LinkItem");

var _LinkItem2 = _interopRequireDefault(_LinkItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DismissibleBanner = function (_Component) {
  _inherits(DismissibleBanner, _Component);

  _createClass(DismissibleBanner, null, [{
    key: "cookiesEnabled",
    value: function cookiesEnabled() {
      // cookieEnabled needs to be exactly false, not undefined or null
      if (typeof navigator !== "undefined" && navigator.cookieEnabled === false) {
        return false;
      }

      return true;
    }
  }]);

  function DismissibleBanner(props) {
    _classCallCheck(this, DismissibleBanner);

    var _this = _possibleConstructorReturn(this, (DismissibleBanner.__proto__ || Object.getPrototypeOf(DismissibleBanner)).call(this, props));

    _this.state = {
      manuallyClosed: false,
      isNewUser: false
    };

    _this.getClassName = (0, _utilityClassNames2.default)(DismissibleBanner.displayName);
    _this.handleClose = _this.handleClose.bind(_this);
    return _this;
  }

  _createClass(DismissibleBanner, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.markUser();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (!this.state.isNewUser) {
        // When the component updates, there's a possibility that we need to
        // re-mark the user, but only if we haven't already identified them as
        // a new user.
        this.markUser();
      }
      if (prevProps.alreadySeenCookieName !== this.props.alreadySeenCookieName || prevProps.alreadySeenCookieExpectedValue !== this.props.alreadySeenCookieExpectedValue || prevProps.hideIfAlreadySeen !== this.props.hideIfAlreadySeen && this.props.hideIfAlreadySeen) {
        // If any cookie-related props have changed, we'll go through user
        // marking again. In the interim, reset to the default new user state.
        this.setState({
          isNewUser: false
        });
      }
    }
  }, {
    key: "handleClose",
    value: function handleClose() {
      this.setState({
        manuallyClosed: true
      });
    }
  }, {
    key: "markUser",
    value: function markUser() {
      if (!DismissibleBanner.cookiesEnabled()) {
        // If cookies are disabled, we shouldn't mark the user. We also shouldn't
        // show the banner to them, so we want to skip any state setting.
        return;
      }

      if (this.props.hideIfAlreadySeen) {
        if (_jsCookie2.default.get(this.props.alreadySeenCookieName) !== this.props.alreadySeenCookieExpectedValue) {
          this.setState({
            isNewUser: true
          });

          var cookieOptions = { expires: 365 };

          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = this.props.validParentDomains[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var domain = _step.value;

              if (window.location.hostname.endsWith(domain)) {
                cookieOptions.domain = "." + domain;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _jsCookie2.default.set(this.props.alreadySeenCookieName, this.props.alreadySeenCookieExpectedValue, cookieOptions);
        }
      }
    }
  }, {
    key: "shouldBeClosed",
    value: function shouldBeClosed() {
      var hideIfAlreadySeen = this.props.hideIfAlreadySeen;
      var _state = this.state,
          manuallyClosed = _state.manuallyClosed,
          isNewUser = _state.isNewUser;


      if (manuallyClosed) {
        // Should always be closed if the user has explicitly closed it on this page.
        return true;
      }

      if (hideIfAlreadySeen) {
        if (isNewUser) {
          // The user is a new user, and hasn't closed the banner yet.
          return false;
        }

        // Either the user has been here before, or we haven't checked for the
        // cookie mark yet - i.e. this could be an initial render.
        return true;
      }

      // Should show the banner: it has not manually been closed, and checking
      // cookies is disabled. This state could be reached if a parent component
      // were doing the hiding and showing.
      return false;
    }
  }, {
    key: "render",
    value: function render() {
      var rehydrationProps = {
        "data-fixed": this.props.fixed || false,
        "data-dismiss-button-text": this.props.dismissButtonText,
        "data-hide-if-already-seen": this.props.hideIfAlreadySeen
      };

      if (this.props.hideIfAlreadySeen) {
        rehydrationProps["data-already-seen-cookie-name"] = this.props.alreadySeenCookieName;
        rehydrationProps["data-already-seen-cookie-expected-value"] = this.props.alreadySeenCookieExpectedValue;
        rehydrationProps["data-valid-parent-domains"] = JSON.stringify(this.props.validParentDomains);
      }

      return _react2.default.createElement(
        "div",
        _extends({
          role: "alertdialog",
          className: this.getClassName({
            modifiers: (0, _classnames2.default)({
              fixed: this.props.fixed
            }),
            states: (0, _classnames2.default)({
              closed: this.shouldBeClosed()
            })
          })
        }, rehydrationProps),
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName({
              descendantName: "closeButtonWrapper"
            })
          },
          _react2.default.createElement(
            "button",
            {
              className: this.getClassName({ descendantName: "closeButton" }),
              onClick: this.handleClose
            },
            _react2.default.createElement(_cross2.default, { label: "Close" })
          )
        ),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "wrapper" }) },
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "inner" }) },
            _react2.default.createElement(
              "p",
              { className: this.getClassName({ descendantName: "heading" }) },
              this.props.heading
            ),
            _react2.default.createElement(
              "p",
              { className: this.getClassName({ descendantName: "text" }) },
              this.props.text
            ),
            this.props.children ? _react2.default.createElement(
              "ul",
              { className: this.getClassName({ descendantName: "links" }) },
              this.props.children
            ) : null
          ),
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "button" }) },
            _react2.default.createElement(
              _componentButton2.default,
              { color: "secondary", kind: "button", onClick: this.handleClose },
              this.props.dismissButtonText
            )
          )
        )
      );
    }
  }]);

  return DismissibleBanner;
}(_react.Component);

DismissibleBanner.displayName = "DismissibleBanner";
DismissibleBanner.propTypes = {
  /**
   * The name of the cookie, which flags a user who has already seen the banner. Only used if `hideIfAlreadySeen` is true.
   */
  alreadySeenCookieName: _propTypes2.default.string,
  /**
   * The expected value of the cookie; if the cookie has a different value than expected, the banner will show. Only used if `hideIfAlreadySeen` is true.
   */
  alreadySeenCookieExpectedValue: _propTypes2.default.string,
  /**
   * The links to display inside the banner.
   */
  children: _propTypes2.default.node,
  /**
   * Text to show inside the button.
   */
  dismissButtonText: _propTypes2.default.string,
  /**
   * Sets the component to be fixed to the bottom of the viewport, on top of all other page content.
   */
  fixed: _propTypes2.default.bool,
  /**
   * Text to show in the heading area.
   */
  heading: _propTypes2.default.string.isRequired,
  /**
   * Hide the banner if the current user has seen it before.
   */
  hideIfAlreadySeen: _propTypes2.default.bool,
  /**
   * Text to show in the content area.
   */
  text: _propTypes2.default.string.isRequired,
  /**
   * Root domains that we're allowed to set cookies on.
   */
  validParentDomains: _propTypes2.default.array
};
DismissibleBanner.defaultProps = {
  alreadySeenCookieName: "tr_ewp_dismissiblebanner",
  alreadySeenCookieExpectedValue: "may2018",
  dismissButtonText: "Okay to continue",
  hideIfAlreadySeen: true,
  validParentDomains: ["reuters.com", "thomsonreuters.com"]
};


DismissibleBanner.LinkItem = _LinkItem2.default;

exports.default = DismissibleBanner;