"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _RehydratablePageNavInner = require("./RehydratablePageNavInner");

var _RehydratablePageNavInner2 = _interopRequireDefault(_RehydratablePageNavInner);

var _Anchor = require("./components/Anchor");

var _Anchor2 = _interopRequireDefault(_Anchor);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var PageNav = function PageNav(_ref) {
  var sticky = _ref.sticky,
      props = _objectWithoutProperties(_ref, ["sticky"]);

  var getClassName = (0, _utilityClassNames2.default)("PageNav");

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: (0, _classnames2.default)({ sticky: sticky }) }) },
    _react2.default.createElement(_RehydratablePageNavInner2.default, props)
  );
};

PageNav.displayName = "PageNav";

PageNav.propTypes = {
  /**
   * Action nodes. Use to add a call-to-action to the PageNav, like a Button.
   */
  actions: _propTypes2.default.node,
  /**
   * Child nodes. Should be `PageNav.Item` components.
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Share node. Should be a SocialDropdown component.
   */
  share: _propTypes2.default.node,
  /**
   * Enable sticky behaviour to make the PageNav stick to the top of the screen.
   */
  sticky: _propTypes2.default.bool,
  /**
   * Enable scroll spy to update the current item as you scroll the page.
   */
  spy: _propTypes2.default.bool
};

PageNav.defaultProps = {
  sticky: true,
  spy: true
};

PageNav.Anchor = _Anchor2.default;
PageNav.Item = _Item2.default;

exports.default = PageNav;