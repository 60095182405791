"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Modal = require("./Modal");

Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Modal).default;
  }
});

var _isOpenContext = require("./isOpenContext");

Object.defineProperty(exports, "IsModalOpenConsumer", {
  enumerable: true,
  get: function get() {
    return _isOpenContext.Consumer;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }