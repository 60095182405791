"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Fieldset = function Fieldset(_ref) {
  var children = _ref.children,
      legend = _ref.legend;

  var getClassName = (0, _utilityClassNames2.default)(Fieldset.displayName);

  return _react2.default.createElement(
    "fieldset",
    { className: getClassName() },
    legend && _react2.default.createElement(
      "legend",
      { className: getClassName({ descendantName: "legend" }) },
      legend
    ),
    children
  );
};

Fieldset.displayName = "Form.Fieldset";

Fieldset.propTypes = {
  /**
   * Form controls composition
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Optional legend for the fieldset (bolding supported with `<b>`)
   */
  legend: _propTypes2.default.node
};

exports.default = Fieldset;