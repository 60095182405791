"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _GlobalHeader = require("./GlobalHeader");

var _GlobalHeader2 = _interopRequireDefault(_GlobalHeader);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_GlobalHeader2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("GlobalHeader");

    var leftItemsNode = domNode.querySelector("." + getClassName({ descendantName: "leftItems" }));

    var rightItemsNode = domNode.querySelector("." + getClassName({ descendantName: "rightItems" }));

    var leftItems = leftItemsNode ? (0, _componentRehydratable.rehydrateChildren)(leftItemsNode, extra) : null;
    var rightItems = rightItemsNode ? (0, _componentRehydratable.rehydrateChildren)(rightItemsNode, extra) : null;

    var rehydratedProps = {
      isRehydrating: true,
      leftItems: leftItems,
      rightItems: rightItems
    };

    return _react2.default.createElement(_GlobalHeader2.default, rehydratedProps);
  }
};

exports.default = rehydrator;