"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextCardBase = require("./TextCardBase");

var _TextCardBase2 = _interopRequireDefault(_TextCardBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextCardBase2.default;