"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _MultiSelectDropdown = require("../MultiSelectDropdown");

var _MultiSelectDropdown2 = _interopRequireDefault(_MultiSelectDropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TagFilter = function TagFilter(_ref) {
  var index = _ref.index,
      handleChange = _ref.handleChange,
      label = _ref.label,
      noOptionsMessage = _ref.noOptionsMessage,
      options = _ref.options,
      placeholder = _ref.placeholder,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(TagFilter.displayName);
  var name = label.replace(/\s+/g, "_") + index;

  return (
    // Control is wrapped in <label>
    // eslint-disable-next-line jsx-a11y/label-has-for
    _react2.default.createElement(
      "div",
      null,
      label && _react2.default.createElement(
        "label",
        { htmlFor: name },
        _react2.default.createElement(
          "span",
          {
            className: getClassName({
              descendantName: "label"
            })
          },
          label
        )
      ),
      _react2.default.createElement(_MultiSelectDropdown2.default, {
        handleChange: handleChange,
        name: name,
        noOptionsMessage: noOptionsMessage,
        options: options,
        placeholder: placeholder,
        value: value
      })
    )
  );
};

TagFilter.displayName = "TableWithFilters.TagFilter";

TagFilter.propTypes = {
  /**
   * On change event handler
   */
  handleChange: _propTypes2.default.func.isRequired,

  /**
   * Remove selected tag from available filter options
   */
  index: _propTypes2.default.number.isRequired,

  /**
   * Label for the input
   */
  label: _propTypes2.default.string,

  /**
   * Message displayed in the filter if there is no option resulting from the typeahead input
   */
  noOptionsMessage: _propTypes2.default.string.isRequired,

  /**
   * Data to generate listed options
   */
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string,
    value: _propTypes2.default.string
  })),

  /**
   * Placeholder for the filter input
   */
  placeholder: _propTypes2.default.string.isRequired,

  /**
   * Data to generate selected options
   */
  value: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string,
    value: _propTypes2.default.string
  }))
};

TagFilter.defaultProps = {
  isMulti: true,
  noOptionsMessage: "No results found that match your search term. Please try another or check your spelling.",
  placeholder: "Add filter(s)...",
  hideSelectedOptions: false
};

exports.default = TagFilter;