"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Asset = require("./Asset");

var _Asset2 = _interopRequireDefault(_Asset);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Asset2.default;