import Button from "@wel-ui/component-button";
import React, { Component } from "react";
import getClassNameFactory from "@wel-ui/utility-class-names";
import loginState from "../../loginState";
import { AuthState } from "@wel-ui/utility-authentication";
import RichText from "@wel-ui/component-rich-text";
import VerticalSpacing from "@wel-ui/component-vertical-spacing";

class UserInfo extends Component {
  static displayName = "UserInfo";

  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loginStateChange = this.loginStateChange.bind(this);

    if (loginState) {
      loginState.onChange(this.loginStateChange);
    }
  }

  componentWillUnmount() {
    if (loginState) {
      loginState.offChange(this.loginStateChange);
    }
  }

  handleLogin() {
    loginState.login();
  }

  handleLogout() {
    loginState.logout();
  }

  loginStateChange() {
    this.forceUpdate();
  }

  render() {
    const getClassName = getClassNameFactory("UserInfo");

    const Wrapper = ({ children }) => (
      <div className={getClassName()}>{children}</div>
    );

    if (!loginState) {
      return <Wrapper />;
    }

    return (
      <Wrapper>
        <VerticalSpacing size="m">
          <RichText>
            <p>Login and logout using the button below.</p>
            <p>
              current logged in user:{" "}
              <b>
                {loginState.authState === AuthState.LOGGED_IN
                  ? loginState.userName
                  : "guest"}
              </b>
            </p>
          </RichText>
        </VerticalSpacing>
        <VerticalSpacing size="m">
          {loginState.authState === AuthState.LOGGED_IN ? (
            <Button kind="button" color="primary" onClick={this.handleLogout}>
              logout
            </Button>
          ) : null}
          {loginState.authState === AuthState.LOGGED_OUT ? (
            <Button kind="button" color="primary" onClick={this.handleLogin}>
              login
            </Button>
          ) : null}
          {loginState.authState === AuthState.UNKNOWN ? (
            <Button kind="button" color="primary" disabled>
              Please wait...
            </Button>
          ) : null}
        </VerticalSpacing>
      </Wrapper>
    );
  }
}

export default UserInfo;
