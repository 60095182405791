"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Dropdown = require("./Dropdown");

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Dropdown2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    // NB you can't and shouldn't ever need to rehydrate functions
    var props = {
      isRehydrating: true,
      block: readData("block"),
      items: readData("items"),
      placeholder: readData("placeholder"),
      size: readData("size"),
      value: readData("value")
    };

    return _react2.default.createElement(_Dropdown2.default, props);
  }
};

exports.default = rehydrator;