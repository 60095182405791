"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _BaseItem = require("./BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_BaseItem2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderBaseItem");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "contentInner" }));

    var reactifiedChildren = childrenNode ? (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra) : null;

    var iconNode = domNode.querySelector("." + getClassName({ descendantName: "linkIcon" }));

    var rehydratedProps = {
      isRehydrating: true,
      icon: iconNode ? (0, _componentRehydratable.rehydrateChildren)(iconNode, extra) : null
    };

    var href = domNode.getAttribute("data-href");

    if (href && href.length) {
      rehydratedProps.href = href;
    }

    var label = domNode.getAttribute("data-label");

    if (label && label.length) {
      rehydratedProps.label = label;
    }

    var longLabel = domNode.getAttribute("data-long-label");

    if (longLabel && longLabel.length) {
      rehydratedProps.longLabel = longLabel;
    }

    var narrow = domNode.getAttribute("data-narrow");

    if (narrow && narrow.length) {
      rehydratedProps.narrow = narrow === "true";
    }

    return _react2.default.createElement(
      _BaseItem2.default,
      rehydratedProps,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;