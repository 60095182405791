"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentSvg = require("@wel-ui/component-svg");

var _componentSvg2 = _interopRequireDefault(_componentSvg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var Icon = function Icon(props) {
  return _react2.default.createElement(_componentSvg2.default, props, Icon.svg);
};

Icon.propTypes = {
  height: _propTypes2.default.number,
  label: _propTypes2.default.string,
  width: _propTypes2.default.number
};

Icon.displayName = "UiChevronUp";

Icon.svg = _react2.default.createElement(
  "svg",
  { viewBox: "0 0 24 24" },
  _react2.default.createElement("path", {
    d: "M8 14l4-4 4 4",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "square",
    strokeWidth: "2"
  })
);

exports.default = Icon;
