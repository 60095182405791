"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentDateTime = require("@wel-ui/component-date-time");

var _componentDateTime2 = _interopRequireDefault(_componentDateTime);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TickerItem = function (_Component) {
  _inherits(TickerItem, _Component);

  function TickerItem(props) {
    _classCallCheck(this, TickerItem);

    var _this = _possibleConstructorReturn(this, (TickerItem.__proto__ || Object.getPrototypeOf(TickerItem)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)("TickerItem");
    return _this;
  }

  _createClass(TickerItem, [{
    key: "render",
    value: function render() {
      var titleEl = _react2.default.createElement(
        "span",
        {
          className: this.getClassName({
            descendantName: "title",
            modifiers: this.props.dateTime ? "dated" : ""
          })
        },
        this.props.title
      );

      var timeEl = this.props.dateTime ? _react2.default.createElement(
        "span",
        {
          className: this.getClassName({
            descendantName: "time"
          })
        },
        _react2.default.createElement(_componentDateTime2.default, {
          currentTime: this.props.currentTime,
          dateTime: this.props.dateTime,
          lang: this.props.lang,
          relative: true
        })
      ) : null;

      return _react2.default.createElement(
        "li",
        {
          className: this.getClassName({
            utilities: this.props.lead ? "typographySmallCaps" : ""
          }),
          "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(TickerItem),
          "data-rehydratable-in-place": true,
          "data-date-time": this.props.dateTime,
          "data-href": this.props.href,
          "data-lang": this.props.lang,
          "data-lead": this.props.lead,
          "data-title": this.props.title
        },
        this.props.href ? _react2.default.createElement(
          "a",
          {
            href: this.props.href,
            className: this.getClassName({
              descendantName: "link"
            })
          },
          titleEl,
          timeEl
        ) : _react2.default.createElement(
          "span",
          null,
          titleEl,
          timeEl
        )
      );
    }
  }]);

  return TickerItem;
}(_react.Component);

TickerItem.propTypes = {
  /**
   * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time. Defaults to the time that the current page loaded; you should set this if you want the time to dynamically update as time progresses.
   */
  currentTime: _propTypes2.default.string,
  /**
   * An ISO 8601, RFC 2822 or `new Date(str)` compatible date string.
   */
  dateTime: _propTypes2.default.string,
  /**
   * A link to this story. Will render without an anchor element if this is not provided.
   */
  href: _propTypes2.default.string,
  /**
   * The language of this item. Used to localize the relative time.
   */
  lang: _propTypes2.default.string,
  /**
   * Whether this should display as the first item or not.
   */
  lead: _propTypes2.default.bool,
  /**
   * The title of this item.
   */
  title: _propTypes2.default.string.isRequired
};
TickerItem.defaultProps = {
  lang: "en"
};
TickerItem.displayName = "Ticker.Item";
exports.default = TickerItem;