"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Item = function Item(_ref) {
  var active = _ref.active,
      href = _ref.href,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("PageNavItem");

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({ states: (0, _classnames2.default)({ active: active }) }),
      "data-active": active ? JSON.stringify(active) : null,
      "data-href": JSON.stringify(href),
      "data-title": JSON.stringify(title),
      "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(Item),
      "data-rehydratable-in-place": true
    },
    _react2.default.createElement(
      "a",
      { className: getClassName({ descendantName: "inner" }), href: href },
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "title" }) },
        title
      )
    )
  );
};

Item.displayName = "PageNav.Item";

Item.propTypes = {
  /**
   * Set this item to active. Use for setting the default item, or defining the current item if not using PageNav's `spy` functionality. Will be overwritten when using `spy`.
   */
  active: _propTypes2.default.bool,
  /**
   * URL for the item. Should be an anchor if using PageNav on a single page (such as with `spy`) or a URL if not. If using on multiple pages, you will need to set the `active` Item manually.
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Title of the item.
   */
  title: _propTypes2.default.string.isRequired
};

exports.default = Item;