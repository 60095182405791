"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Heading = function Heading(_ref) {
  var _className;

  var align = _ref.align,
      children = _ref.children,
      rank = _ref.rank,
      boldOrange = _ref.boldOrange,
      type = _ref.type;

  var getClassName = (0, _utilityClassNames2.default)(Heading.displayName);

  var HeadingTag = "span";

  if (Heading.ranks.indexOf(rank) >= 0) {
    HeadingTag = "h" + rank;
  }

  return _react2.default.createElement(
    HeadingTag,
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)((_className = {}, _defineProperty(_className, type, true), _defineProperty(_className, align, align !== "left"), _defineProperty(_className, "boldOrange", boldOrange), _className)),
        utilities: type === "small-caps" && "typographySmallCaps"
      })
    },
    children
  );
};

Heading.alignments = ["center", "left", "right"];
Heading.ranks = ["1", "2", "3", "4", "5", "6"];
Heading.types = ["small-caps", "xs", "s", "m", "l", "xl"];

Heading.displayName = "Heading";

/* eslint-disable max-len  */
Heading.propTypes = {
  /**
   * Heading children.
   */
  align: _propTypes2.default.oneOf(Heading.alignments),
  /**
   * Heading children.
   */
  children: _propTypes2.default.node,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. If null, will render a `<div />`.
   */
  rank: _propTypes2.default.oneOf(Heading.ranks),
  /**
   * Set color of inline `<b />` and `<strong />` elements to dark orange.
   */
  boldOrange: _propTypes2.default.bool,
  /**
   * Style of the heading.
   */
  type: _propTypes2.default.oneOf(Heading.types).isRequired
};
/* eslint-enable max-len */

Heading.defaultProps = {
  align: "left"
};

exports.default = Heading;