"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SectionBridge = exports.Section = undefined;

var _Section = require("./Section");

var _Section2 = _interopRequireDefault(_Section);

var _SectionBridge = require("./SectionBridge");

var _SectionBridge2 = _interopRequireDefault(_SectionBridge);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Section2.default;
exports.Section = _Section2.default;
exports.SectionBridge = _SectionBridge2.default;