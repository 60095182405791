"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

// Default configuration structure
var currentConfiguration = {
  styles: {
    namespace: null
  }
};

var updateConfiguration = function updateConfiguration(newValues) {
  currentConfiguration = _extends({}, currentConfiguration, newValues);
};

// Add a way to set the configuration without a knowledge of this package.
// We need this, because sometimes we don't have access to the actual wel
// context.
if (global.welUiConfig) {
  updateConfiguration(global.welUiConfig);
}

exports.default = {
  get current() {
    return currentConfiguration;
  }
};
exports.updateConfiguration = updateConfiguration;