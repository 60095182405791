import React from "react";
import ReactDOM from "react-dom";
import AjaxContent from "@wel-ui/component-ajax-content";
import Modal from "@wel-ui/component-modal";

document.querySelector("#root").addEventListener("click", e => {
  const { target } = e;
  const anchor = target.closest(".tr-Anchor[data-content-modal]");

  if (anchor) {
    const { contentLocation, modalLabel } = anchor.dataset;

    // The <Modal /> will use a portal. We can do the root render detached from
    // the main DOM; when ReactDOM.render reaches the portal, it'll attach to
    // #modalRoot.
    let el = document.createElement("div");

    const closeThisComponent = () => {
      ReactDOM.unmountComponentAtNode(el);

      // Remove the reference, so it can get garbage collected
      el = null;
    };

    ReactDOM.render(
      <Modal ariaLabel={modalLabel} closeThisComponent={closeThisComponent}>
        <AjaxContent location={contentLocation} />
      </Modal>,
      el
    );

    e.stopPropagation();
    e.preventDefault();

    return false;
  }
});
