"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RichText = function RichText(_ref) {
  var children = _ref.children,
      compact = _ref.compact,
      large = _ref.large;

  var getClassName = (0, _utilityClassNames2.default)(RichText.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)(compact && "compact", large && "large")
      })
    },
    children
  );
};

RichText.displayName = "RichText";

RichText.propTypes = {
  /**
   * Child nodes passed to the component.
   */
  children: _propTypes2.default.node,

  /**
   * Compact variant modifier
   */
  compact: _propTypes2.default.bool,

  /**
   * Large variant modifier
   */
  large: _propTypes2.default.bool
};

exports.default = RichText;