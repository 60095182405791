"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Cart = require("./Cart");

var _Cart2 = _interopRequireDefault(_Cart);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityRehydrator = require("@wel-ui/utility-rehydrator");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Cart2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var loginManager = (0, _utilityRehydrator.getService)("LoginManager");

    var rehydratedProps = {
      isRehydrating: true
    };

    var label = domNode.getAttribute("data-label");

    if (label && label.length) {
      rehydratedProps.label = label;
    }

    return _react2.default.createElement(_Cart2.default, _extends({}, rehydratedProps, { loginManager: loginManager }));
  }
};

exports.default = rehydrator;