import React from "react";
import CartInfo from "./CartInfo";
import { getRehydratableName } from "@wel-ui/component-rehydratable";

const rehydrator = {
  dataName: getRehydratableName(CartInfo),

  // eslint-disable-next-line react/display-name
  elementToReact: () => {
    const props = {
      isRehydrating: true
    };

    return <CartInfo {...props} />;
  }
};

export default rehydrator;
