"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkItem = function LinkItem(_ref) {
  var href = _ref.href,
      text = _ref.text;

  var getClassName = (0, _utilityClassNames2.default)("DismissibleBannerLinkItem");

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(
      "a",
      { href: href },
      text
    )
  );
};

LinkItem.displayName = "DismissibleBanner.LinkItem";

LinkItem.propTypes = {
  /**
   * The link destination
   */
  href: _propTypes2.default.string.isRequired,

  /**
   * The title of the link
   */
  text: _propTypes2.default.string.isRequired
};

exports.default = LinkItem;