import "./services/campaign-tracking";

import loginState from "./loginState";
import getAnalytics from "@wel-ui/utility-analytics";
import rehydrate, {
  getService,
  registerRehydrator,
  registerService
} from "@wel-ui/utility-rehydrator";
import { rehydrators } from "@wel-ui/component-compositions";
import { rehydrator as cartInfoRehydrator } from "./components/CartInfo";
import { rehydrator as userInfoRehydrator } from "./components/UserInfo";
import "./modals";

const firePageLoad = () => {
  if (window.PubSub) {
    window.PubSub.publish("analytics.pageLoad", {});
  }
};

const initializeRehydration = () => {
  for (const rehydrator of rehydrators) {
    registerRehydrator(rehydrator);
  }

  registerRehydrator(cartInfoRehydrator);
  registerRehydrator(userInfoRehydrator);

  if (typeof getService("LoginManager") === "undefined") {
    registerService("LoginManager", loginState);
  }
};

const gtm = getAnalytics("gtm");
const consoleAnalytics = getAnalytics("console");

const analytics = (...args) => {
  gtm(...args);

  if (window.welUiEnableConsole) {
    consoleAnalytics(...args);
  }
};

const doRehydrate = () => {
  rehydrate(document.body, {
    analytics
  });
};

export default () => {
  initializeRehydration();

  // rehydrate on Adobe Target DOM change
  document.addEventListener("at-content-rendering-succeeded", () => {
    doRehydrate();
  });

  doRehydrate();

  firePageLoad();
};
