"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Grid = function Grid(_ref) {
  var _classNames;

  var children = _ref.children,
      flex = _ref.flex,
      gutterBorder = _ref.gutterBorder,
      gutterless = _ref.gutterless,
      gutterlessAtNarrow = _ref.gutterlessAtNarrow,
      variant = _ref.variant;

  var getClassName = (0, _utilityClassNames2.default)(Grid.displayName);

  var cleanVariant = variant.replace(/,/g, "");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, cleanVariant, true), _defineProperty(_classNames, "flex", flex), _defineProperty(_classNames, "gutterBorder", gutterBorder), _defineProperty(_classNames, "gutterless", gutterless), _defineProperty(_classNames, "gutterlessAtNarrow", gutterlessAtNarrow), _classNames))
      })
    },
    _react2.default.createElement(
      "div",
      {
        className: getClassName({
          descendantName: "items"
        })
      },
      children
    )
  );
};

Grid.displayName = "Grid";

var variants = ["1/3,2/3", "1/4,3/4", "1/6,2/3,1/6", "2/3,1/3", "3/4,1/4", "7/12,5/12", "collage-1", "collage-2", "even-2", "even-3", "even-4", "even-5", "even-6"];

/* eslint-disable max-len */
Grid.propTypes = {
  /**
   * The contents of the Grid. Should always be `<Grid.Item />` components.
   */
  children: _propTypes2.default.node,
  /**
   * Use CSS flex rather than CSS grid. Useful for debugging.
   */
  flex: _propTypes2.default.bool,
  /**
   * Add a border to the gutter.
   */
  gutterBorder: _propTypes2.default.bool,
  /**
   * Gutterless mode.
   */
  gutterless: _propTypes2.default.bool,
  /**
   * Gutterless mode for stacked/narrow only.
   */
  gutterlessAtNarrow: _propTypes2.default.bool,
  /**
   * The grid variant to use.
   */
  variant: _propTypes2.default.oneOf(variants).isRequired
};
/* eslint-enable max-len */

Grid.defaultProps = {
  flex: false,
  gutterBorder: false,
  gutterless: false,
  gutterlessAtNarrow: false
};

Grid.Item = _Item2.default;
Grid.variants = variants;

exports.default = Grid;