"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Anchor = function Anchor(_ref) {
  var id = _ref.id;

  var getClassName = (0, _utilityClassNames2.default)("PageNavAnchor");

  return _react2.default.createElement("div", { className: getClassName(), id: id });
};

Anchor.displayName = "PageNav.Anchor";

Anchor.propTypes = {
  /**
   * ID for the element. Use with PageNav for anchor links and scroll tracking.
   */
  id: _propTypes2.default.string.isRequired
};

exports.default = Anchor;