"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.privacyControlRehydrator = undefined;

var _PrivacyControl = require("./PrivacyControl");

var _PrivacyControl2 = _interopRequireDefault(_PrivacyControl);

var _PrivacyControl3 = require("./PrivacyControl.rehydrator");

var _PrivacyControl4 = _interopRequireDefault(_PrivacyControl3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PrivacyControl2.default;
exports.privacyControlRehydrator = _PrivacyControl4.default;