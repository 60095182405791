"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Flyouts = require("./Flyouts");

var _Flyouts2 = _interopRequireDefault(_Flyouts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)(_Flyouts2.default.displayName);

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Flyouts2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "panel" }));
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);
    var props = {
      isRehydrating: true,
      summaryLinkHref: domNode.getAttribute("data-summary-link-href") || null,
      summaryLinkText: domNode.getAttribute("data-summary-link-text") || null
    };

    return _react2.default.createElement(
      _Flyouts2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;