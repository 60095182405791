"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _BaseItem = require("./components/BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _Cart = require("./components/Cart");

var _Cart2 = _interopRequireDefault(_Cart);

var _Column = require("./components/Column");

var _Column2 = _interopRequireDefault(_Column);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

var _Login = require("./components/Login");

var _Login2 = _interopRequireDefault(_Login);

var _NavList = require("./components/NavList");

var _NavList2 = _interopRequireDefault(_NavList);

var _NavItem = require("./components/NavItem");

var _NavItem2 = _interopRequireDefault(_NavItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GlobalHeader = function GlobalHeader(_ref) {
  var leftItems = _ref.leftItems,
      rightItems = _ref.rightItems;

  var getClassName = (0, _utilityClassNames2.default)(GlobalHeader.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "inner" }) },
      leftItems && _react2.default.createElement(
        "ul",
        { className: getClassName({ descendantName: "leftItems" }) },
        leftItems
      ),
      rightItems && _react2.default.createElement(
        "ul",
        { className: getClassName({ descendantName: "rightItems" }) },
        rightItems
      )
    )
  );
};

GlobalHeader.displayName = "GlobalHeader";

/* eslint-disable max-len */
GlobalHeader.propTypes = {
  /**
   * Child nodes to appear on left hand side. Should be GlobalHeader.Item wrapped in a `<React.Fragment />`.
   */
  leftItems: _propTypes2.default.node,
  /**
   * Child nodes to appear on right hand side. Should be GlobalHeader.Item wrapped in a `<React.Fragment />`.
   */
  rightItems: _propTypes2.default.node
};

GlobalHeader.BaseItem = _BaseItem2.default;
GlobalHeader.Cart = _Cart2.default;
GlobalHeader.Column = _Column2.default;
GlobalHeader.Item = _Item2.default;
GlobalHeader.Login = _Login2.default;
GlobalHeader.NavList = _NavList2.default;
GlobalHeader.NavItem = _NavItem2.default;

exports.default = GlobalHeader;