"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "accent-height": "accentHeight",
  accept: "accept",
  acceptcharset: "acceptCharset",
  accesskey: "accessKey",
  accumulate: "accumulate",
  action: "action",
  additive: "additive",
  "alignment-baseline": "alignmentBaseline",
  allowfullscreen: "allowFullScreen",
  allowReorder: "allowReorder",
  allowtransparency: "allowTransparency",
  alphabetic: "alphabetic",
  alt: "alt",
  amplitude: "amplitude",
  "arabic-form": "arabicForm",
  ascent: "ascent",
  async: "async",
  attributeName: "attributeName",
  attributeType: "attributeType",
  autocomplete: "autoComplete",
  autoplay: "autoPlay",
  autoReverse: "autoReverse",
  azimuth: "azimuth",
  baseFrequency: "baseFrequency",
  "baseline-shift": "baselineShift",
  baseProfile: "baseProfile",
  bbox: "bbox",
  begin: "begin",
  bias: "bias",
  by: "by",
  calcMode: "calcMode",
  "cap-height": "capHeight",
  cellpadding: "cellPadding",
  cellspacing: "cellSpacing",
  charset: "charSet",
  checked: "checked",
  class: "className",
  classid: "classID",
  "clip-path": "clipPath",
  "clip-rule": "clipRule",
  clip: "clip",
  clipPathUnits: "clipPathUnits",
  "color-interpolation-filters": "colorInterpolationFilters",
  "color-interpolation": "colorInterpolation",
  "color-profile": "colorProfile",
  "color-rendering": "colorRendering",
  color: "color",
  cols: "cols",
  colspan: "colSpan",
  content: "content",
  contenteditable: "contentEditable",
  contentScriptType: "contentScriptType",
  contentStyleType: "contentStyleType",
  contextmenu: "contextMenu",
  controls: "controls",
  coords: "coords",
  crossorigin: "crossOrigin",
  cursor: "cursor",
  cx: "cx",
  cy: "cy",
  d: "d",
  data: "data",
  datetime: "dateTime",
  decelerate: "decelerate",
  defer: "defer",
  descent: "descent",
  diffuseConstant: "diffuseConstant",
  dir: "dir",
  direction: "direction",
  disabled: "disabled",
  display: "display",
  divisor: "divisor",
  "dominant-baseline": "dominantBaseline",
  download: "download",
  draggable: "draggable",
  dur: "dur",
  dx: "dx",
  dy: "dy",
  edgeMode: "edgeMode",
  elevation: "elevation",
  "enable-background": "enableBackground",
  enctype: "encType",
  end: "end",
  exponent: "exponent",
  externalResourcesRequired: "externalResourcesRequired",
  "fill-opacity": "fillOpacity",
  "fill-rule": "fillRule",
  fill: "fill",
  filter: "filter",
  filterRes: "filterRes",
  filterUnits: "filterUnits",
  "flood-color": "floodColor",
  "flood-opacity": "floodOpacity",
  "font-family": "fontFamily",
  "font-size-adjust": "fontSizeAdjust",
  "font-size": "fontSize",
  "font-stretch": "fontStretch",
  "font-style": "fontStyle",
  "font-variant": "fontVariant",
  "font-weight": "fontWeight",
  for: "htmlFor",
  form: "form",
  format: "format",
  formnovalidate: "formNoValidate",
  fr: "fr",
  frameborder: "frameBorder",
  from: "from",
  fx: "fx",
  fy: "fy",
  g1: "g1",
  g2: "g2",
  "glyph-name": "glyphName",
  "glyph-orientation-horizontal": "glyphOrientationHorizontal",
  "glyph-orientation-vertical": "glyphOrientationVertical",
  glyphRef: "glyphRef",
  gradientTransform: "gradientTransform",
  gradientUnits: "gradientUnits",
  hanging: "hanging",
  height: "height",
  hidden: "hidden",
  "horiz-adv-x": "horizAdvX",
  "horiz-origin-x": "horizOriginX",
  href: "href",
  hreflang: "hrefLang",
  httpequiv: "httpEquiv",
  icon: "icon",
  id: "id",
  ideographic: "ideographic",
  "image-rendering": "imageRendering",
  in: "in",
  in2: "in2",
  intercept: "intercept",
  k: "k",
  k1: "k1",
  k2: "k2",
  k3: "k3",
  k4: "k4",
  kernelMatrix: "kernelMatrix",
  kernelUnitLength: "kernelUnitLength",
  kerning: "kerning",
  keyPoints: "keyPoints",
  keySplines: "keySplines",
  keyTimes: "keyTimes",
  label: "label",
  lang: "lang",
  lengthAdjust: "lengthAdjust",
  "letter-spacing": "letterSpacing",
  "lighting-color": "lightingColor",
  limitingConeAngle: "limitingConeAngle",
  list: "list",
  local: "local",
  loop: "loop",
  manifest: "manifest",
  "marker-end": "markerEnd",
  "marker-mid": "markerMid",
  "marker-start": "markerStart",
  markerHeight: "markerHeight",
  markerUnits: "markerUnits",
  markerWidth: "markerWidth",
  mask: "mask",
  maskContentUnits: "maskContentUnits",
  maskUnits: "maskUnits",
  mathematical: "mathematical",
  max: "max",
  maxlength: "maxLength",
  media: "media",
  mediagroup: "mediaGroup",
  method: "method",
  min: "min",
  mode: "mode",
  multiple: "multiple",
  muted: "muted",
  name: "name",
  novalidate: "noValidate",
  numOctaves: "numOctaves",
  offset: "offset",
  opacity: "opacity",
  open: "open",
  operator: "operator",
  order: "order",
  orient: "orient",
  orientation: "orientation",
  origin: "origin",
  overflow: "overflow",
  "overline-position": "overlinePosition",
  "overline-thickness": "overlineThickness",
  "paint-order": "paintOrder",
  "panose-1": "panose1",
  path: "path",
  pathLength: "pathLength",
  pattern: "pattern",
  patternContentUnits: "patternContentUnits",
  patternTransform: "patternTransform",
  patternUnits: "patternUnits",
  ping: "ping",
  placeholder: "placeholder",
  "pointer-events": "pointerEvents",
  points: "points",
  pointsAtX: "pointsAtX",
  pointsAtY: "pointsAtY",
  pointsAtZ: "pointsAtZ",
  poster: "poster",
  preload: "preload",
  preserveAlpha: "preserveAlpha",
  preserveAspectRatio: "preserveAspectRatio",
  primitiveUnits: "primitiveUnits",
  r: "r",
  radiogroup: "radioGroup",
  radius: "radius",
  readonly: "readOnly",
  referrerPolicy: "referrerPolicy",
  refX: "refX",
  refY: "refY",
  rel: "rel",
  "rendering-intent": "renderingIntent",
  repeatCount: "repeatCount",
  repeatDur: "repeatDur",
  required: "required",
  requiredExtensions: "requiredExtensions",
  requiredFeatures: "requiredFeatures",
  restart: "restart",
  result: "result",
  role: "role",
  rotate: "rotate",
  rows: "rows",
  rowspan: "rowSpan",
  rx: "rx",
  ry: "ry",
  sandbox: "sandbox",
  scale: "scale",
  scope: "scope",
  scrolling: "scrolling",
  seamless: "seamless",
  seed: "seed",
  selected: "selected",
  "shape-rendering": "shapeRendering",
  shape: "shape",
  size: "size",
  sizes: "sizes",
  slope: "slope",
  spacing: "spacing",
  span: "span",
  specularConstant: "specularConstant",
  specularExponent: "specularExponent",
  speed: "speed",
  spellcheck: "spellCheck",
  spreadMethod: "spreadMethod",
  src: "src",
  srcdoc: "srcDoc",
  srcset: "srcSet",
  start: "start",
  startOffset: "startOffset",
  stdDeviation: "stdDeviation",
  stemh: "stemh",
  stemv: "stemv",
  step: "step",
  stitchTiles: "stitchTiles",
  "stop-color": "stopColor",
  "stop-opacity": "stopOpacity",
  "strikethrough-position": "strikethroughPosition",
  "strikethrough-thickness": "strikethroughThickness",
  string: "string",
  "stroke-dasharray": "strokeDasharray",
  "stroke-dashoffset": "strokeDashoffset",
  "stroke-linecap": "strokeLinecap",
  "stroke-linejoin": "strokeLinejoin",
  "stroke-miterlimit": "strokeMiterlimit",
  "stroke-opacity": "strokeOpacity",
  "stroke-width": "strokeWidth",
  stroke: "stroke",
  style: "style",
  surfaceScale: "surfaceScale",
  systemLanguage: "systemLanguage",
  tabindex: "tabIndex",
  tableValues: "tableValues",
  target: "target",
  targetX: "targetX",
  targetY: "targetY",
  "text-anchor": "textAnchor",
  "text-decoration": "textDecoration",
  "text-rendering": "textRendering",
  textLength: "textLength",
  title: "title",
  to: "to",
  transform: "transform",
  type: "type",
  u1: "u1",
  u2: "u2",
  "underline-position": "underlinePosition",
  "underline-thickness": "underlineThickness",
  "unicode-bidi": "unicodeBidi",
  "unicode-range": "unicodeRange",
  unicode: "unicode",
  "units-per-em": "unitsPerEm",
  usemap: "useMap",
  "v-alphabetic": "vAlphabetic",
  "v-hanging": "vHanging",
  "v-ideographic": "vIdeographic",
  "v-mathematical": "vMathematical",
  value: "value",
  values: "values",
  "vector-effect": "vectorEffect",
  version: "version",
  "vert-adv-y": "vertAdvY",
  "vert-origin-x": "vertOriginX",
  "vert-origin-y": "vertOriginY",
  viewBox: "viewBox",
  viewTarget: "viewTarget",
  visibility: "visibility",
  width: "width",
  widths: "widths",
  wmode: "wmode",
  "word-spacing": "wordSpacing",
  "writing-mode": "writingMode",
  "x-height": "xHeight",
  x: "x",
  x1: "x1",
  x2: "x2",
  xChannelSelector: "xChannelSelector",
  "xlink:actuate": "xlinkActuate",
  "xlink:arcrole": "xlinkArcrole",
  "xlink:href": "xlinkHref",
  "xlink:role": "xlinkRole",
  "xlink:show": "xlinkShow",
  "xlink:title": "xlinkTitle",
  "xlink:type": "xlinkType",
  "xml:base": "xmlBase",
  "xml:lang": "xmlLang",
  "xml:space": "xmlSpace",
  y: "y",
  y1: "y1",
  y2: "y2",
  yChannelSelector: "yChannelSelector",
  z: "z",
  zoomAndPan: "zoomAndPan"
};