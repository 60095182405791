"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _arrowLeft = require("@wel-ui/icons/lib/ui/arrow-left");

var _arrowLeft2 = _interopRequireDefault(_arrowLeft);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Panel = function (_Component) {
  _inherits(Panel, _Component);

  _createClass(Panel, null, [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(props, state) {
      if (props.open) {
        return {
          animating: true,
          closed: false
        };
      }

      if (!props.open && state.closed === false) {
        return {
          animating: true,
          closed: true
        };
      }

      return {};
    }
  }]);

  function Panel(props) {
    _classCallCheck(this, Panel);

    var _this = _possibleConstructorReturn(this, (Panel.__proto__ || Object.getPrototypeOf(Panel)).call(this, props));

    _this.state = {
      // this.state.closed is trinary:
      // - null: has not been opened, therefore has not been closed. Default.
      // - false: the menu is open.
      // - true: the menu had been opened previously, but is now closed.
      // This assists in animating correctly.
      closed: null
    };

    _this.handleAnimationEnd = _this.handleAnimationEnd.bind(_this);
    return _this;
  }

  _createClass(Panel, [{
    key: "handleAnimationEnd",
    value: function handleAnimationEnd() {
      this.setState({ animating: false });
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          children = _props.children,
          onBack = _props.onBack,
          open = _props.open,
          title = _props.title;
      var _state = this.state,
          animating = _state.animating,
          closed = _state.closed;

      var getClassName = (0, _utilityClassNames2.default)("SiteHeaderPanel");

      return _react2.default.createElement(
        "div",
        {
          className: getClassName({
            states: (0, _classnames2.default)({
              animating: animating,
              closed: closed,
              open: open
            })
          }),
          onAnimationEnd: this.handleAnimationEnd
        },
        _react2.default.createElement(
          "div",
          { className: getClassName({ descendantName: "bar" }) },
          _react2.default.createElement(
            "button",
            {
              className: getClassName({ descendantName: "backButton" }),
              onClick: onBack
            },
            _react2.default.createElement(_arrowLeft2.default, { width: 16 })
          ),
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "barTitle" }) },
            title
          )
        ),
        _react2.default.createElement(
          "div",
          { className: getClassName({ descendantName: "inner" }) },
          children
        )
      );
    }
  }]);

  return Panel;
}(_react.Component);

Panel.propTypes = {
  /**
   * Content to show inside the Panel.
   */
  children: _propTypes2.default.node,

  /**
   * Callback when back button is activated
   */
  onBack: _propTypes2.default.func,

  /**
   * Whether or not this panel is open
   */
  open: _propTypes2.default.bool,

  /**
   * Title to show in the bar.
   */
  title: _propTypes2.default.string
};
exports.default = Panel;