"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Tabs = require("./Tabs");

var _Tabs2 = _interopRequireDefault(_Tabs);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)(_Tabs2.default.displayName);

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Tabs2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "panel" }));

    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var props = {
      isRehydrating: true,
      analytics: extra.analytics,
      defaultTab: domNode.getAttribute("data-default-tab")
    };

    var layoutVariant = domNode.getAttribute("data-layout-variant");

    if (layoutVariant) {
      props.layoutVariant = layoutVariant;
    }

    return _react2.default.createElement(
      _Tabs2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;