"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

/**
 * Calls every function in the first argument, using all the arguments that
 * follow.
 *
 * eg.
 *   callEach([(arg1, arg2) => { console.log(arg1, arg2); }], "hello", "world");
 * would log "hello", "world" to the console.
 */
exports.default = function (functions) {
  for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = functions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var cb = _step.value;

      cb.apply(undefined, args);
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
};