"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _fullscreen = require("@wel-ui/icons/lib/ui/fullscreen-77");

var _fullscreen2 = _interopRequireDefault(_fullscreen);

var _componentModal = require("@wel-ui/component-modal");

var _componentModal2 = _interopRequireDefault(_componentModal);

var _componentImage = require("@wel-ui/component-image");

var _componentImage2 = _interopRequireDefault(_componentImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Figure = function (_Component) {
  _inherits(Figure, _Component);

  function Figure() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Figure);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Figure.__proto__ || Object.getPrototypeOf(Figure)).call.apply(_ref, [this].concat(args))), _this), _this.getClassName = (0, _utilityClassNames2.default)(Figure.displayName), _this.state = {
      modalOpen: false
    }, _this.handleCloseModal = function () {
      _this.setState({ modalOpen: false });
    }, _this.handleIconClick = function (event) {
      event.preventDefault();
      _this.setState({
        modalOpen: true
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Figure, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          children = _props.children,
          rest = _objectWithoutProperties(_props, ["children"]);

      var attribution = rest.attribution,
          caption = rest.caption,
          expandable = rest.expandable,
          expandableUrl = rest.expandableUrl;
      var modalOpen = this.state.modalOpen;

      var rehydrationProps = {
        "data-attribution": attribution,
        "data-expandable": expandable,
        "data-expandable-url": expandableUrl
      };
      var footer = void 0;

      if (attribution) {
        footer = _react2.default.createElement(
          "footer",
          {
            className: this.getClassName({ descendantName: "attribution" })
          },
          _react2.default.createElement(
            "small",
            null,
            attribution
          )
        );
      }

      return _react2.default.createElement(
        "figure",
        _extends({ className: this.getClassName() }, rehydrationProps),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "inner" }) },
          expandable && _react2.default.createElement(
            "button",
            {
              className: this.getClassName({ descendantName: "expandable" }),
              onClick: this.handleIconClick
            },
            _react2.default.createElement(_fullscreen2.default, null)
          ),
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName({ descendantName: "imageWrapper" })
            },
            children
          ),
          footer
        ),
        _react2.default.createElement(
          "figcaption",
          {
            className: this.getClassName({ descendantName: "caption" })
          },
          caption
        ),
        modalOpen && _react2.default.createElement(
          _componentModal2.default,
          {
            ariaLabel: "Full screen image",
            closeLabel: "Close",
            closeThisComponent: this.handleCloseModal
          },
          _react2.default.createElement(
            Figure,
            _extends({}, rest, { expandable: false }),
            expandableUrl ? _react2.default.createElement(_componentImage2.default, { alt: "Full screen image", src: expandableUrl }) : children
          )
        )
      );
    }
  }]);

  return Figure;
}(_react.Component);

Figure.displayName = "Figure";
Figure.propTypes = {
  /**
   * An attribution for the comments of the figure. For example, REUTERS/John Smith.
   */
  attribution: _propTypes2.default.string,

  /**
   * A caption to attach to the figure. Children should be restricted to text, <a>, <em> and <strong>.
   */
  caption: _propTypes2.default.node,

  /**
   * The contents of the figure.
   */
  children: _propTypes2.default.node,

  /**
   * Whether or not the image is expandable to a modal / larger view.
   */
  expandable: _propTypes2.default.bool,

  /**
   * (optional) The image URL to display. If not supplied, will fall back to original URL.
   */
  expandableUrl: _propTypes2.default.string
};
exports.default = Figure;