"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Pagination = require("./Pagination");

var _Pagination2 = _interopRequireDefault(_Pagination);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Pagination2.default;