"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Ticker = require("./Ticker");

var _Ticker2 = _interopRequireDefault(_Ticker);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Ticker2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("Ticker");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "itemsInner" }));
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var props = {
      isRehydrating: true,
      initialTime: domNode.getAttribute("data-initial-time") || new Date().toISOString()
    };

    return _react2.default.createElement(
      _Ticker2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;