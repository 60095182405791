"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fireEnd = exports.fireBuffer = exports.firePause = exports.firePlay = exports.getGtmLabel = undefined;

var _utilities = require("./utilities");

var getGtmLabel = exports.getGtmLabel = function getGtmLabel(videoObj) {
  return "" + videoObj.id + (videoObj.title ? " | " + videoObj.title : "");
};

var firePlay = exports.firePlay = function firePlay(videoObj) {
  var analytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

  (0, _utilities.safePublish)("analytics.videoPlay", videoObj);
  (0, _utilities.callIfDefined)(analytics, "Video", "Play", getGtmLabel(videoObj));
};

var firePause = exports.firePause = function firePause(videoObj) {
  var analytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

  (0, _utilities.safePublish)("analytics.videoPause", videoObj);
  (0, _utilities.callIfDefined)(analytics, "Video", "Pause", getGtmLabel(videoObj));
};

var fireBuffer = exports.fireBuffer = function fireBuffer(videoObj) {
  // GTM doesn't expect a buffer event, so we only send this to PubSub.
  (0, _utilities.safePublish)("analytics.videoBuffer", videoObj);
};

var fireEnd = exports.fireEnd = function fireEnd(videoObj) {
  var analytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

  (0, _utilities.safePublish)("analytics.videoEnd", videoObj);
  (0, _utilities.callIfDefined)(analytics, "Video", "Finish", getGtmLabel(videoObj));
};