// Eventually, we should include all our vendor code here.
// For now, it essentially functions as a polyfill file.

if (typeof document !== "undefined") {
  require("whatwg-fetch");
  require("element-closest");
  require("intersection-observer");

  const Promise = require("promise-polyfill");

  // To add to window
  if (!window.Promise) {
    window.Promise = Promise;
  }
}
