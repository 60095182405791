"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getService = exports.registerService = exports.registerRehydrator = exports.rehydratableToReactElement = undefined;

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var registeredRehydrators = [];

var registeredServices = {};

var registerRehydrator = function registerRehydrator(rehydrator) {
  var existingRehydrator = registeredRehydrators.findIndex(function (r) {
    return r.dataName === rehydrator.dataName;
  });

  if (existingRehydrator >= 0) {
    registeredRehydrators[existingRehydrator] = rehydrator;
  } else {
    registeredRehydrators.push(rehydrator);
  }
};

var registerService = function registerService(serviceName, service) {
  if (registeredServices[serviceName]) {
    throw new Error(serviceName + " service already registered");
  }

  registeredServices[serviceName] = service;
};

var getService = function getService(serviceName) {
  return registeredServices[serviceName];
};

var rehydratableToReactElement = function rehydratableToReactElement(node, extra) {
  var rehydratorName = node.getAttribute("data-rehydratable");
  var rehydrator = registeredRehydrators.find(function (r) {
    return r.dataName === rehydratorName;
  });

  if (rehydrator) {
    // In some circumstances, the standard data-rehydratable wrapping div may
    // not be appropriate - for example, when a parent-child flexbox interaction
    // exists.
    //
    // For those specific cases, and only when the parent is already a
    // rehydratable, the wrapping div is unnecessary - we don't need it as a
    // `ReactDOM.render()` target, and it does not provide the normal
    // convenience.
    //
    // If you wish to opt out of the normal `data-rehydratable` behaviour,
    // you can add the `data-rehydratable-in-place` attribute, along with
    // a `data-rehydratable` attribute, and disregard the normal `rehydratable`
    // higher order component.
    if (node.getAttribute("data-rehydratable-in-place")) {
      return rehydrator.elementToReact(node, extra);
    }

    return rehydrator.elementToReact(node.children[0], extra);
  }

  throw new Error("No rehydrator found for type " + rehydratorName);
};

var clearNode = function clearNode(node) {
  // Removes any React events/handlers, and empties the node.
  var reactDidUnmount = _reactDom2.default.unmountComponentAtNode(node);

  if (!reactDidUnmount) {
    // If `unmountComponentAtNode` didn't succeed, then empty the node manually.
    while (node.lastChild) {
      node.removeChild(node.lastChild);
    }
  }
};

exports.default = function (rootNode, extra) {
  var nodes = rootNode.querySelectorAll("[data-rehydratable]");
  var failedNodes = [];

  var _loop = function _loop(i) {
    var node = nodes[i];

    // If rehydration failed on a parent node, then we should skip rehydration
    // for all its child elements.
    var hasFailedParent = failedNodes.some(function (f) {
      return f.contains(node);
    });

    if (rootNode.contains(node) && node.hasChildNodes && !hasFailedParent) {
      try {
        var replacement = rehydratableToReactElement(node, extra);

        clearNode(node);
        _reactDom2.default.render(replacement, node);
      } catch (e) {
        failedNodes.push(node);
        // eslint-disable-next-line no-console
        console.error("Rehydration failure", e);
      }
    }
  };

  for (var i = 0; i < nodes.length; i++) {
    _loop(i);
  }
};

exports.rehydratableToReactElement = rehydratableToReactElement;
exports.registerRehydrator = registerRehydrator;
exports.registerService = registerService;
exports.getService = getService;