"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Header = require("../Header");

var _Header2 = _interopRequireDefault(_Header);

var _Item = require("./Item");

var _Item2 = _interopRequireDefault(_Item);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Item2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)(_Item2.default.displayName);
    var className = getClassName({ descendantName: "content" });
    var childrenNode = domNode.querySelector("." + className);
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    /*
     * Reach insisde Header subcomponent in order to rehydrate the children of
     * it's internal Heading component.
     */
    var getHeaderClassName = (0, _utilityClassNames2.default)(_Header2.default.displayName);
    var headingInnerClassName = getHeaderClassName({
      descendantName: "headingInner"
    });
    var headingInnerNode = domNode.querySelector("." + headingInnerClassName);
    var reactifiedHeadingInner = (0, _componentRehydratable.rehydrateChildren)(headingInnerNode, extra);

    var props = {
      isRehydrating: true,
      isExpanded: domNode.getAttribute("data-is-expanded") === "true" || false,
      headingRank: domNode.getAttribute("data-heading-rank") || null,
      title: reactifiedHeadingInner
    };

    return _react2.default.createElement(
      _Item2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;