"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _NavItem = require("./components/NavItem");

var _NavItem2 = _interopRequireDefault(_NavItem);

var _PageItem = require("./components/PageItem");

var _PageItem2 = _interopRequireDefault(_PageItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Pagination = function Pagination(_ref) {
  var children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(Pagination.displayName);

  return _react2.default.createElement(
    "nav",
    {
      className: getClassName(),
      role: "navigation",
      "aria-label": "Pagination Navigation"
    },
    _react2.default.createElement(
      "ul",
      { className: getClassName({ descendantName: "items" }) },
      children
    )
  );
};

Pagination.displayName = "Pagination";

/* eslint-disable max-len */
Pagination.propTypes = {
  /**
   * Pagination NavItems and PageItems
   */
  children: _propTypes2.default.node
};
/* eslint-enable max-len */

Pagination.NavItem = _NavItem2.default;
Pagination.PageItem = _PageItem2.default;

exports.default = Pagination;