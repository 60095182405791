"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GlobalFooterItem = function GlobalFooterItem(_ref) {
  var href = _ref.href,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)(GlobalFooterItem.displayName);

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(
      "a",
      { className: getClassName({ descendantName: "inner" }), href: href },
      title
    )
  );
};

GlobalFooterItem.displayName = "GlobalFooterItem";

/* eslint-disable max-len */
GlobalFooterItem.propTypes = {
  /**
   * URL.
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Title of the item.
   */
  title: _propTypes2.default.string.isRequired
};
/* eslint-enable max-len */

exports.default = GlobalFooterItem;