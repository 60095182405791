"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _class = function _class() {
  _classCallCheck(this, _class);

  var self = this;

  this.emcm = {
    authPageLocation: null,
    location: null,
    loginUrl: null,
    logoutUrl: null,
    cors: false
  };

  this.estore = {
    location: null,

    get cartUrl() {
      if (this.cartUrlInternal) {
        return this.cartUrlInternal;
      }

      return this.location + "/cart";
    },

    set cartUrl(value) {
      this.cartUrlInternal = value;
    }
  };

  this.identityService = {
    clientId: null,
    location: null,

    get loginUrl() {
      if (this.loginUrlInternal) {
        return this.loginUrlInternal;
      }

      var callbackUrl = encodeURIComponent(self.emcm.authPageLocation + "?action=login");

      return this.location + "/token?iss=" + this.clientId + "&services=" + this.clientId + "&provider=onepass&callback_url=" + callbackUrl;
    },

    set loginUrl(value) {
      this.loginUrlInternal = value;
    }
  };

  this.onepass = {
    location: null,
    productId: null,

    get transferUrl() {
      if (this.transferUrlInternal) {
        return this.transferUrlInternal;
      }

      var identityServiceTransferUrl = self.identityService.location + "/auth/onepass/" + self.identityService.clientId + "/transfertoken";

      return this.location + "?productid=" + this.productId + "&returnto=" + encodeURIComponent(identityServiceTransferUrl);
    },

    set transferUrl(value) {
      this.transferUrlInternal = value;
    }
  };

  this.site = {
    accountOverviewUrl: null
  };

  this.transferIframeLocations = [];
  this.signoutIframeLocations = [];

  this.redirectUrlSessionKey = "emcmLoginRedirectURL";
};

exports.default = _class;