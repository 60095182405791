"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

var _scrollama = require("scrollama");

var _scrollama2 = _interopRequireDefault(_scrollama);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require("lodash.debounce");

var _lodash2 = _interopRequireDefault(_lodash);

var _Item = require("./components/Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PageNavInner = function (_Component) {
  _inherits(PageNavInner, _Component);

  _createClass(PageNavInner, null, [{
    key: "getItems",
    value: function getItems(children) {
      var items = [];

      _react2.default.Children.forEach(children, function (child) {
        if (child.type === _Item2.default) {
          items.push(child.props);
        }
      });

      return items;
    }
  }, {
    key: "getCurrentItem",
    value: function getCurrentItem(items) {
      return Math.max(items.findIndex(function (item) {
        return item.active === true;
      }), 0);
    }
  }]);

  function PageNavInner(props) {
    _classCallCheck(this, PageNavInner);

    var _this = _possibleConstructorReturn(this, (PageNavInner.__proto__ || Object.getPrototypeOf(PageNavInner)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)("PageNavInner");

    var items = PageNavInner.getItems(props.children);

    _this.state = {
      currentItemIndex: PageNavInner.getCurrentItem(items),
      items: items,
      expanded: false
    };

    _this.scrollSpyOffset = 0.1;
    _this.resizeDebounceTimeout = 200;

    _this.handleBlur = _this.handleBlur.bind(_this);
    _this.handleClick = _this.handleClick.bind(_this);
    _this.handleItemClick = _this.handleItemClick.bind(_this);
    _this.handleStepEnter = _this.handleStepEnter.bind(_this);
    _this.handleStepExit = _this.handleStepExit.bind(_this);
    _this.resizeScrollSpy = _this.resizeScrollSpy.bind(_this);
    _this.resizeScrollSpyDebounced = (0, _lodash2.default)(_this.resizeScrollSpy, _this.resizeDebounceTimeout).bind(_this);
    return _this;
  }

  _createClass(PageNavInner, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      // Run on mount rather than constructor, as page need to have loaded.
      if (this.props.spy && typeof window !== "undefined") {
        this.setupScrollSpy();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.scroller && typeof window !== "undefined") {
        this.scroller.destroy();
        window.removeEventListener("resize", this.resizeScrollSpyDebounced);
      }
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      // This prevents blurring when the next target is within the PageNav.
      if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
        return;
      }

      this.setState({
        expanded: false
      });
    }
  }, {
    key: "handleClick",
    value: function handleClick() {
      this.setState({ expanded: !this.state.expanded });
    }
  }, {
    key: "handleItemClick",
    value: function handleItemClick() {
      this.setState({ expanded: false });
    }
  }, {
    key: "handleStepEnter",
    value: function handleStepEnter(_ref) {
      var element = _ref.element;

      this.setState({
        // Use Math.max to ensure we don't crash if element doesn't exist
        currentItemIndex: Math.max(this.state.items.findIndex(function (item) {
          return item.href === "#" + element.id;
        }), 0)
      });
    }
  }, {
    key: "handleStepExit",
    value: function handleStepExit(_ref2) {
      var direction = _ref2.direction;

      if (direction === "up") {
        this.setState({
          currentItemIndex: Math.max(0, this.state.currentItemIndex - 1)
        });
      }
    }
  }, {
    key: "setupScrollSpy",
    value: function setupScrollSpy() {
      this.scroller = (0, _scrollama2.default)();

      var itemDividerClassName = (0, _utilityClassNames2.default)("PageNavAnchor")();

      // setup the instance, pass callback functions
      this.scroller.setup({
        step: "." + itemDividerClassName,
        offset: this.scrollSpyOffset
      }).onStepEnter(this.handleStepEnter).onStepExit(this.handleStepExit);

      window.addEventListener("resize", this.resizeScrollSpyDebounced);
    }
  }, {
    key: "resizeScrollSpy",
    value: function resizeScrollSpy() {
      this.scroller.resize();
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          items = _state.items,
          currentItemIndex = _state.currentItemIndex,
          expanded = _state.expanded;

      var currentItem = items[currentItemIndex];

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({ states: (0, _classnames2.default)({ expanded: expanded }) }),
          ref: function ref(_ref3) {
            return _this2.ref = _ref3;
          },
          "data-spy": this.props.spy ? JSON.stringify(this.props.spy) : null
        },
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "container" }) },
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "header" }) },
            _react2.default.createElement(
              "button",
              {
                className: this.getClassName({ descendantName: "currentItem" }),
                onClick: this.handleClick,
                onBlur: this.handleBlur
              },
              _react2.default.createElement(
                "span",
                {
                  className: this.getClassName({
                    descendantName: "currentItemTitle"
                  })
                },
                currentItem.title
              ),
              _react2.default.createElement(
                "span",
                {
                  className: this.getClassName({
                    descendantName: "currentItemIcon"
                  })
                },
                expanded ? _react2.default.createElement(_chevronUp2.default, { label: "Collapse", width: 16 }) : _react2.default.createElement(_chevronDown2.default, { label: "Expand", width: 16 })
              )
            ),
            this.props.actions ? _react2.default.createElement(
              "div",
              { className: this.getClassName({ descendantName: "actions" }) },
              this.props.actions
            ) : null,
            this.props.share ? _react2.default.createElement(
              "div",
              { className: this.getClassName({ descendantName: "share" }) },
              _react2.default.createElement(
                "div",
                {
                  className: this.getClassName({
                    descendantName: "shareLabel"
                  })
                },
                _react2.default.createElement(_componentEyebrow2.default, { text: "Share" })
              ),
              _react2.default.createElement(
                "div",
                {
                  className: this.getClassName({
                    descendantName: "shareInner"
                  })
                },
                this.props.share
              )
            ) : null
          ),
          _react2.default.createElement(
            "ol",
            {
              className: this.getClassName({ descendantName: "items" }),
              onClick: this.handleItemClick // Captures bubbled events from links
              , onBlur: this.handleBlur,
              "data-rehydratable-children": true
            },
            items.map(function (item, i) {
              return _react2.default.createElement(_Item2.default, _extends({ key: i }, item, { active: currentItemIndex === i }));
            })
          )
        )
      );
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps) {
      var items = PageNavInner.getItems(nextProps.children);

      return {
        currentItemIndex: PageNavInner.getCurrentItem(items),
        items: items
      };
    }
  }]);

  return PageNavInner;
}(_react.Component);

PageNavInner.displayName = "PageNavInner";
PageNavInner.propTypes = {
  actions: _propTypes2.default.node,
  children: _propTypes2.default.node,
  share: _propTypes2.default.node,
  spy: _propTypes2.default.bool
};
exports.default = PageNavInner;