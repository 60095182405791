"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _TextCardBase = require("../../bases/TextCardBase");

var _TextCardBase2 = _interopRequireDefault(_TextCardBase);

var _componentImage = require("@wel-ui/component-image");

var _componentImage2 = _interopRequireDefault(_componentImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PairedHorizontalCard = function PairedHorizontalCard(_ref) {
  var borderless = _ref.borderless,
      eyebrow = _ref.eyebrow,
      footer = _ref.footer,
      imgAnchor = _ref.imgAnchor,
      imgSrc = _ref.imgSrc,
      imgSrcSet = _ref.imgSrcSet,
      heading = _ref.heading,
      headingRank = _ref.headingRank,
      href = _ref.href;

  var getClassName = (0, _utilityClassNames2.default)(PairedHorizontalCard.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({ modifiers: borderless ? "borderless" : "" })
    },
    _react2.default.createElement(_TextCardBase2.default, {
      anchor: "top",
      borderless: true,
      eyebrow: eyebrow,
      footer: footer,
      heading: heading,
      headingRank: headingRank,
      href: href,
      size: "small",
      renderContent: function renderContent(content) {
        return _react2.default.createElement(
          "div",
          { className: getClassName({ descendantName: "content" }) },
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "image" }) },
            _react2.default.createElement(
              "div",
              { className: getClassName({ descendantName: "imageInner" }) },
              _react2.default.createElement(_componentImage2.default, {
                alt: "",
                anchor: imgAnchor,
                fit: "cover",
                sizes: _componentImage2.default.sizes.oneThird,
                src: imgSrc,
                srcSet: imgSrcSet
              })
            )
          ),
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "contentInner" }) },
            content
          )
        );
      }
    })
  );
};

PairedHorizontalCard.displayName = "PairedHorizontalCard";

PairedHorizontalCard.headingRanks = _TextCardBase2.default.headingRanks;

/* eslint-disable max-len */
PairedHorizontalCard.propTypes = {
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Eyebrow text
   */
  eyebrow: _propTypes2.default.string.isRequired,
  /**
   * Footer. Should be a valid card footer, like ArticleCardFooter.
   */
  footer: _propTypes2.default.node.isRequired,
  /**
   * Heading text
   */
  heading: _propTypes2.default.string.isRequired,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. Defaults to 3.
   */
  headingRank: _propTypes2.default.oneOf(PairedHorizontalCard.headingRanks),
  /**
   * Card URL
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Anchor point for the image.
   */
  imgAnchor: _propTypes2.default.oneOf(_componentImage.anchorPoints),
  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  imgSrc: _propTypes2.default.string.isRequired,
  /**
   * A comma-separated list indicating a set of possible image sources for the image. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  imgSrcSet: _propTypes2.default.string
};
/* eslint-enable max-len */

PairedHorizontalCard.defaultProps = {
  headingRank: "3",
  imgGradient: false,
  imgAnchor: "C"
};

exports.default = PairedHorizontalCard;