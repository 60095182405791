"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SelectedItem = require("./SelectedItem");

var _SelectedItem2 = _interopRequireDefault(_SelectedItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SelectedItem2.default;