"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _setOpenMenuContext = require("../../setOpenMenuContext");

var _currentMenuContext = require("../../currentMenuContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NavList = function (_Component) {
  _inherits(NavList, _Component);

  function NavList() {
    _classCallCheck(this, NavList);

    return _possibleConstructorReturn(this, (NavList.__proto__ || Object.getPrototypeOf(NavList)).apply(this, arguments));
  }

  _createClass(NavList, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var title = this.props.title;


      return _react2.default.createElement(
        _setOpenMenuContext.Consumer,
        null,
        function (setOpenMenu) {
          return _react2.default.createElement(
            _currentMenuContext.Consumer,
            null,
            function (currentMenu) {
              var getClassName = (0, _utilityClassNames2.default)("SiteFooterNavList");
              var isExpanded = currentMenu !== null && currentMenu === title;

              var handleItemClick = function handleItemClick(e) {
                e.preventDefault();

                if (title === currentMenu) {
                  setOpenMenu(null);
                } else {
                  setOpenMenu(title);
                }
              };

              return _react2.default.createElement(
                "li",
                {
                  className: getClassName({
                    states: (0, _classnames2.default)({
                      expanded: isExpanded
                    })
                  }),
                  "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(NavList),
                  "data-rehydratable-in-place": true,
                  "data-title": title
                },
                _react2.default.createElement(
                  "button",
                  {
                    className: getClassName({ descendantName: "title" }),
                    onClick: handleItemClick,
                    role: "button",
                    "aria-label": "Thomas Reuters",
                    "aria-haspopup": true,
                    "aria-expanded": isExpanded
                  },
                  _react2.default.createElement(
                    "span",
                    {
                      className: getClassName({
                        descendantName: "text"
                      }) + "\n                u-typographySmallCaps"
                    },
                    title
                  ),
                  _react2.default.createElement(
                    "span",
                    { className: getClassName({ descendantName: "caret" }) },
                    isExpanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
                  )
                ),
                _react2.default.createElement(
                  "ul",
                  { className: getClassName({ descendantName: "inner" }) },
                  _this2.props.children
                )
              );
            }
          );
        }
      );
    }
  }]);

  return NavList;
}(_react.Component);

NavList.displayName = "SiteFooter.NavList";
NavList.propTypes = {
  /**
   * The children to show in this `Menu`'s menu
   */
  children: _propTypes2.default.node,

  /**
   * The title to show on this Menu.
   *
   * This doubles as a unique identifier; you should never have two `Menu`s with the same title in one `SiteFooter`.
   */
  title: _propTypes2.default.string.isRequired,

  /**
   * Whether or not the component is rehydrating. Do not use this.
   */
  isRehydrating: _propTypes2.default.bool
};
exports.default = NavList;