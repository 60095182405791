"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Panel = require("./Panel");

var _Panel2 = _interopRequireDefault(_Panel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Panel2.default;