"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _SearchInput = require("./SearchInput");

var _SearchInput2 = _interopRequireDefault(_SearchInput);

var _SearchInput3 = require("./SearchInput.rehydrator");

var _SearchInput4 = _interopRequireDefault(_SearchInput3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_SearchInput2.default);
exports.rehydrator = _SearchInput4.default;