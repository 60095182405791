"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NonRehydratableHorizontalOverflow = exports.rehydrator = undefined;

var _HorizontalOverflow = require("./HorizontalOverflow");

var _HorizontalOverflow2 = _interopRequireDefault(_HorizontalOverflow);

var _HorizontalOverflow3 = require("./HorizontalOverflow.rehydrator");

var _HorizontalOverflow4 = _interopRequireDefault(_HorizontalOverflow3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableHorizontalOverflow = (0, _componentRehydratable2.default)(_HorizontalOverflow2.default);

exports.default = RehydratableHorizontalOverflow;
exports.rehydrator = _HorizontalOverflow4.default;
exports.NonRehydratableHorizontalOverflow = _HorizontalOverflow2.default;