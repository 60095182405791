"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BlockQuote = require("./BlockQuote");

var _BlockQuote2 = _interopRequireDefault(_BlockQuote);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BlockQuote2.default;