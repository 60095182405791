"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Menu = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _smallRight = require("@wel-ui/icons/lib/ui/small-right");

var _smallRight2 = _interopRequireDefault(_smallRight);

var _Panel = require("../Panel");

var _Panel2 = _interopRequireDefault(_Panel);

var _setOpenMenuContext = require("../../setOpenMenuContext");

var _currentOpenMenuContext = require("../../currentOpenMenuContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Menu = exports.Menu = function (_Component) {
  _inherits(Menu, _Component);

  function Menu(props) {
    _classCallCheck(this, Menu);

    var _this = _possibleConstructorReturn(this, (Menu.__proto__ || Object.getPrototypeOf(Menu)).call(this, props));

    _this.handleBlur = _this.handleBlur.bind(_this);
    _this.handleItemClick = _this.handleItemClick.bind(_this);
    _this.menuRef = null;
    _this.menuChildrenRef = null;
    return _this;
  }

  _createClass(Menu, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate() {
      var menuRef = this.menuRef,
          menuChildrenRef = this.menuChildrenRef,
          expanded = this.props.expanded;

      /*
       * Position open Menu children relative to their parent.
       */

      if (expanded) {
        var baseFontSize = 16;
        var menuPaddingLeft = 16;
        var menuOffsetLeft = menuRef.offsetLeft - menuPaddingLeft;

        if (menuChildrenRef.offsetLeft > menuOffsetLeft) {
          menuChildrenRef.style.marginLeft = menuOffsetLeft / baseFontSize + "rem";
        }
      }
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;
      var siteHeaderViewportWidth = 1200;

      /*
       * Only apply this behaviour above the SiteHeader viewport width, to avoid
       * unwanted blur events being fired cross-browser. At narrower viewports,
       * Menu essentially takes up the whole screen (minus fixed area with close
       * button) so utility of closing on click-outside is negligible.
       */
      if (window.innerWidth < siteHeaderViewportWidth) {
        return;
      }

      // React's `blur` event bubbles - it's actually closer to the `onfocusout`
      // event.
      //
      // We want to close the menu when it loses focus. When an item blurs, we
      // check the `relatedTarget` on the event, which corresponds to the newly
      // focused node. If it's contained inside the menu, then the menu still
      // has focus, and should stay open. Otherwise, we close it.
      if (this.menuRef && relatedTarget && this.menuRef.contains(relatedTarget)) {
        return;
      }

      this.props.onRequestClose();
    }
  }, {
    key: "handleItemClick",
    value: function handleItemClick(e) {
      e.preventDefault();

      if (this.props.expanded) {
        this.props.onRequestClose();
      } else {
        this.props.onRequestOpen();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var getClassName = (0, _utilityClassNames2.default)("SiteHeaderMenu");
      var _props = this.props,
          children = _props.children,
          expanded = _props.expanded,
          fallbackHref = _props.fallbackHref,
          href = _props.href,
          title = _props.title;


      var anchorProps = {};

      // Optionally apply props based on href and fallbackHref
      if (href) {
        anchorProps.href = href;
      } else {
        anchorProps.onClick = this.handleItemClick;
        anchorProps.role = "button";

        anchorProps["aria-haspopup"] = true;
        anchorProps["aria-expanded"] = expanded;

        if (fallbackHref) {
          anchorProps.href = fallbackHref;
        } else {
          anchorProps.href = "#";
        }
      }

      return _react2.default.createElement(
        "li",
        {
          className: getClassName({
            modifiers: (0, _classnames2.default)({
              href: href
            }),
            states: (0, _classnames2.default)({
              expanded: expanded
            })
          }),
          onBlur: this.handleBlur,
          ref: function ref(_ref2) {
            return _this2.menuRef = _ref2;
          },
          "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(Menu),
          "data-rehydratable-in-place": true,
          "data-fallback-href": fallbackHref,
          "data-href": href,
          "data-title": title
        },
        _react2.default.createElement(
          "a",
          _extends({
            className: getClassName({ descendantName: "link" })
          }, anchorProps),
          _react2.default.createElement(
            "span",
            { className: getClassName({ descendantName: "linkText" }) },
            title
          ),
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "linkIcon" }) },
            expanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
          ),
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "narrowLinkIcon" }) },
            _react2.default.createElement(_smallRight2.default, { width: 24 })
          )
        ),
        _react2.default.createElement(
          "div",
          {
            tabIndex: "-1",
            className: getClassName({ descendantName: "content" }),
            role: "menu"
          },
          _react2.default.createElement(
            _Panel2.default,
            { title: title, open: expanded, onBack: this.handleItemClick },
            _react2.default.createElement(
              "div",
              {
                className: getClassName({ descendantName: "children" }),
                ref: function ref(_ref) {
                  return _this2.menuChildrenRef = _ref;
                }
              },
              children
            )
          )
        )
      );
    }
  }]);

  return Menu;
}(_react.Component);

// The prop types are defined on Menu; no need to redefine here.
// eslint-disable-next-line react/prop-types


Menu.displayName = "SiteHeader.Menu";
Menu.propTypes = {
  /**
   * The children to show in this `Menu`'s menu
   */
  children: _propTypes2.default.node,

  /**
   * Whether or not this menu should be open.
   */
  expanded: _propTypes2.default.bool,

  /**
   * A destination that this `Menu` links to. If specified, this `Menu` will always be a link, and its contents will not show.
   *
   * Overrides fallbackHref.
   */
  href: _propTypes2.default.string,

  /**
   * A fallback link for this item, for circumstances where JavaScript is unavailable.
   *
   * Overridden by href if specified.
   */
  fallbackHref: _propTypes2.default.string,

  /**
   * Callback when this menu requires closing
   */
  onRequestClose: _propTypes2.default.func.isRequired,

  /**
   * Callback when this menu requests opening
   */
  onRequestOpen: _propTypes2.default.func.isRequired,

  /**
   * The title to show on this Menu.
   *
   * This doubles as a unique identifier; you should never have two `Menu`s with the same title in one `SiteHeader`.
   */
  title: _propTypes2.default.string.isRequired
};
var MenuWithContext = function MenuWithContext(_ref3) {
  var title = _ref3.title,
      props = _objectWithoutProperties(_ref3, ["title"]);

  return _react2.default.createElement(
    _setOpenMenuContext.Consumer,
    null,
    function (setOpenMenu) {
      return _react2.default.createElement(
        _currentOpenMenuContext.Consumer,
        null,
        function (currentOpenMenu) {
          return _react2.default.createElement(Menu, _extends({}, props, {
            expanded: currentOpenMenu === title,
            onRequestOpen: function onRequestOpen() {
              return setOpenMenu(title);
            },
            onRequestClose: function onRequestClose() {
              return setOpenMenu(null);
            },
            title: title
          }));
        }
      );
    }
  );
};

exports.default = MenuWithContext;