"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (url) {
  var i = document.createElement("iframe");

  i.style.display = "none";
  i.src = url;

  return new Promise(function (resolve, reject) {
    i.onload = function () {
      resolve();
    };
    i.onerror = function () {
      reject();
    };
    document.body.appendChild(i);
  }).then(function () {
    i.parentNode.removeChild(i);
  });
};