"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chapterRehydrator = exports.rehydrator = undefined;

var _ChapterNav = require("./ChapterNav");

var _ChapterNav2 = _interopRequireDefault(_ChapterNav);

var _ChapterNavInner = require("./ChapterNavInner.rehydrator");

var _ChapterNavInner2 = _interopRequireDefault(_ChapterNavInner);

var _Chapter = require("./components/Chapter/Chapter.rehydrator");

var _Chapter2 = _interopRequireDefault(_Chapter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ChapterNav2.default;
exports.rehydrator = _ChapterNavInner2.default;
exports.chapterRehydrator = _Chapter2.default;