"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Section = function Section(_ref) {
  var children = _ref.children,
      columns = _ref.columns,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("SiteHeaderSection");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: columns ? columns + "col" : null
      })
    },
    title ? _react2.default.createElement(
      "h3",
      {
        className: getClassName({
          descendantName: "title"
        })
      },
      title
    ) : null,
    _react2.default.createElement(
      "div",
      {
        className: getClassName({
          descendantName: "children"
        })
      },
      children
    )
  );
};

Section.propTypes = {
  /**
   * The contents of the section. These should be `SiteHeader.Column`s.
   */
  children: _propTypes2.default.node,

  /**
   * The number of columns this Section should contain
   */
  columns: _propTypes2.default.number,

  /**
   * The title of the section.
   */
  title: _propTypes2.default.string
};

Section.displayName = "SiteHeader.Section";

exports.default = Section;