"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentHeader = require("@wel-ui/component-header");

var _componentHeader2 = _interopRequireDefault(_componentHeader);

var _componentHeading = require("@wel-ui/component-heading");

var _componentHeading2 = _interopRequireDefault(_componentHeading);

var _componentVerticalSpacing = require("@wel-ui/component-vertical-spacing");

var _componentVerticalSpacing2 = _interopRequireDefault(_componentVerticalSpacing);

var _componentGrid = require("@wel-ui/component-grid");

var _componentGrid2 = _interopRequireDefault(_componentGrid);

var _componentPagination = require("@wel-ui/component-pagination");

var _componentPagination2 = _interopRequireDefault(_componentPagination);

var _componentHorizontalOverflow = require("@wel-ui/component-horizontal-overflow");

var _componentHorizontalOverflow2 = _interopRequireDefault(_componentHorizontalOverflow);

var _Table = require("./components/Table");

var _Table2 = _interopRequireDefault(_Table);

var _TagFilter = require("./components/TagFilter");

var _TagFilter2 = _interopRequireDefault(_TagFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// to avoid pulling in lodash get this get fn lets us use similar behavior.
var get = function get(fn) {
  try {
    return fn();
  } catch (e) {
    return undefined; // eslint-disable-line no-undefined
  }
};

var TableWithFilters = function (_Component) {
  _inherits(TableWithFilters, _Component);

  function TableWithFilters(props) {
    _classCallCheck(this, TableWithFilters);

    var _this = _possibleConstructorReturn(this, (TableWithFilters.__proto__ || Object.getPrototypeOf(TableWithFilters)).call(this, props));

    _this.paginationResultSummary = null;
    _this.scrollTimeout = null;

    _this.getPaginationMarkup = function (placement) {
      var _this$state$paginatio = _this.state.pagination,
          currentPage = _this$state$paginatio.currentPage,
          pages = _this$state$paginatio.pages;

      var getClassName = _this.getClassName;

      var PaginationWrapper = _this.getPaginationWrapper(placement);
      var topPagination = placement === "top";
      var borderlessAtStandard = topPagination;

      if (pages > 1) {
        return _react2.default.createElement(
          PaginationWrapper,
          null,
          _react2.default.createElement(
            "div",
            {
              className: getClassName({
                descendantName: "paginationInnerWrapper"
              })
            },
            _react2.default.createElement(
              _componentPagination2.default,
              null,
              _react2.default.createElement(_componentPagination2.default.NavItem, _extends({
                borderlessAtStandard: borderlessAtStandard,
                direction: "left",
                disabled: currentPage === 1
              }, currentPage !== 1 && {
                onClick: function onClick() {
                  _this.goToPage(currentPage - 1);
                  _this.handlePageChange();
                }
              }, {
                "data-testid": "navItemLeft"
              })),
              topPagination ? _react2.default.createElement(
                "li",
                {
                  className: getClassName({
                    descendantName: "paginationPageSummary"
                  })
                },
                currentPage + " / " + pages
              ) : _this.getPagination().map(function (page) {
                return _react2.default.createElement(
                  _componentPagination2.default.PageItem,
                  _extends({
                    active: page === currentPage
                  }, typeof page === "number" && {
                    onClick: function onClick() {
                      _this.goToPage(page);
                      _this.handlePageChange();
                    }
                  }, {
                    key: page.toString(),
                    "data-testid": "pageItem"
                  }),
                  typeof page === "number" ? page : page.substring(1)
                );
              }),
              _react2.default.createElement(_componentPagination2.default.NavItem, _extends({
                borderlessAtStandard: borderlessAtStandard,
                direction: "right",
                disabled: currentPage === pages
              }, currentPage !== pages && {
                onClick: function onClick() {
                  _this.goToPage(currentPage + 1);
                  _this.handlePageChange();
                }
              }, {
                "data-testid": "navItemRight"
              }))
            )
          )
        );
      }

      return null;
    };

    _this.getPaginationResultSummaryText = function () {
      var _this$props = _this.props,
          paginationResultSummaryText = _this$props.paginationResultSummaryText,
          rowsPerPage = _this$props.rowsPerPage;
      var pagination = _this.state.pagination;


      return paginationResultSummaryText.replace("{1}", (pagination.rows > 0 ? (pagination.currentPage - 1) * rowsPerPage + 1 : 0) + "-" + (pagination.currentPage * rowsPerPage < pagination.rows ? pagination.currentPage * rowsPerPage : pagination.rows)).replace("{2}", pagination.rows);
    };

    _this.getPaginationWrapper = function (placement) {
      return function (_ref) {
        var children = _ref.children;

        var topPagination = placement === "top";

        if (!topPagination) {
          return _react2.default.createElement(
            _componentVerticalSpacing2.default,
            { size: "xs" },
            children
          );
        }

        return children;
      };
    };

    _this.handlePageChange = function () {
      var delay = 100;

      if (_this.paginationResultSummary) {
        clearTimeout(_this.scrollTimeout);
        _this.scrollTimeout = setTimeout(function () {
          var rect = _this.paginationResultSummary.getBoundingClientRect();

          window.scrollTo({
            top: rect.y + window.scrollY,
            behavior: "smooth"
          });
        }, delay);
      }
    };

    _this.getClassName = (0, _utilityClassNames2.default)(TableWithFilters.displayName);

    // Create a deep copy
    var data = JSON.parse(JSON.stringify(_this.props.data));

    // Add keys to table rows and cells
    _this.addKeys(data);

    // Change array order of the filters to match they display order
    // This needs to be subsequent to adding keys addKeys()
    if (data.filter && data.filter.cells) {
      data.filter.cells.sort(function (a, b) {
        if (typeof a.displayOrder === "number" && typeof b.displayOrder === "number") {
          return a.displayOrder - b.displayOrder;
        }

        return 0;
      });
    }

    // Initialize pagination
    var rows = get(function () {
      return data.table.bodies[0].rows.length;
    }) || 0;

    _this.state = {
      filter: data.filter || {},
      table: data.table || {},
      pagination: {
        currentPage: 1,
        pages: Math.ceil(rows / _this.props.rowsPerPage),
        rows: rows
      }
    };
    return _this;
  }

  _createClass(TableWithFilters, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      // Initialize pagination. Needed for refresh in Storybook.
      var rows = get(function () {
        return _this2.props.data.table.bodies[0].rows.length;
      }) || 0;

      if (this.props.rowsPerPage !== prevProps.rowsPerPage) {
        this.setState({
          pagination: {
            currentPage: 1,
            pages: Math.ceil(rows / this.props.rowsPerPage),
            rows: rows
          }
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var _state = this.state,
          filter = _state.filter,
          table = _state.table,
          pagination = _state.pagination;
      var _props = this.props,
          filtersHideSelectedOptions = _props.filtersHideSelectedOptions,
          filtersNoOptionsMessage = _props.filtersNoOptionsMessage,
          filtersPlaceholder = _props.filtersPlaceholder,
          infoText = _props.infoText,
          isMulti = _props.isMulti,
          paginationResultSummaryText = _props.paginationResultSummaryText,
          pagesNavigableOnSide = _props.pagesNavigableOnSide,
          noResultsMessage = _props.noResultsMessage,
          rowsPerPage = _props.rowsPerPage,
          subtitleHeadingRank = _props.subtitleHeadingRank,
          title = _props.title,
          titleHeadingRank = _props.titleHeadingRank;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName(),
          "data-table-data": JSON.stringify(this.props.data),
          "data-filters-hide-selected-options": JSON.stringify(filtersHideSelectedOptions),
          "data-filters-no-options-message": JSON.stringify(filtersNoOptionsMessage),
          "data-filters-placeholder": JSON.stringify(filtersPlaceholder),
          "data-info-text": JSON.stringify(infoText),
          "data-no-results-message": JSON.stringify(noResultsMessage),
          "data-pages-navigable-on-side": JSON.stringify(pagesNavigableOnSide),
          "data-pagination-result-summary-text": JSON.stringify(paginationResultSummaryText),
          "data-rows-per-page": JSON.stringify(rowsPerPage),
          "data-title": JSON.stringify(title)
        },
        _react2.default.createElement(
          "div",
          null,
          _react2.default.createElement(
            _componentVerticalSpacing2.default,
            { size: "l" },
            _react2.default.createElement(
              "div",
              {
                className: this.getClassName({
                  descendantName: "filtersPanel"
                })
              },
              _react2.default.createElement(
                _componentVerticalSpacing2.default,
                { size: "xs" },
                _react2.default.createElement(
                  _componentHeading2.default,
                  { type: "l", rank: titleHeadingRank },
                  title
                )
              ),
              _react2.default.createElement(
                _componentVerticalSpacing2.default,
                null,
                _react2.default.createElement(_componentHeader2.default, {
                  heading: _react2.default.createElement(
                    _componentHeading2.default,
                    { rank: subtitleHeadingRank, type: "m" },
                    infoText
                  )
                })
              ),
              _react2.default.createElement(
                _componentGrid2.default,
                { variant: "even-2" },
                get(function () {
                  return filter.cells;
                }) && filter.cells.map(function (cell, index) {
                  var key = typeof cell.key === "undefined" ? index : cell.key;
                  var label = get(function () {
                    return table.head.rows[0].cells[key].content;
                  }) || "";

                  return cell.display && _react2.default.createElement(
                    _componentGrid2.default.Item,
                    { key: key.toString() },
                    _react2.default.createElement(TableWithFilters.TagFilter, {
                      index: index,
                      handleChange: function handleChange(optionsSelected) {
                        return _this3.handleChange(optionsSelected, key);
                      },
                      hideSelectedOptions: filtersHideSelectedOptions,
                      isMulti: isMulti,
                      label: label,
                      options: _this3.getOptions(key),
                      placeholder: filtersPlaceholder,
                      noOptionsMessage: filtersNoOptionsMessage,
                      value: get(function () {
                        return filter.cells.find(function (cellFilter) {
                          return cellFilter.key === key;
                        }).optionsSelected;
                      }) || []
                    })
                  );
                })
              )
            )
          ),
          _react2.default.createElement(
            _componentVerticalSpacing2.default,
            { size: "xs" },
            _react2.default.createElement(
              "div",
              {
                className: this.getClassName({
                  descendantName: "paginationResultSummary"
                }),
                name: "paginationResultSummary",
                ref: function ref(el) {
                  _this3.paginationResultSummary = el;
                }
              },
              this.getPaginationResultSummaryText(),
              this.getPaginationMarkup("top")
            )
          ),
          _react2.default.createElement(
            _componentVerticalSpacing2.default,
            { size: "m" },
            _react2.default.createElement(
              "div",
              {
                className: this.getClassName({
                  descendantName: "table"
                })
              },
              _react2.default.createElement(
                _componentHorizontalOverflow2.default,
                null,
                _react2.default.createElement(TableWithFilters.Table, { data: this.getPaginatedTable() })
              ),
              pagination.pages < 1 && _react2.default.createElement(
                "p",
                {
                  className: this.getClassName({
                    descendantName: "noResultsMessage"
                  })
                },
                noResultsMessage
              )
            )
          ),
          this.getPaginationMarkup()
        )
      );
    }
  }, {
    key: "addKeys",
    value: function addKeys(_ref2) {
      var filter = _ref2.filter,
          table = _ref2.table;

      if (get(function () {
        return table.head.rows;
      })) {
        table.head.rows.forEach(function (row, indexRow) {
          row.key = indexRow;
          if (row.cells) {
            row.cells.forEach(function (cell, indexCell) {
              cell.key = indexCell;
            });
          }
        });
      }
      if (get(function () {
        return table.bodies[0].rows;
      })) {
        table.bodies[0].rows.forEach(function (row, indexRow) {
          row.key = indexRow;
          if (row.cells) {
            row.cells.forEach(function (cell, indexCell) {
              cell.key = indexCell;

              // Link filters with table cells via "key" attribute
              if (indexRow === 0) {
                if (get(function () {
                  return filter.cells && filter.cells[indexCell];
                })) {
                  filter.cells[indexCell].key = indexCell;
                }
              }
            });
          }
        });
      }
    }
  }, {
    key: "getOptions",
    value: function getOptions(key) {
      var options = [];
      var table = this.state.table;


      if (get(function () {
        return table.bodies;
      })) {
        table.bodies.forEach(function (body) {
          if (body.rows) {
            body.rows.forEach(function (row) {
              if (row.cells && row.cells[key]) {
                var option = row.cells[key].content;

                if (!options.includes(option)) {
                  options.push(option);
                }
              }
            });
          }
        });
      }

      return options.map(function (option) {
        return { label: option, value: option };
      });
    }
  }, {
    key: "getPagination",
    value: function getPagination() {
      var items = [1];
      var pagesNavigableOnSide = this.props.pagesNavigableOnSide;
      var _state$pagination = this.state.pagination,
          currentPage = _state$pagination.currentPage,
          pages = _state$pagination.pages;

      var left = currentPage - pagesNavigableOnSide;
      var right = currentPage + pagesNavigableOnSide;

      // Compensate navigation items so the block will not get smaller on extremities
      if (left <= 0) {
        right = right + Math.abs(left) + 1;
      } else if (right > pages) {
        left = left - (Math.abs(right) - pages);
      }

      if (left > 1 + 1) {
        items.push("0...");
      }

      // Add navigations items adjacent to current page
      for (var page = left; page <= right && page < pages; page++) {
        if (page > 1) {
          items.push(page);
        }
      }

      if (right + 1 < pages) {
        items.push("1...");
      }
      if (pages > 1) {
        items.push(pages);
      }

      return items;
    }
  }, {
    key: "getPaginatedTable",
    value: function getPaginatedTable() {
      var _state2 = this.state,
          table = _state2.table,
          pagination = _state2.pagination;
      var rowsPerPage = this.props.rowsPerPage;

      // Create a deep copy

      var paginatedTable = JSON.parse(JSON.stringify(table));

      if (get(function () {
        return paginatedTable.bodies[0].rows;
      })) {
        var pageRows = paginatedTable.bodies[0].rows.filter(function (row) {
          return !row.hidden;
        }).slice((pagination.currentPage - 1) * rowsPerPage, pagination.currentPage * rowsPerPage);

        if (pageRows.length) {
          paginatedTable.bodies[0].rows = pageRows;
        }
      }

      return paginatedTable;
    }
  }, {
    key: "goToPage",
    value: function goToPage(page) {
      // Create a deep copy
      var pagination = JSON.parse(JSON.stringify(this.state.pagination));

      if (page >= 1 && page <= pagination.pages) {
        pagination.currentPage = page;
        this.setState({ pagination: pagination });
      }
    }
  }, {
    key: "handleChange",
    value: function handleChange(optionsSelected, key) {
      // Create a deep copy
      var filter = JSON.parse(JSON.stringify(this.state.filter));
      var table = JSON.parse(JSON.stringify(this.state.table));
      var pagination = JSON.parse(JSON.stringify(this.state.pagination));
      var rowsPerPage = this.props.rowsPerPage;


      filter.cells.find(function (cell) {
        return cell.key === key;
      }).optionsSelected = optionsSelected;

      if (get(function () {
        return table.bodies[0].rows;
      })) {
        table.bodies[0].rows.forEach(function (row) {
          var filteredOut = row.cells.some(function (cell) {
            var filterCell = filter.cells.find(function (filterCellFind) {
              return filterCellFind.key === cell.key;
            });

            if (filterCell.optionsSelected && filterCell.optionsSelected.length) {
              return filterCell.optionsSelected.some(function (option) {
                return option.value === cell.content;
              }) === false;
            }

            return false;
          });

          row.hidden = filteredOut;
        });

        pagination.rows = table.bodies[0].rows.filter(function (row) {
          return !row.hidden;
        }).length;
        pagination.pages = Math.ceil(pagination.rows / rowsPerPage);
      }
      pagination.currentPage = 1;

      this.setState({
        filter: filter,
        table: table,
        pagination: pagination
      });
    }
  }]);

  return TableWithFilters;
}(_react.Component);

TableWithFilters.displayName = "TableWithFilters";
TableWithFilters.propTypes = {
  /**
   * Data object to generate table and filters
   */
  data: _propTypes2.default.object.isRequired,

  /**
   * Remove selected tag from available filter options
   */
  filtersHideSelectedOptions: _propTypes2.default.bool,

  /**
   * Message displayed in a filter if there is no option resulting from the typeahead input
   */
  filtersNoOptionsMessage: _propTypes2.default.string,

  /**
   * Placeholder for the filter input
   */
  filtersPlaceholder: _propTypes2.default.string,

  /**
   * Information text below the title
   */
  infoText: _propTypes2.default.string.isRequired,

  /**
   * Allow multiple tags to be selected in a filter
   */
  isMulti: _propTypes2.default.bool,

  /**
   * Message displayed if there are no results in the table
   */
  noResultsMessage: _propTypes2.default.string.isRequired,

  /**
   * Max number of pages shown in the pagination to be navigable directly
   */
  pagesNavigableOnSide: _propTypes2.default.number.isRequired,

  /**
   * Text for pagination result summary
   */
  paginationResultSummaryText: _propTypes2.default.string.isRequired,

  /**
   * Number of table rows displayed per page in pagination
   */
  rowsPerPage: _propTypes2.default.number.isRequired,

  /**
   * Subtitle heading rank of the filters' panel
   */
  subtitleHeadingRank: _propTypes2.default.oneOf(_componentHeading2.default.ranks),

  /**
   * Title of the filters' panel
   */
  title: _propTypes2.default.string.isRequired,

  /**
   * Title heading rank of the filters' panel
   */
  titleHeadingRank: _propTypes2.default.oneOf(_componentHeading2.default.ranks)
};
TableWithFilters.defaultProps = {
  infoText: "Find a broker by asset, region, route or name.",
  noResultsMessage: "No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for.",
  pagesNavigableOnSide: 2,
  paginationResultSummaryText: "Viewing {1} of {2} results:",
  rowsPerPage: 50,
  subtitleHeadingRank: "3",
  title: "Search Our Network",
  titleHeadingRank: "2"
};


TableWithFilters.TagFilter = _TagFilter2.default;
TableWithFilters.Table = _Table2.default;

exports.default = TableWithFilters;