"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityCampaign = require("@wel-ui/utility-campaign");

var _utilityCampaign2 = _interopRequireDefault(_utilityCampaign);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * This control creates a hidden field, and gets its value from the provided
 * service (which should be a CampaignTrackingParameterService) by passing the
 * `parameterToTrack` to the service's `get` method.
 *
 * In the case of rehydration, the service will come from
 * @wel-ui/utility-rehydrator's getService function.
 */
var CampaignControl = function CampaignControl(_ref) {
  var defaultValue = _ref.defaultValue,
      name = _ref.name,
      parameterToTrack = _ref.parameterToTrack,
      service = _ref.service;

  var getClassName = (0, _utilityClassNames2.default)(CampaignControl.displayName);

  return _react2.default.createElement("input", {
    className: getClassName(),
    "data-parameter-to-track": parameterToTrack,
    "data-default-value": defaultValue,
    "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(CampaignControl),
    "data-rehydratable-in-place": true,
    type: "hidden",
    value: service && service.get(parameterToTrack) || defaultValue,
    name: name
  });
};

CampaignControl.displayName = "Form.CampaignControl";

CampaignControl.defaultProps = {
  defaultValue: ""
};

CampaignControl.propTypes = {
  /**
   * The default value for this field, if the tracked parameter doesn't exist.
   */
  defaultValue: _propTypes2.default.string,

  /**
   * Name attribute for <input>, required for serialisation.
   */
  name: _propTypes2.default.string.isRequired,

  /**
   * The name of the tracked parameter.
   */
  parameterToTrack: _propTypes2.default.string.isRequired,

  /**
   * The service providing values for tracked parameters.
   *
   * Optional, in case the service isn't ready yet (ie. when rendering on the server)
   */
  service: _propTypes2.default.instanceOf(_utilityCampaign2.default)
};

exports.default = CampaignControl;