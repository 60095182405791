"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _DismissibleBanner = require("./DismissibleBanner");

var _DismissibleBanner2 = _interopRequireDefault(_DismissibleBanner);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)("DismissibleBanner");

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_DismissibleBanner2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var childrenClass = getClassName({
      descendantName: "links"
    });
    var headingClass = getClassName({
      descendantName: "heading"
    });
    var textClass = getClassName({
      descendantName: "text"
    });

    var rehydrationProps = {
      isRehydrating: true,
      dismissButtonText: domNode.getAttribute("data-dismiss-button-text"),
      fixed: domNode.getAttribute("data-fixed") === "true",
      heading: domNode.querySelector("." + headingClass).innerText,
      hideIfAlreadySeen: domNode.getAttribute("data-hide-if-already-seen") === "true",
      text: domNode.querySelector("." + textClass).innerText
    };

    if (rehydrationProps.hideIfAlreadySeen) {
      var alreadySeenCookieName = domNode.getAttribute("data-already-seen-cookie-name");
      var alreadySeenCookieExpectedValue = domNode.getAttribute("data-already-seen-cookie-expected-value");
      var validParentDomains = readData("data-valid-parent-domains");

      if (alreadySeenCookieName) {
        rehydrationProps.alreadySeenCookieName = alreadySeenCookieName;
      }

      if (alreadySeenCookieExpectedValue) {
        rehydrationProps.alreadySeenCookieExpectedValue = alreadySeenCookieExpectedValue;
      }

      if (validParentDomains) {
        rehydrationProps.validParentDomains = validParentDomains;
      }
    }

    var childrenNode = domNode.querySelector("." + childrenClass);

    if (childrenNode) {
      return _react2.default.createElement(
        _DismissibleBanner2.default,
        rehydrationProps,
        (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra)
      );
    }

    return _react2.default.createElement(_DismissibleBanner2.default, rehydrationProps);
  }
};

exports.default = rehydrator;