"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Provider = exports.Consumer = undefined;

var _react = require("react");

var _createContext = (0, _react.createContext)(false),
    Consumer = _createContext.Consumer,
    Provider = _createContext.Provider;

exports.Consumer = Consumer;
exports.Provider = Provider;