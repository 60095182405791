"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FilterSectionOverflow = function (_Component) {
  _inherits(FilterSectionOverflow, _Component);

  function FilterSectionOverflow(props) {
    _classCallCheck(this, FilterSectionOverflow);

    var _this = _possibleConstructorReturn(this, (FilterSectionOverflow.__proto__ || Object.getPrototypeOf(FilterSectionOverflow)).call(this, props));

    _this.state = {
      isExpanded: false
    };

    _this.handleToggle = _this.handleToggle.bind(_this);
    _this.getClassName = (0, _utilityClassNames2.default)("SearchFiltersFilterSectionOverflow");
    return _this;
  }

  _createClass(FilterSectionOverflow, [{
    key: "handleToggle",
    value: function handleToggle() {
      this.setState({
        isExpanded: !this.state.isExpanded
      });
    }
  }, {
    key: "render",
    value: function render() {
      var titleText = this.state.isExpanded ? this.props.collapseText : this.props.expandText;

      return _react2.default.createElement(
        "li",
        {
          className: this.getClassName({
            states: this.state.isExpanded ? "expanded" : ""
          }),
          "data-rehydratable-in-place": true,
          "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(FilterSectionOverflow),
          "data-collapse-text": this.props.collapseText,
          "data-expand-text": this.props.expandText
        },
        _react2.default.createElement(
          "ul",
          { className: this.getClassName({ descendantName: "items" }) },
          this.props.children
        ),
        _react2.default.createElement(
          "button",
          {
            className: this.getClassName({ descendantName: "toggleButton" }),
            onClick: this.handleToggle
          },
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName({
                descendantName: "toggleButtonIcon"
              })
            },
            this.state.isExpanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
          ),
          _react2.default.createElement(
            "span",
            {
              className: this.getClassName({
                descendantName: "toggleButtonText"
              })
            },
            titleText
          )
        )
      );
    }
  }]);

  return FilterSectionOverflow;
}(_react.Component);

FilterSectionOverflow.propTypes = {
  /**
   * The children of this section. Should be a `SearchFilters.FilterItem` or `SearchFilters.FilterGroup`.
   */
  children: _propTypes2.default.node,

  /**
   * The text to show when the section can expand
   */
  collapseText: _propTypes2.default.string,

  /**
   * The text to show when the section can expand
   */
  expandText: _propTypes2.default.string
};
FilterSectionOverflow.defaultProps = {
  collapseText: "See less",
  expandText: "See more"
};
FilterSectionOverflow.displayName = "SearchFilters.FilterSectionOverflow";
exports.default = FilterSectionOverflow;