"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitRequest = undefined;

var _submitRequest = require("./submitRequest");

var _submitRequest2 = _interopRequireDefault(_submitRequest);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.submitRequest = _submitRequest2.default;