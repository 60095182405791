"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TabList = require("./TabList");

var _TabList2 = _interopRequireDefault(_TabList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TabList2.default;