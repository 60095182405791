"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SelectControl = function SelectControl(_ref) {
  var defaultValue = _ref.defaultValue,
      disabled = _ref.disabled,
      id = _ref.id,
      labelText = _ref.labelText,
      name = _ref.name,
      onBlur = _ref.onBlur,
      onChange = _ref.onChange,
      options = _ref.options,
      placeholder = _ref.placeholder,
      placeholderText = _ref.placeholderText,
      required = _ref.required,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(SelectControl.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "label",
      { className: getClassName({ descendantName: "label" }), htmlFor: id },
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "labelText" }) },
        labelText,
        " ",
        !required && _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "optional" }) },
          "(Optional)"
        )
      ),
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "input" }) },
        _react2.default.createElement(
          "select",
          {
            className: getClassName({ descendantName: "select" }),
            defaultValue: defaultValue,
            disabled: disabled,
            id: id,
            name: name,
            onBlur: onBlur,
            onChange: onChange,
            required: required,
            value: value
          },
          placeholder && _react2.default.createElement(
            "option",
            {
              className: getClassName({ descendantName: "option" }),
              value: ""
            },
            placeholderText
          ),
          options.map(function (option) {
            return _react2.default.createElement(
              "option",
              {
                className: getClassName({ descendantName: "option" }),
                key: option.value,
                value: option.value
              },
              option.label
            );
          })
        ),
        _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "arrow" }) },
          _react2.default.createElement(_chevronDown2.default, { width: 24 })
        )
      )
    )
  );
};

/* eslint-disable max-len */
SelectControl.propTypes = {
  /**
   * Initialize <select> with a value
   */
  defaultValue: _propTypes2.default.string,
  /**
   * Disable the <select>
   */
  disabled: _propTypes2.default.bool,
  /**
   * Id attribute for <select> (and <label> `for` atrribute)
   */
  id: _propTypes2.default.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: _propTypes2.default.string.isRequired,
  /**
   * Name attribute for <select>, required for serialisation
   */
  name: _propTypes2.default.string.isRequired,
  /**
   * Listen for blur on the <select>.
   */
  onBlur: _propTypes2.default.func,
  /**
   * Listen for changes on the <select>
   *
   * N.B. onChange should only be used for storing the value. Any action related to the value of the <select> should be handled onBlur. See https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-onchange.md
   */
  onChange: _propTypes2.default.func,
  /**
   * Array of objects with `value` and `label` properties
   */
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string.isRequired,
    value: _propTypes2.default.string.isRequired
  })),
  /**
   * Show a placeholder <option>
   */
  placeholder: _propTypes2.default.bool,
  /**
   * Text for placeholder <option>
   */
  placeholderText: _propTypes2.default.string,
  /**
   * Set <select> to be required
   */
  required: _propTypes2.default.bool,
  /**
   * Explicitly set the value of the <select>. Will switch to controlled mode
   */
  value: _propTypes2.default.string
};
/* eslint-enable max-len */

SelectControl.defaultProps = {
  placeholder: true,
  placeholderText: "Select…"
};

SelectControl.displayName = "Form.SelectControl";

exports.default = SelectControl;