"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentSvg = require("@wel-ui/component-svg");

var _componentSvg2 = _interopRequireDefault(_componentSvg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var Icon = function Icon(props) {
  return _react2.default.createElement(_componentSvg2.default, props, Icon.svg);
};

Icon.propTypes = {
  height: _propTypes2.default.number,
  label: _propTypes2.default.string,
  width: _propTypes2.default.number
};

Icon.displayName = "UiLock";

Icon.svg = _react2.default.createElement(
  "svg",
  { viewBox: "0 0 24 24" },
  _react2.default.createElement(
    "g",
    {
      className: "nc-icon-wrapper",
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "square",
      strokeWidth: "2"
    },
    _react2.default.createElement("path", {
      d: "M12 1C9.2 1 7 3.2 7 6v3h10V6c0-2.8-2.2-5-5-5zM4 9h16v14H4z"
    }),
    _react2.default.createElement("path", {
      d: "M12 13a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM12 17v2",
      "data-color": "color-2"
    })
  )
);

exports.default = Icon;
