"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var highlightKeywords = function highlightKeywords(getClassName, phrase, keyword) {
  var startIndex = phrase.search(keyword);
  var endIndex = startIndex + keyword.length;

  if (startIndex >= 0) {
    return _react2.default.createElement(
      "span",
      null,
      phrase.slice(0, startIndex),
      _react2.default.createElement(
        "span",
        {
          className: getClassName({
            descendantName: "highlight"
          })
        },
        keyword
      ),
      phrase.slice(endIndex)
    );
  }

  return _react2.default.createElement(
    "span",
    null,
    phrase
  );
};

var TypeaheadItem = function TypeaheadItem(_ref) {
  var colorTheme = _ref.colorTheme,
      highlight = _ref.highlight,
      id = _ref.id,
      onClick = _ref.onClick,
      selected = _ref.selected,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(TypeaheadItem.displayName);

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({
        states: selected ? "selected" : "",
        modifiers: (0, _classnames2.default)({
          dark: colorTheme === "dark"
        })
      }),
      role: "option",
      "aria-selected": selected ? "true" : "false",
      id: id
    },
    _react2.default.createElement(
      "button",
      {
        className: getClassName({
          descendantName: "button"
        }),
        onClick: onClick,
        type: "submit",
        value: value
      },
      highlightKeywords(getClassName, value, highlight)
    )
  );
};

TypeaheadItem.displayName = "SearchInputTypeaheadItem";

TypeaheadItem.propTypes = {
  colorTheme: _propTypes2.default.oneOf(["dark", "light"]),
  highlight: _propTypes2.default.string,
  id: _propTypes2.default.string,
  onClick: _propTypes2.default.func,
  selected: _propTypes2.default.bool,
  value: _propTypes2.default.string
};

TypeaheadItem.defaultProps = {
  colorTheme: "light"
};

exports.default = TypeaheadItem;