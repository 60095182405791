"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Link = require("../Link");

var _Link2 = _interopRequireDefault(_Link);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterGroup = function FilterGroup(_ref) {
  var children = _ref.children,
      href = _ref.href,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("SearchFiltersFilterGroup");

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "title" }) },
      _react2.default.createElement(
        _Link2.default,
        { href: href },
        title
      )
    ),
    _react2.default.createElement(
      "ul",
      { className: getClassName({ descendantName: "items" }) },
      children
    )
  );
};

FilterGroup.displayName = "SearchFilters.FilterGroup";

FilterGroup.propTypes = {
  /**
   * This group's children. Should be `SearchFilters.FilterItem`.
   */
  children: _propTypes2.default.node,

  /**
   * The destination for the title of this group.
   */
  href: _propTypes2.default.string,

  /**
   * The title of this group.
   */
  title: _propTypes2.default.string
};

exports.default = FilterGroup;