"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chapterNavChapterRehydrator = exports.chapterNavRehydrator = undefined;

var _Longform = require("./Longform");

var _Longform2 = _interopRequireDefault(_Longform);

var _ChapterNav = require("./components/ChapterNav");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Longform2.default;
exports.chapterNavRehydrator = _ChapterNav.rehydrator;
exports.chapterNavChapterRehydrator = _ChapterNav.chapterRehydrator;