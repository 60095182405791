"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _search = require("@wel-ui/icons/lib/ui/search");

var _search2 = _interopRequireDefault(_search);

var _TypeaheadItem = require("./components/TypeaheadItem");

var _TypeaheadItem2 = _interopRequireDefault(_TypeaheadItem);

var _queryString = require("query-string");

var _queryString2 = _interopRequireDefault(_queryString);

var _fetchJsonp = require("fetch-jsonp");

var _fetchJsonp2 = _interopRequireDefault(_fetchJsonp);

var _lodash = require("lodash.debounce");

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require("lodash.uniqueid");

var _lodash4 = _interopRequireDefault(_lodash3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var getRandomInt = function getRandomInt(minFloat, maxFloat) {
  var min = Math.ceil(minFloat);
  var max = Math.floor(maxFloat);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

var SearchInput = function (_Component) {
  _inherits(SearchInput, _Component);

  /* eslint-disable max-len */
  function SearchInput(props) {
    _classCallCheck(this, SearchInput);

    var _this = _possibleConstructorReturn(this, (SearchInput.__proto__ || Object.getPrototypeOf(SearchInput)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(SearchInput.displayName);

    _this.input = null;
    _this.submitButton = null;

    _this.state = {
      userQuery: _this.props.value,
      query: _this.props.value,
      searchSuggestions: [],
      selectedSuggestion: null
    };

    var searchSuggestionDebounceTimeout = 100;

    _this.getSearchSuggestionsDebounced = (0, _lodash2.default)(_this.getSearchSuggestions, searchSuggestionDebounceTimeout);

    _this.id = (0, _lodash4.default)("searchInput-");

    var cacheBustMin = 1;
    var cacheBustMax = 999999999999;

    // We only bust cache once per component, rather than with every request.
    _this.cacheBust = getRandomInt(cacheBustMin, cacheBustMax); // get value between 1 and 999999999999
    return _this;
  }
  /* eslint-enable max-len */

  _createClass(SearchInput, [{
    key: "buildTypeaheadUrl",
    value: function buildTypeaheadUrl(query) {
      var _props$typeaheadUrl$s = this.props.typeaheadUrl.split("?"),
          _props$typeaheadUrl$s2 = _slicedToArray(_props$typeaheadUrl$s, 2),
          cleanUrl = _props$typeaheadUrl$s2[0],
          existingQs = _props$typeaheadUrl$s2[1];

      var requestParams = _extends({
        max_results: 7, // eslint-disable-line camelcase
        beginning: 1
      }, _queryString2.default.parse(existingQs), {
        query: query,
        _: this.cacheBust
      });

      return cleanUrl + "?" + _queryString2.default.stringify(requestParams);
    }
  }, {
    key: "getSearchSuggestions",
    value: function getSearchSuggestions(query) {
      var _this2 = this;

      return (0, _fetchJsonp2.default)(this.buildTypeaheadUrl(query)).then(function (response) {
        return response.json();
      }).then(function (data) {
        _this2.setState({
          searchSuggestions: data,
          selectedSuggestion: null
        });
      });
    }
  }, {
    key: "focusAppropriateSuggestion",
    value: function focusAppropriateSuggestion(e) {
      var selectedSuggestion = this.state.selectedSuggestion;

      if (e.key === "ArrowDown") {
        if (selectedSuggestion === null) {
          selectedSuggestion = 0;
        } else if (selectedSuggestion < this.state.searchSuggestions.length - 1) {
          selectedSuggestion = selectedSuggestion + 1;
        }
      } else if (e.key === "ArrowUp") {
        selectedSuggestion = selectedSuggestion > 0 ? selectedSuggestion - 1 : null;
      }

      this.setState({
        query: selectedSuggestion === null ? this.state.userQuery : this.state.searchSuggestions[selectedSuggestion],
        selectedSuggestion: selectedSuggestion
      });
    }
  }, {
    key: "reset",
    value: function reset() {
      this.setState({
        userQuery: "",
        query: "",
        searchSuggestions: [],
        selectedSuggestion: null
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (prevProps.value !== this.props.value && prevProps.value !== this.state.query) {
        this.setState({
          userQuery: this.props.value,
          query: this.props.value
        });
      }
    }
  }, {
    key: "handleClearClick",
    value: function handleClearClick(e) {
      e.preventDefault();

      this.reset();

      // Retain focus
      this.input.focus();
    }
  }, {
    key: "handleInputChange",
    value: function handleInputChange(e) {
      var hasQuery = e.target.value.length > 1;

      this.setState({
        query: e.target.value,
        userQuery: e.target.value,
        searchSuggestions: hasQuery ? this.state.searchSuggestions : []
      });

      if (hasQuery && this.props.typeahead && this.props.typeaheadUrl) {
        this.getSearchSuggestionsDebounced(e.target.value);
      }
    }
  }, {
    key: "handleSearchSubmit",
    value: function handleSearchSubmit(e) {
      if (this.state.query === "") {
        e.preventDefault();
        this.input.focus();
      } else if (this.props.onSubmit) {
        e.preventDefault();
        this.props.onSubmit(this.state.query);
      }

      this.setState({ searchSuggestions: [] });
    }
  }, {
    key: "handleEscape",
    value: function handleEscape(e) {
      e.preventDefault();
      if (this.state.searchSuggestions.length > 0) {
        this.setState({
          searchSuggestions: [],
          query: this.state.userQuery
        });
      } else {
        this.input.blur();
      }
    }
  }, {
    key: "handleInputKeyDown",
    value: function handleInputKeyDown(e) {
      if (e.key === "Enter" && this.props.onSubmit) {
        this.handleSearchSubmit(e);
      } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        this.focusAppropriateSuggestion(e);
      } else if (e.key === "Escape") {
        this.handleEscape(e);
      }
    }
  }, {
    key: "handleSuggestionClick",
    value: function handleSuggestionClick(e) {
      var _this3 = this;

      // Although this is a submit event, native form submissions rely on the
      // state being up to date. setState happens asynchronously, but event
      // callbacks happen synchronously. In order to support both JS and native
      // form submissions in this scenario, we use an escape hatch by
      // programatically firing a click event on the submit button after the state
      // has updated.

      e.preventDefault();

      this.setState({
        query: e.currentTarget.value
      }, function () {
        return _this3.submitButton.click();
      });
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
        return;
      }

      this.setState({
        searchSuggestions: []
      });

      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    }
  }, {
    key: "handleRef",
    value: function handleRef(ref) {
      this.ref = ref;
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            states: (0, _classnames2.default)({
              expanded: this.state.searchSuggestions.length > 0,
              active: this.state.query !== ""
            }),
            modifiers: (0, _classnames2.default)({
              dark: this.props.colorTheme === "dark"
            })
          }),
          ref: this.handleRef.bind(this),
          onBlur: this.handleBlur.bind(this),
          "data-auto-focus": JSON.stringify(this.props.autoFocus),
          "data-color-theme": JSON.stringify(this.props.colorTheme),
          "data-name": JSON.stringify(this.props.name),
          "data-placeholder": JSON.stringify(this.props.placeholder),
          "data-typeahead": JSON.stringify(this.props.typeahead),
          "data-typeahead-url": JSON.stringify(this.props.typeaheadUrl),
          "data-value": JSON.stringify(this.props.value)
        },
        _react2.default.createElement(
          "label",
          {
            className: this.getClassName({
              descendantName: "label",
              utilities: "hiddenVisually"
            }),
            htmlFor: this.id
          },
          "Search"
        ),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "box" }) },
          _react2.default.createElement("input", {
            // disabling the line below, autoFocus is a prop of this component jsx-a11y/no-autofocus
            // eslint-disable-next-line
            autoFocus: this.props.autoFocus,
            className: this.getClassName({ descendantName: "input" }),
            autoComplete: "off",
            placeholder: this.props.placeholder,
            id: this.id,
            onChange: this.handleInputChange.bind(this),
            onKeyDown: this.handleInputKeyDown.bind(this),
            ref: function ref(_ref) {
              _this4.input = _ref;
            },
            type: "search",
            value: this.state.query,
            name: this.props.name,
            "aria-haspopup": "true",
            "aria-owns": this.id + "-typeahead",
            "aria-expanded": this.state.query === "" ? "false" : "true",
            "aria-autocomplete": "both",
            "aria-label": "Search"
            // disabling the line below, aria is valid jsx-a11y/role-has-required-aria-props
            // eslint-disable-next-line
            , role: "combobox",
            "aria-activedescendant": this.state.selectedSuggestion === null ? "" : this.id + "-typeaheadItem-" + this.state.selectedSuggestion
          }),
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "buttons" }) },
            _react2.default.createElement(
              "button",
              {
                "aria-label": "Clear search",
                className: this.getClassName({ descendantName: "clearButton" }),
                onClick: this.handleClearClick.bind(this),
                type: "button"
              },
              _react2.default.createElement(
                "div",
                { className: this.getClassName({ descendantName: "icon" }) },
                _react2.default.createElement(_cross2.default, null)
              )
            ),
            _react2.default.createElement(
              "button",
              {
                "aria-label": "Submit search",
                className: this.getClassName({ descendantName: "searchButton" }),
                onClick: this.handleSearchSubmit.bind(this),
                type: "submit",
                ref: function ref(_ref2) {
                  return _this4.submitButton = _ref2;
                }
              },
              _react2.default.createElement(
                "div",
                { className: this.getClassName({ descendantName: "icon" }) },
                _react2.default.createElement(_search2.default, null)
              )
            )
          )
        ),
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName({ descendantName: "typeahead" }),
            tabIndex: "-1"
          },
          _react2.default.createElement(
            "ul",
            {
              className: this.getClassName({ descendantName: "typeaheadItems" }),
              id: this.id + "-typeahead",
              role: "listbox"
            },
            this.state.searchSuggestions.map(function (suggestion, i) {
              return _react2.default.createElement(_TypeaheadItem2.default, {
                colorTheme: _this4.props.colorTheme,
                highlight: _this4.state.userQuery,
                id: _this4.id + "-typeaheadItem-" + i,
                key: i,
                onClick: _this4.handleSuggestionClick.bind(_this4),
                selected: _this4.state.selectedSuggestion === i,
                value: suggestion
              });
            })
          )
        )
      );
    }
  }]);

  return SearchInput;
}(_react.Component);

SearchInput.displayName = "SearchInput";
SearchInput.propTypes = {
  /**
   * Whether this search input should have focus on page load. Note that only one input can have `autoFocus` set on a page.
   */
  autoFocus: _propTypes2.default.bool,
  /**
   * Color theme of the search input.
   */
  colorTheme: _propTypes2.default.oneOf(["light", "dark"]),
  /**
   * Blur callback.
   */
  onBlur: _propTypes2.default.func,
  /**
   * Submission callback. Prevents default form submit if specified.
   */
  onSubmit: _propTypes2.default.func,
  /**
   * Name of the input, used for form submission. Must be unique to form.
   */
  name: _propTypes2.default.string,
  /**
   * Placeholder text.
   */
  placeholder: _propTypes2.default.string,
  /**
   * Use typeahead for search suggestions.
   */
  typeahead: _propTypes2.default.bool,
  /**
   * JSONP URL for typeahead. Required if `typeahead = true`.
   */
  typeaheadUrl: _propTypes2.default.string,
  /**
   * Value for the input. Overrides internal value if updated.
   */
  value: _propTypes2.default.string
};
SearchInput.defaultProps = {
  autoFocus: false,
  colorTheme: "light",
  name: "q",
  placeholder: "Search",
  typeahead: true,
  typeaheadUrl: "",
  value: ""
};
exports.default = SearchInput;