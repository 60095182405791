"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DOMToReactConverter = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _domElementToReactElement = require("./domElementToReactElement.js");

var _domElementToReactElement2 = _interopRequireDefault(_domElementToReactElement);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DOMToReactConverter = function () {
  function DOMToReactConverter(customHandlers) {
    _classCallCheck(this, DOMToReactConverter);

    this.customHandlers = customHandlers;
  }

  _createClass(DOMToReactConverter, [{
    key: "processChildren",
    value: function processChildren(children) {
      var childrenAsReact = [];

      for (var i = 0; i < children.length; i++) {
        childrenAsReact.push(this.handleDOMNode(children[i]));
      }

      return childrenAsReact;
    }
  }, {
    key: "handleElement",
    value: function handleElement(el) {
      var handler = this.customHandlers.find(function (h) {
        return h.shouldProcessNode(el);
      });

      if (handler) {
        if (handler.replaceChildren) {
          return handler.processNode(el);
        }

        return handler.processNode(el, this.processChildren(el.childNodes, this.handleDOMNode));
      }

      return (0, _domElementToReactElement2.default)(el, this.handleDOMNode.bind(this));
    }
  }, {
    key: "handleDOMNode",
    value: function handleDOMNode(domNode) {
      switch (domNode.nodeType) {
        case Node.ELEMENT_NODE:
          return this.handleElement(domNode);

        case Node.TEXT_NODE:
          return domNode.data;

        default:
          return null;
      }
    }
  }, {
    key: "getReactElement",
    value: function getReactElement(rootNode) {
      return this.handleElement(rootNode);
    }
  }]);

  return DOMToReactConverter;
}();

exports.default = function (domNode, customHandlers) {
  var converter = new DOMToReactConverter(customHandlers);

  return converter.getReactElement(domNode);
};

exports.DOMToReactConverter = DOMToReactConverter;