"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tabPanelRehydrator = undefined;

var _TabPanel = require("./TabPanel");

var _TabPanel2 = _interopRequireDefault(_TabPanel);

var _TabPanel3 = require("./TabPanel.rehydrator");

var _TabPanel4 = _interopRequireDefault(_TabPanel3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TabPanel2.default;
exports.tabPanelRehydrator = _TabPanel4.default;