"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _arrowRight = require("@wel-ui/icons/lib/ui/arrow-right");

var _arrowRight2 = _interopRequireDefault(_arrowRight);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _currentOpenTabPanelContext = require("./currentOpenTabPanelContext");

var _defaultOpenTabPanelContext = require("./defaultOpenTabPanelContext");

var _setOpenTabPanelContext = require("./setOpenTabPanelContext");

var _Tab = require("./components/Tab");

var _Tab2 = _interopRequireDefault(_Tab);

var _TabList = require("./components/TabList");

var _TabList2 = _interopRequireDefault(_TabList);

var _TabPanel = require("./components/TabPanel");

var _TabPanel2 = _interopRequireDefault(_TabPanel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Flyouts = function (_Component) {
  _inherits(Flyouts, _Component);

  _createClass(Flyouts, null, [{
    key: "getFlyouts",
    value: function getFlyouts(children) {
      var tabs = [];

      _react2.default.Children.forEach(children, function (child) {
        if (child.type === _TabPanel2.default) {
          tabs.push({
            title: child.props.tabTitle,
            relatedId: child.props.id
          });
        }
      });

      return tabs;
    }
  }]);

  function Flyouts(props) {
    _classCallCheck(this, Flyouts);

    var _this = _possibleConstructorReturn(this, (Flyouts.__proto__ || Object.getPrototypeOf(Flyouts)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(Flyouts.displayName);

    var tabs = Flyouts.getFlyouts(props.children);

    _this.state = {
      currentOpenPanel: null,
      defaultOpenPanel: tabs[0] && tabs[0].relatedId || null,
      tabs: tabs
    };

    _this.handleTabClick = _this.handleTabClick.bind(_this);
    _this.handleTabListKeyDown = _this.handleTabListKeyDown.bind(_this);
    _this.setOpenPanel = _this.setOpenPanel.bind(_this);
    return _this;
  }

  _createClass(Flyouts, [{
    key: "setOpenPanel",
    value: function setOpenPanel(panelId) {
      this.setState({ currentOpenPanel: panelId });
    }
  }, {
    key: "handleTabListKeyDown",
    value: function handleTabListKeyDown(e) {
      var _this2 = this;

      var siteHeaderViewportWidth = 1200;

      /*
       * Only apply this behaviour above the SiteHeader viewport width, where the
       * "tabs" UI pattern is used.
       */
      if (window.innerWidth < siteHeaderViewportWidth) {
        return;
      }

      var currentOpenPanelIndex = this.state.tabs.findIndex(function (tab) {
        return tab.relatedId === _this2.state.currentOpenPanel;
      });

      var direction = getComputedStyle(this.ref).direction;
      var nextKey = "ArrowDown";
      var previousKey = "ArrowUp";
      var focusPanelKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";

      if ([nextKey, previousKey].includes(e.key)) {
        var newOpenPanel = void 0;

        if (e.key === previousKey) {
          newOpenPanel = this.state.tabs[Math.max(0, currentOpenPanelIndex - 1)].relatedId;
        } else {
          newOpenPanel = this.state.tabs[Math.min(this.state.tabs.length - 1, currentOpenPanelIndex + 1)].relatedId;
        }

        this.setOpenPanel(newOpenPanel);
      } else if (e.key === focusPanelKey || e.key === "Tab") {
        // See https://inclusive-components.design/tabbed-interfaces/#aproblemreadingpanels
        var openPanel = this.ref.querySelector("#" + this.state.currentOpenPanel);

        if (openPanel) {
          openPanel.focus();
        }
      }
    }
  }, {
    key: "handleTabClick",
    value: function handleTabClick(e, relatedId) {
      e.preventDefault();
      var siteHeaderViewportWidth = 1200;

      this.setOpenPanel(relatedId);

      /*
       * Only apply this behaviour below the SiteHeader viewport width, where the
       * "sliding panels" UI pattern is used.
       */
      if (window.innerWidth < siteHeaderViewportWidth) {
        this.ref.querySelector("#" + relatedId).focus();
      }
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var newState = {
        tabs: Flyouts.getFlyouts(nextProps.children)
      };

      if (newState.tabs.length === 0) {
        newState.currentOpenPanel = null;
      } else if (!newState.tabs.map(function (tab) {
        return tab.relatedId;
      }).includes(this.state.currentOpenPanel)) {
        // The previously open flyout no longer exists. Open the first flyout.
        newState.currentOpenPanel = newState.tabs[0].relatedId;
      }

      this.setState(newState);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(oldProps, oldState) {
      /*
       * Below the SiteHeader viewport width, ensure that focus is on the correct
       * Tab when closing a TabPanel.
       *
       * At or above the SiteHeader viewport width `this.state.currentOpenPanel`
       * is never `null`, so this behaviour will be skipped.
       */
      if (this.state.currentOpenPanel === null && oldState.currentOpenPanel !== null) {
        this.ref.querySelector("a[href=\"#" + oldState.currentOpenPanel + "\"]").focus();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          children = _props.children,
          summaryLinkHref = _props.summaryLinkHref,
          summaryLinkText = _props.summaryLinkText;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            modifiers: summaryLinkHref && summaryLinkText && "withSummaryLink"
          }),
          ref: function ref(_ref) {
            return _this3.ref = _ref;
          },
          "data-summary-link-href": summaryLinkHref,
          "data-summary-link-text": summaryLinkText
        },
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "list" }) },
          _react2.default.createElement(
            _TabList2.default,
            null,
            this.state.tabs.map(function (tab) {
              return _react2.default.createElement(_Tab2.default, _extends({}, tab, {
                key: tab.relatedId,
                onClick: _this3.handleTabClick,
                onKeyDown: _this3.handleTabListKeyDown,
                selected: tab.relatedId === _this3.state.currentOpenPanel || _this3.state.currentOpenPanel === null && tab.relatedId === _this3.state.defaultOpenPanel
              }));
            })
          ),
          summaryLinkHref && summaryLinkText && _react2.default.createElement(
            "a",
            {
              className: this.getClassName({ descendantName: "summaryLink" }),
              href: summaryLinkHref,
              tabIndex: "-1"
            },
            _react2.default.createElement(
              "span",
              {
                className: this.getClassName({
                  descendantName: "summaryLinkText"
                })
              },
              summaryLinkText
            ),
            _react2.default.createElement(
              "span",
              {
                className: this.getClassName({
                  descendantName: "summaryLinkIcon"
                })
              },
              _react2.default.createElement(_arrowRight2.default, { width: 16 })
            )
          )
        ),
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "panel" }) },
          _react2.default.createElement(
            _setOpenTabPanelContext.Provider,
            { value: this.setOpenPanel },
            _react2.default.createElement(
              _currentOpenTabPanelContext.Provider,
              { value: this.state.currentOpenPanel },
              _react2.default.createElement(
                _defaultOpenTabPanelContext.Provider,
                { value: this.state.defaultOpenPanel },
                children
              )
            )
          )
        )
      );
    }
  }]);

  return Flyouts;
}(_react.Component);

Flyouts.displayName = "SiteHeaderFlyouts";
Flyouts.propTypes = {
  /**
   * The content of the flyout.
   * These should be of type `SiteHeader.Flyouts.TabPanel`.
   */
  children: _propTypes2.default.node,
  /**
   * Href for summary link below list, required if `summaryLinkText`.
   */
  summaryLinkHref: _propTypes2.default.string,
  /**
   * Text for summary link below list, required if `summaryLinkHref`.
   */
  summaryLinkText: _propTypes2.default.string
};


Flyouts.TabPanel = _TabPanel2.default;

exports.default = Flyouts;