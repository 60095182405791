"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (path, formData) {
  var fetchOptions = {
    body: formData,
    credentials: "same-origin",
    method: "POST",
    headers: {
      Accept: "application/json"
    }
  };

  return fetch(path, fetchOptions);
};