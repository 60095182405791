"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var VideoPlayerRegistry = function () {
  function VideoPlayerRegistry() {
    _classCallCheck(this, VideoPlayerRegistry);

    this.players = [];
  }

  _createClass(VideoPlayerRegistry, [{
    key: "register",
    value: function register(videoPlayer) {
      this.players.push(videoPlayer);
    }
  }, {
    key: "deregister",
    value: function deregister(videoPlayer) {
      this.players = this.players.filter(function (p) {
        return p !== videoPlayer;
      });
    }
  }, {
    key: "deactivateAll",
    value: function deactivateAll() {
      var videoPlayer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var players = videoPlayer === null ? this.players : this.players.filter(function (p) {
        return p !== videoPlayer;
      });

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = players[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var player = _step.value;

          player.deactivate();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  }]);

  return VideoPlayerRegistry;
}();

exports.default = new VideoPlayerRegistry();
exports.VideoPlayerRegistry = VideoPlayerRegistry;