"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _CardBase = require("../../bases/CardBase");

var _CardBase2 = _interopRequireDefault(_CardBase);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

var _RehydratableTwitterCardTimeline = require("./RehydratableTwitterCardTimeline");

var _RehydratableTwitterCardTimeline2 = _interopRequireDefault(_RehydratableTwitterCardTimeline);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TwitterCard = function (_Component) {
  _inherits(TwitterCard, _Component);

  function TwitterCard() {
    _classCallCheck(this, TwitterCard);

    return _possibleConstructorReturn(this, (TwitterCard.__proto__ || Object.getPrototypeOf(TwitterCard)).apply(this, arguments));
  }

  _createClass(TwitterCard, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          borderless = _props.borderless,
          eyebrow = _props.eyebrow,
          timelineDataSource = _props.timelineDataSource,
          timelineOptions = _props.timelineOptions;

      var getClassName = (0, _utilityClassNames2.default)(TwitterCard.displayName);

      return _react2.default.createElement(
        "div",
        { className: getClassName() },
        _react2.default.createElement(
          _CardBase2.default,
          { borderless: borderless, size: "large" },
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "wrapper" }) },
            _react2.default.createElement(
              "div",
              { className: getClassName({ descendantName: "inner" }) },
              _react2.default.createElement(
                "div",
                { className: getClassName({ descendantName: "eyebrow" }) },
                _react2.default.createElement(_componentEyebrow2.default, { text: eyebrow })
              ),
              _react2.default.createElement(
                "div",
                { className: getClassName({ descendantName: "timeline" }) },
                _react2.default.createElement(_RehydratableTwitterCardTimeline2.default, {
                  timelineOptions: timelineOptions,
                  timelineDataSource: timelineDataSource
                })
              )
            )
          )
        )
      );
    }
  }]);

  return TwitterCard;
}(_react.Component);

TwitterCard.propTypes = {
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Eyebrow to display above the timeline
   */
  eyebrow: _propTypes2.default.string,
  /**
   * Data source for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
   */
  timelineDataSource: _propTypes2.default.object.isRequired,
  /**
   * Display options for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
   */
  timelineOptions: _propTypes2.default.object
};
TwitterCard.defaultProps = {
  timelineOptions: {
    chrome: "noheader",
    linkColor: "#005da2",
    dnt: true
  }
};
TwitterCard.displayName = "TwitterCard";
exports.default = TwitterCard;