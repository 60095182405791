"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getClassName = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _utility = require("react-suitcssify/dist/lib/utility");

var _utilityConfiguration = require("@wel-ui/utility-configuration");

var _utilityConfiguration2 = _interopRequireDefault(_utilityConfiguration);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = function getClassName(args) {
  return (0, _utility.getClassName)(_extends({
    namespace: _utilityConfiguration2.default.current.styles.namespace
  }, args));
};

var getClassNameFactory = function getClassNameFactory(componentName) {
  return function (args) {
    return getClassName(_extends({
      componentName: componentName
    }, args));
  };
};

exports.default = getClassNameFactory;
exports.getClassName = getClassName;