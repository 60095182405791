"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _scrollama = require("scrollama");

var _scrollama2 = _interopRequireDefault(_scrollama);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require("lodash.debounce");

var _lodash2 = _interopRequireDefault(_lodash);

var _Chapter = require("./components/Chapter");

var _Chapter2 = _interopRequireDefault(_Chapter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ChapterNavInner = function (_Component) {
  _inherits(ChapterNavInner, _Component);

  _createClass(ChapterNavInner, null, [{
    key: "getChapters",
    value: function getChapters(children) {
      var chapters = [];

      _react2.default.Children.forEach(children, function (child) {
        if (child.type === _Chapter2.default) {
          chapters.push(child.props);
        }
      });

      return chapters;
    }
  }, {
    key: "getCurrentChapter",
    value: function getCurrentChapter(chapters) {
      return Math.max(chapters.findIndex(function (chapter) {
        return chapter.active === true;
      }), 0);
    }
  }]);

  function ChapterNavInner(props) {
    _classCallCheck(this, ChapterNavInner);

    var _this = _possibleConstructorReturn(this, (ChapterNavInner.__proto__ || Object.getPrototypeOf(ChapterNavInner)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)("LongformChapterNavInner");

    var chapters = ChapterNavInner.getChapters(props.children);

    _this.state = {
      currentChapterIndex: ChapterNavInner.getCurrentChapter(chapters),
      chapters: chapters,
      expanded: false
    };

    _this.scrollSpyOffset = 0.1;
    _this.resizeDebounceTimeout = 200;

    _this.handleBlur = _this.handleBlur.bind(_this);
    _this.handleClick = _this.handleClick.bind(_this);
    _this.handleChapterClick = _this.handleChapterClick.bind(_this);
    _this.handleStepEnter = _this.handleStepEnter.bind(_this);
    _this.handleStepExit = _this.handleStepExit.bind(_this);
    _this.resizeScrollSpy = _this.resizeScrollSpy.bind(_this);
    _this.resizeScrollSpyDebounced = (0, _lodash2.default)(_this.resizeScrollSpy, _this.resizeDebounceTimeout).bind(_this);
    return _this;
  }

  _createClass(ChapterNavInner, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      // Run on mount rather than constructor, as page need to have loaded.
      if (this.props.spy && typeof window !== "undefined") {
        this.setupScrollSpy();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.scroller && typeof window !== "undefined") {
        this.scroller.destroy();
        window.removeEventListener("resize", this.resizeScrollSpyDebounced);
      }
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      // This prevents blurring when the next target is within the chapter nav.
      if (this.ref && relatedTarget && this.ref.contains(relatedTarget)) {
        return;
      }

      this.setState({
        expanded: false
      });
    }
  }, {
    key: "handleClick",
    value: function handleClick() {
      this.setState({ expanded: !this.state.expanded });
    }
  }, {
    key: "handleChapterClick",
    value: function handleChapterClick() {
      this.setState({ expanded: false });
    }
  }, {
    key: "handleStepEnter",
    value: function handleStepEnter(_ref) {
      var element = _ref.element;

      this.setState({
        // Use Math.max to ensure we don't crash if element doesn't exist
        currentChapterIndex: Math.max(this.state.chapters.findIndex(function (chapter) {
          return chapter.href === "#" + element.id;
        }), 0)
      });
    }
  }, {
    key: "handleStepExit",
    value: function handleStepExit(_ref2) {
      var direction = _ref2.direction;

      if (direction === "up") {
        this.setState({
          currentChapterIndex: Math.max(0, this.state.currentChapterIndex - 1)
        });
      }
    }
  }, {
    key: "setupScrollSpy",
    value: function setupScrollSpy() {
      this.scroller = (0, _scrollama2.default)();

      var chapterDividerClassName = (0, _utilityClassNames2.default)("LongformChapterDivider")();

      // setup the instance, pass callback functions
      this.scroller.setup({
        step: "." + chapterDividerClassName,
        offset: this.scrollSpyOffset
      }).onStepEnter(this.handleStepEnter).onStepExit(this.handleStepExit);

      window.addEventListener("resize", this.resizeScrollSpyDebounced);
    }
  }, {
    key: "resizeScrollSpy",
    value: function resizeScrollSpy() {
      this.scroller.resize();
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          chapters = _state.chapters,
          currentChapterIndex = _state.currentChapterIndex,
          expanded = _state.expanded;

      var currentChapter = chapters[currentChapterIndex];

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({ states: (0, _classnames2.default)({ expanded: expanded }) }),
          ref: function ref(_ref3) {
            return _this2.ref = _ref3;
          },
          "data-spy": this.props.spy ? JSON.stringify(this.props.spy) : null
        },
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName({ descendantName: "chapterContainer" })
          },
          _react2.default.createElement(
            "button",
            {
              className: this.getClassName({ descendantName: "currentChapter" }),
              onClick: this.handleClick,
              onBlur: this.handleBlur
            },
            currentChapter.prefix ? _react2.default.createElement(
              "span",
              {
                className: this.getClassName({
                  descendantName: "currentChapterPrefix",
                  utilities: "typographySmallCaps"
                })
              },
              currentChapter.prefix
            ) : null,
            _react2.default.createElement(
              "span",
              {
                className: this.getClassName({
                  descendantName: "currentChapterTitle"
                })
              },
              currentChapter.title
            ),
            _react2.default.createElement(
              "span",
              {
                className: this.getClassName({
                  descendantName: "currentChapterIcon"
                })
              },
              expanded ? _react2.default.createElement(_chevronUp2.default, { label: "Collapse", width: 20 }) : _react2.default.createElement(_chevronDown2.default, { label: "Expand", width: 20 })
            )
          ),
          _react2.default.createElement(
            "ol",
            {
              className: this.getClassName({ descendantName: "chapters" }),
              onClick: this.handleChapterClick // Captures bubbled events from links
              , onBlur: this.handleBlur,
              "data-rehydratable-children": true
            },
            chapters.map(function (chapter, i) {
              return _react2.default.createElement(_Chapter2.default, _extends({
                key: i
              }, chapter, {
                active: currentChapterIndex === i
              }));
            })
          )
        )
      );
    }
  }]);

  return ChapterNavInner;
}(_react.Component);

ChapterNavInner.displayName = "Longform.ChapterNavInner";
ChapterNavInner.propTypes = {
  children: _propTypes2.default.node,
  spy: _propTypes2.default.bool
};
exports.default = ChapterNavInner;