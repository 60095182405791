"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Selected = require("./Selected");

var _Selected2 = _interopRequireDefault(_Selected);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Selected2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("SearchFiltersSelected");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "items" }));

    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var rehydratedProps = {
      isRehydrating: true,
      clearAllHref: domNode.getAttribute("data-clear-all-href"),
      clearAllText: domNode.getAttribute("data-clear-all-text"),
      title: domNode.getAttribute("data-title")
    };

    return _react2.default.createElement(
      _Selected2.default,
      rehydratedProps,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;