"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextControl = function TextControl(_ref) {
  var defaultValue = _ref.defaultValue,
      disabled = _ref.disabled,
      hashableIdentifier = _ref.hashableIdentifier,
      id = _ref.id,
      labelText = _ref.labelText,
      name = _ref.name,
      onChange = _ref.onChange,
      required = _ref.required,
      type = _ref.type,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(TextControl.displayName);

  var attributes = {};

  if (hashableIdentifier) {
    attributes["data-hashable-identifier"] = hashableIdentifier;
  }

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "label",
      { className: getClassName({ descendantName: "label" }), htmlFor: id },
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "labelText" }) },
        labelText,
        " ",
        !required && _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "optional" }) },
          "(Optional)"
        )
      ),
      _react2.default.createElement("input", _extends({
        className: getClassName({ descendantName: "input" }),
        defaultValue: defaultValue,
        disabled: disabled,
        id: id,
        name: name,
        onChange: onChange,
        required: required,
        type: type,
        value: value
      }, attributes))
    )
  );
};

TextControl.displayName = "Form.TextControl";

TextControl.types = ["email", "text", "url", "tel"];

TextControl.propTypes = {
  /**
   * Initialize <input> with a value
   */
  defaultValue: _propTypes2.default.string,
  /**
   * Disable the <input>
   */
  disabled: _propTypes2.default.bool,
  /**
   * Mark this field as a hashable identifier - a value which, when hashed, can anonymously identify a user.
   *
   * This field will be picked up and hashed before being put in the data layer.
   */
  hashableIdentifier: _propTypes2.default.bool,
  /**
   * Id attribute for <input> (and <label>'s `for` attribute)
   */
  id: _propTypes2.default.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: _propTypes2.default.string.isRequired,
  /**
   * Name attribute for <input>, required for serialisation
   */
  name: _propTypes2.default.string.isRequired,
  /**
   * Listen for changes on the <input>.
   */
  onChange: _propTypes2.default.func,
  /**
   * Set <input> to be required
   */
  required: _propTypes2.default.bool,
  /**
   * Type of <input>
   */
  type: _propTypes2.default.oneOf(TextControl.types).isRequired,
  /**
   * Explicitly set the value of the <input>. Will switch to controlled mode
   */
  value: _propTypes2.default.string
};

TextControl.defaultProps = { type: "text" };

exports.default = TextControl;