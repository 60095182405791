"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _isOpenContext = require("./isOpenContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ESCAPE_KEY_CODE = 27;

var getFocusableDescendants = function getFocusableDescendants(node) {
  /*
   * this is based on the code provided by:
   * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
   * at `aria.Utils.isFocusable`
   */
  var selector = ["button:not([disabled])", "[href]", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])", "[tabindex]:not([tabindex='-1'])"].join(", ");

  return node.querySelectorAll(selector);
};

var Modal = function (_Component) {
  _inherits(Modal, _Component);

  function Modal(props) {
    _classCallCheck(this, Modal);

    var _this = _possibleConstructorReturn(this, (Modal.__proto__ || Object.getPrototypeOf(Modal)).call(this, props));

    _this.contentRef = _react2.default.createRef();
    _this.dialogRef = _react2.default.createRef();
    _this.ref = _react2.default.createRef();

    _this.appRoot = document.getElementById("root");
    _this.modalRoot = document.getElementById("modalRoot");

    _this.body = document.getElementsByTagName("body")[0];
    _this.el = document.createElement("div");

    _this.getClassName = (0, _utilityClassNames2.default)(Modal.displayName);

    _this.handleClose = _this.handleClose.bind(_this);
    _this.handleFocus = _this.handleFocus.bind(_this);
    _this.handleKeydown = _this.handleKeydown.bind(_this);
    _this.handleOverlayClick = _this.handleOverlayClick.bind(_this);
    return _this;
  }

  _createClass(Modal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      /*
       * The portal element is inserted in the DOM tree after
       * the Modal's children are mounted, meaning that children
       * will be mounted on a detached DOM node. If a child
       * component requires to be attached to the DOM tree
       * immediately when mounted, for example to measure a
       * DOM node, or uses 'autoFocus' in a descendant, add
       * state to Modal and only render the children when Modal
       * is inserted in the DOM tree.
       */
      this.modalRoot.appendChild(this.el);

      /**
       * hide all other page content from screen readers
       * https://www.w3.org/TR/wai-aria-1.0/states_and_properties#aria-hidden
       */
      this.appRoot.setAttribute("aria-hidden", "true");

      this.body.classList.add("is-scrollLocked");

      document.addEventListener("focus", this.handleFocus, true);
      document.addEventListener("keydown", this.handleKeydown, false);

      this.lastFocusEl = this.contentRef;
      this.contentRef.current.focus();

      // close all other modals, and add this instance to the list
      Modal.mountedModals.map(function (e) {
        return e.handleClose();
      });
      Modal.mountedModals.push(this);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      var _this2 = this;

      this.modalRoot.removeChild(this.el);

      this.appRoot.removeAttribute("aria-hidden");

      this.body.classList.remove("is-scrollLocked");

      document.removeEventListener("focus", this.handleFocus, true);
      document.removeEventListener("keydown", this.handleKeydown, false);

      // remove this instance from the list of mounted modals
      Modal.mountedModals = Modal.mountedModals.filter(function (e) {
        return e !== _this2;
      });
    }
  }, {
    key: "handleClose",
    value: function handleClose(event) {
      if (event) {
        event.stopPropagation();
      }

      this.props.closeThisComponent();
    }
  }, {
    key: "handleKeydown",
    value: function handleKeydown(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        this.handleClose();
      }
    }
  }, {
    key: "handleOverlayClick",
    value: function handleOverlayClick(event) {
      // close if target element is the overlay, not the dialog
      if (event.target === this.ref.current) {
        this.handleClose(event);
      }
    }

    /*
     * this is based on the code provided by:
     * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
     * at `aria.Dialog.prototype.trapFocus`
     */

  }, {
    key: "handleFocus",
    value: function handleFocus(event) {
      var isTargetWithinModal = this.dialogRef.current.contains(event.target);

      if (isTargetWithinModal) {
        this.lastFocusEl = event.target;

        return;
      }

      var focusableDialogDescendants = getFocusableDescendants(this.dialogRef.current);

      if (!focusableDialogDescendants.length) {
        this.contentRef.focus();

        return;
      }

      if (this.lastFocusEl === focusableDialogDescendants[0]) {
        focusableDialogDescendants[focusableDialogDescendants.length - 1].focus();
      } else {
        focusableDialogDescendants[0].focus();
      }

      this.lastFocusEl = document.activeElement;
    }
  }, {
    key: "render",
    value: function render() {
      /*
       * Bracket the dialog node with two invisible, focusable nodes.
       * While this dialog is open, we use these to make sure that focus never
       * leaves the document even if dialogNode is the first or last node.
       *
       * following example of:
       * https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
       * see: `aria.Dialog` constructor
       */

      return _reactDom2.default.createPortal(_react2.default.createElement(
        _isOpenContext.Provider,
        { value: true },
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName(),
            onClick: this.handleOverlayClick,
            ref: this.ref
          },
          _react2.default.createElement("div", {
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex: "0"
          }),
          _react2.default.createElement(
            "div",
            {
              "aria-label": this.props.ariaLabel,
              "aria-modal": true,
              className: this.getClassName({ descendantName: "dialog" }),
              role: "dialog",
              ref: this.dialogRef
            },
            _react2.default.createElement(
              "div",
              { className: this.getClassName({ descendantName: "header" }) },
              _react2.default.createElement(
                "div",
                {
                  className: this.getClassName({ descendantName: "headerOuter" })
                },
                _react2.default.createElement(
                  "div",
                  {
                    className: this.getClassName({
                      descendantName: "headerInner"
                    })
                  },
                  _react2.default.createElement(
                    "button",
                    {
                      "aria-label": "close",
                      className: this.getClassName({ descendantName: "button" }),
                      onClick: this.handleClose
                    },
                    _react2.default.createElement(
                      "span",
                      {
                        className: this.getClassName({
                          descendantName: "buttonLabel",
                          utilities: "typographySmallCaps"
                        })
                      },
                      this.props.closeLabel
                    ),
                    _react2.default.createElement(
                      "span",
                      {
                        className: this.getClassName({
                          descendantName: "buttonCross"
                        })
                      },
                      _react2.default.createElement(_cross2.default, null)
                    )
                  )
                )
              )
            ),
            _react2.default.createElement(
              "div",
              {
                className: this.getClassName({ descendantName: "content" }),
                ref: this.contentRef
              },
              _react2.default.createElement(
                "div",
                {
                  className: this.getClassName({
                    descendantName: "contentOuter"
                  })
                },
                _react2.default.createElement(
                  "div",
                  {
                    className: this.getClassName({
                      descendantName: "contentInner"
                    })
                  },
                  this.props.children
                )
              )
            )
          ),
          _react2.default.createElement("div", {
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex: "0"
          })
        )
      ), this.el);
    }
  }]);

  return Modal;
}(_react.Component);

Modal.displayName = "Modal";
Modal.mountedModals = [];
Modal.propTypes = {
  /**
   * Modal children
   */
  children: _propTypes2.default.node,

  /**
   * Aria label for modal dialog
   */
  ariaLabel: _propTypes2.default.string.isRequired,

  /**
   * label for the modal close button
   */
  closeLabel: _propTypes2.default.string,

  /**
   * Close this component callback
   * modal must closed by parent, this function is called when a modal
   * is forced to close either by another event or the user has chosen
   * to close it.
   */
  closeThisComponent: _propTypes2.default.func.isRequired
};
exports.default = Modal;