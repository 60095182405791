"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

var _componentImage = require("@wel-ui/component-image");

var _componentImage2 = _interopRequireDefault(_componentImage);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ChapterDivider = function ChapterDivider(_ref) {
  var backgroundAnchor = _ref.backgroundAnchor,
      backgroundGradient = _ref.backgroundGradient,
      backgroundSrc = _ref.backgroundSrc,
      backgroundSrcSet = _ref.backgroundSrcSet,
      chapterText = _ref.chapterText,
      colorTheme = _ref.colorTheme,
      id = _ref.id,
      titleText = _ref.titleText;

  var getClassName = (0, _utilityClassNames2.default)("LongformChapterDivider");

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: colorTheme }), id: id },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "background" }) },
      _react2.default.createElement(
        "div",
        { className: getClassName({ descendantName: "backgroundImage" }) },
        backgroundSrc ? _react2.default.createElement(_componentImage2.default, {
          alt: "",
          anchor: backgroundAnchor,
          fit: "cover",
          sizes: _componentImage2.default.sizes.edgeToEdge,
          src: backgroundSrc,
          srcSet: backgroundSrcSet
        }) : null
      )
    ),
    backgroundSrc && backgroundGradient ? _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "background" }) },
      _react2.default.createElement("div", {
        className: getClassName({ descendantName: "backgroundGradient" })
      })
    ) : null,
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "content" }) },
      _react2.default.createElement(
        "div",
        { className: getClassName({ descendantName: "inner" }) },
        chapterText && _react2.default.createElement(
          "div",
          {
            className: getClassName({
              descendantName: "chapter",
              utilities: "textAntialiased"
            })
          },
          _react2.default.createElement(_componentEyebrow2.default, { text: chapterText })
        ),
        _react2.default.createElement(
          "h2",
          { className: getClassName({ descendantName: "title" }) },
          titleText
        )
      )
    )
  );
};

/* eslint-disable max-len */
ChapterDivider.propTypes = {
  /**
   * Anchor point for the background image.
   */
  backgroundAnchor: _propTypes2.default.oneOf(_componentImage.anchorPoints),

  /**
   * A backgroundGradient to apply over the background image. Should not be used if the image has a rasterized backgroundGradient.
   */
  backgroundGradient: _propTypes2.default.bool,

  /**
   * Background image `src`. Used if srcSet is not defined, or as a fallback.
   */
  backgroundSrc: _propTypes2.default.string,

  /**
   * A comma-separated list indicating a set of possible image sources for the background. The image will be fit to cover. This is used by the user agent to determine the correct image for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  backgroundSrcSet: _propTypes2.default.string,

  /**
   * Used for the chapter text of this section
   */
  chapterText: _propTypes2.default.string,

  /**
   * Color theme. This is the color of the text, not the asset.
   */
  colorTheme: _propTypes2.default.oneOf(["dark", "light"]),

  /**
   * ID for the element. Use with ChapterNav.
   */
  id: _propTypes2.default.string,

  /**
   * Used for the title of this section
   */
  titleText: _propTypes2.default.string.isRequired
};
/* eslint-enable max-len */

ChapterDivider.defaultProps = {
  backgroundAnchor: "C",
  backgroundGradient: true,
  colorTheme: "light"
};

ChapterDivider.displayName = "Longform.ChapterDivider";

exports.default = ChapterDivider;