"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Eyebrow = require("./Eyebrow");

var _Eyebrow2 = _interopRequireDefault(_Eyebrow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Eyebrow2.default;