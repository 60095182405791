"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Panel = require("./Panel");

var _Panel2 = _interopRequireDefault(_Panel);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Panel2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var childrenNode = domNode.querySelector("[data-rehydratable-children]");
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var rehydratedProps = {
      isRehydrating: true,
      tabTitle: domNode.getAttribute("data-tab-title"),
      id: domNode.getAttribute("id")
    };

    return _react2.default.createElement(
      _Panel2.default,
      rehydratedProps,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;