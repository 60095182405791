"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Svg = function Svg(_ref) {
  var children = _ref.children,
      height = _ref.height,
      label = _ref.label,
      width = _ref.width;

  var getClassName = (0, _utilityClassNames2.default)(Svg.displayName);

  var baseFontSize = 16;

  /* eslint-disable no-undefined */
  var svg = _react2.default.cloneElement(children, {
    "aria-hidden": label ? undefined : true,
    "aria-label": label,
    role: label ? "img" : undefined
  });
  /* eslint-enable no-undefined */

  var svgProps = { className: getClassName() };
  var innerProps = { className: getClassName({ descendantName: "inner" }) };

  if (width) {
    svgProps.style = { width: width / baseFontSize + "rem" };
  }

  /* eslint-disable no-magic-numbers */
  if (height && width) {
    innerProps.style = { paddingBottom: height / width * 100 + "%" };
  }
  /* eslint-enable no-magic-numbers */

  return _react2.default.createElement(
    "span",
    svgProps,
    _react2.default.createElement(
      "span",
      innerProps,
      svg
    )
  );
};

Svg.displayName = "Svg";

Svg.propTypes = {
  /**
   * A single SVG element
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Height of the SVG, in pixels
   */
  height: _propTypes2.default.number,
  /**
   * Accessible alternative text
   */
  label: _propTypes2.default.string,
  /**
   * Width of the SVG, in pixels
   */
  width: _propTypes2.default.number
};

exports.default = Svg;