"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _SocialDropdown = require("./SocialDropdown");

var _SocialDropdown2 = _interopRequireDefault(_SocialDropdown);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)("SocialDropdown");

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_SocialDropdown2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "items" }));

    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var props = {
      isRehydrating: true
    };

    return _react2.default.createElement(
      _SocialDropdown2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;