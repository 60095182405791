"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _video = require("@wel-ui/icons/lib/ui/video");

var _video2 = _interopRequireDefault(_video);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VideoCardFooter = function VideoCardFooter(_ref) {
  var runningTime = _ref.runningTime;

  var getClassName = (0, _utilityClassNames2.default)(VideoCardFooter.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "icon" }) },
      _react2.default.createElement(_video2.default, null)
    ),
    runningTime ? _react2.default.createElement(
      "time",
      null,
      runningTime
    ) : null
  );
};

VideoCardFooter.displayName = "VideoCardFooter";

/* eslint-disable max-len */
VideoCardFooter.propTypes = {
  /**
   * Running time for the content, if relevant. Use SI and common non-SI format (English: s = seconds, min = minutes, h = hours, d = days, i.e. "15 min" or "1 h". See https://en.wikipedia.org/wiki/International_System_of_Units#Units_and_prefixes.)
   */
  runningTime: _propTypes2.default.string
};
/* eslint-enable max-len */

VideoCardFooter.defaultProps = {};

exports.default = VideoCardFooter;