"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TwitterCardTimeline = require("./TwitterCardTimeline");

var _TwitterCardTimeline2 = _interopRequireDefault(_TwitterCardTimeline);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_TwitterCardTimeline2.default);