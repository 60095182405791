"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _GroupItem = require("./GroupItem");

var _GroupItem2 = _interopRequireDefault(_GroupItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _GroupItem2.default;