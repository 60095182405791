"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Eyebrow = function Eyebrow(_ref) {
  var text = _ref.text;

  var getClassName = (0, _utilityClassNames2.default)(Eyebrow.displayName);

  return _react2.default.createElement(
    "p",
    { className: getClassName() + " u-typographySmallCaps" },
    text
  );
};

Eyebrow.displayName = "Eyebrow";

/* eslint-disable max-len */
Eyebrow.propTypes = {
  /**
   * The text to display in the eyebrow.
   */
  text: _propTypes2.default.string.isRequired
};
/* eslint-enable max-len */

exports.default = Eyebrow;