"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _componentSearchInput = require("@wel-ui/component-search-input");

var _componentSearchInput2 = _interopRequireDefault(_componentSearchInput);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SiteFooterSearch = function SiteFooterSearch(_ref) {
  var getClassName = _ref.getClassName,
      searchFormAction = _ref.searchFormAction,
      searchFormMethod = _ref.searchFormMethod,
      searchInputName = _ref.searchInputName,
      typeaheadUrl = _ref.typeaheadUrl;

  return _react2.default.createElement(
    "form",
    {
      className: getClassName({ descendantName: "search" }),
      action: searchFormAction,
      method: searchFormMethod,
      role: "search"
    },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "searchInner" }) },
      _react2.default.createElement(_componentSearchInput2.default, {
        colorTheme: "dark",
        name: searchInputName,
        typeaheadUrl: typeaheadUrl
      })
    )
  );
};

SiteFooterSearch.displayName = "SiteFooterSearch";

SiteFooterSearch.propTypes = {
  getClassName: _propTypes2.default.func,
  searchFormAction: _propTypes2.default.string,
  searchFormMethod: _propTypes2.default.string,
  searchInputName: _propTypes2.default.string,
  typeaheadUrl: _propTypes2.default.string
};

exports.default = SiteFooterSearch;