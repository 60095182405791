"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormOnSubmitSuccessContextProvider = exports.formRehydrator = exports.privacyControlRehydrator = exports.campaignControlRehydrator = undefined;

var _Form = require("./Form");

var _Form2 = _interopRequireDefault(_Form);

var _CampaignControl = require("./components/CampaignControl");

var _PrivacyControl = require("./components/PrivacyControl");

var _formOnSubmitSuccessContext = require("./formOnSubmitSuccessContext");

var _Form3 = require("./Form.rehydrator");

var _Form4 = _interopRequireDefault(_Form3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableForm = (0, _componentRehydratable2.default)(_Form2.default);

RehydratableForm.CampaignControl = _Form2.default.CampaignControl;
RehydratableForm.CheckboxControl = _Form2.default.CheckboxControl;
RehydratableForm.Fieldset = _Form2.default.Fieldset;
RehydratableForm.PrivacyControl = _Form2.default.PrivacyControl;
RehydratableForm.RadioControl = _Form2.default.RadioControl;
RehydratableForm.SelectControl = _Form2.default.SelectControl;
RehydratableForm.TextareaControl = _Form2.default.TextareaControl;
RehydratableForm.TextControl = _Form2.default.TextControl;

exports.default = RehydratableForm;
exports.campaignControlRehydrator = _CampaignControl.campaignControlRehydrator;
exports.privacyControlRehydrator = _PrivacyControl.privacyControlRehydrator;
exports.formRehydrator = _Form4.default;
exports.FormOnSubmitSuccessContextProvider = _formOnSubmitSuccessContext.Provider;