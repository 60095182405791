"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageNavItemRehydrator = exports.pageNavRehydrator = undefined;

var _PageNav = require("./PageNav");

var _PageNav2 = _interopRequireDefault(_PageNav);

var _PageNavInner = require("./PageNavInner.rehydrator");

var _PageNavInner2 = _interopRequireDefault(_PageNavInner);

var _Item = require("./components/Item/Item.rehydrator");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PageNav2.default;
exports.pageNavRehydrator = _PageNavInner2.default;
exports.pageNavItemRehydrator = _Item2.default;