"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxControl = function CheckboxControl(_ref) {
  var checked = _ref.checked,
      defaultChecked = _ref.defaultChecked,
      disabled = _ref.disabled,
      id = _ref.id,
      labelText = _ref.labelText,
      name = _ref.name,
      onChange = _ref.onChange,
      required = _ref.required,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(CheckboxControl.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "label",
      { className: getClassName({ descendantName: "label" }), htmlFor: id },
      _react2.default.createElement("input", {
        className: getClassName({ descendantName: "input" }),
        checked: checked,
        defaultChecked: defaultChecked,
        disabled: disabled,
        id: id,
        name: name,
        onChange: onChange,
        required: required,
        type: "checkbox",
        value: value
      }),
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "labelText" }) },
        labelText,
        !required && _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "optional" }) },
          "(Optional)"
        )
      )
    )
  );
};

CheckboxControl.displayName = "Form.CheckboxControl";

CheckboxControl.propTypes = {
  /**
   * Check the <input>. Will switch into controlled mode.
   */
  checked: _propTypes2.default.bool,
  /**
   * Set the initial checked state for the <input>.
   */
  defaultChecked: _propTypes2.default.bool,
  /**
   * Disable the <input>
   */
  disabled: _propTypes2.default.bool,
  /**
   * Id attribute for <input> (and <label>'s `for` attribute)
   */
  id: _propTypes2.default.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: _propTypes2.default.string.isRequired,
  /**
   * Name attribute for <input>, required for serialisation
   */
  name: _propTypes2.default.string,
  /**
   * Add an onChange callback to the <input>.
   */
  onChange: _propTypes2.default.func,
  /**
   * Set <input> to be required
   */
  required: _propTypes2.default.bool,
  /**
   * Initialize <input> with a value
   */
  value: _propTypes2.default.string
};

exports.default = CheckboxControl;