"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _RadioControl = require("./RadioControl");

var _RadioControl2 = _interopRequireDefault(_RadioControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _RadioControl2.default;