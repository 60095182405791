"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ConsoleAnalytics = function () {
  // eslint-disable-next-line no-console
  function ConsoleAnalytics() {
    var logger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : console.log;

    _classCallCheck(this, ConsoleAnalytics);

    this.logger = logger;
  }

  _createClass(ConsoleAnalytics, [{
    key: "sendEvent",
    value: function sendEvent(category, action, label) {
      this.logger({
        category: category,
        action: action,
        label: label
      });
    }
  }]);

  return ConsoleAnalytics;
}();

exports.default = ConsoleAnalytics;