"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var PageItem = function PageItem(_ref) {
  var active = _ref.active,
      children = _ref.children,
      href = _ref.href,
      onClick = _ref.onClick,
      rest = _objectWithoutProperties(_ref, ["active", "children", "href", "onClick"]);

  var getClassName = (0, _utilityClassNames2.default)(PageItem.displayName);

  var InnerName = "span";
  var clickable = false;

  var innerProps = {};

  if (active) {
    innerProps["aria-current"] = "true";
    innerProps["aria-label"] = "Current Page, Page " + children;
  } else if (href) {
    InnerName = "a";
    innerProps.href = href;
    innerProps["aria-current"] = "false";
    innerProps["aria-label"] = "Go to Page " + children;
    clickable = true;
  } else if (onClick) {
    InnerName = "button";
    innerProps.onClick = onClick;
    clickable = true;
  }

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({
        states: (0, _classnames2.default)({ active: active })
      })
    },
    _react2.default.createElement(
      InnerName,
      _extends({
        className: getClassName({
          descendantName: "inner",
          states: (0, _classnames2.default)({ clickable: clickable })
        })
      }, innerProps, rest),
      _react2.default.createElement(
        "span",
        {
          className: getClassName({
            descendantName: "innerText"
          })
        },
        children
      )
    )
  );
};

PageItem.displayName = "Pagination.PageItem";

/* eslint-disable max-len */
PageItem.propTypes = {
  /**
   * Flag the page as active. Will disable the link.
   */
  active: _propTypes2.default.bool,

  /**
   * Page number.
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * Page URL.
   */
  href: _propTypes2.default.string,

  /**
   * On click event handler.
   */
  onClick: _propTypes2.default.func
};
/* eslint-enable max-len */

PageItem.defaultProps = {
  active: false
};

exports.default = PageItem;