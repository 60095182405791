"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _CardBase = require("../../bases/CardBase");

var _CardBase2 = _interopRequireDefault(_CardBase);

var _componentEyebrow = require("@wel-ui/component-eyebrow");

var _componentEyebrow2 = _interopRequireDefault(_componentEyebrow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var QuoteCard = function QuoteCard(_ref) {
  var attribution = _ref.attribution,
      borderless = _ref.borderless,
      eyebrow = _ref.eyebrow,
      href = _ref.href,
      text = _ref.text;

  var getClassName = (0, _utilityClassNames2.default)(QuoteCard.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      _CardBase2.default,
      { borderless: borderless, href: href, size: "large" },
      _react2.default.createElement(
        "div",
        { className: getClassName({ descendantName: "content" }) },
        eyebrow ? _react2.default.createElement(
          "div",
          {
            className: getClassName({
              descendantName: "eyebrow"
            })
          },
          _react2.default.createElement(_componentEyebrow2.default, { text: eyebrow })
        ) : null,
        _react2.default.createElement(
          "blockquote",
          { className: getClassName({ descendantName: "quote" }) },
          text
        ),
        _react2.default.createElement(
          "div",
          { className: getClassName({ descendantName: "attribution" }) },
          attribution
        )
      )
    )
  );
};

QuoteCard.displayName = "QuoteCard";

QuoteCard.sizes = _CardBase2.default.sizes;

/* eslint-disable max-len */
QuoteCard.propTypes = {
  /**
   * Attribution component for attributing the quotation
   */
  attribution: _propTypes2.default.node.isRequired,
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Eyebrow text
   */
  eyebrow: _propTypes2.default.string,
  /**
   * Card URL
   */
  href: _propTypes2.default.string,
  /**
   * The quotation
   */
  text: _propTypes2.default.string.isRequired
};
/* eslint-enable max-len */

QuoteCard.defaultProps = {
  borderless: false,
  size: "large"
};

exports.default = QuoteCard;