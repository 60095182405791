"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _reactInputAutosize = require("react-input-autosize");

var _reactInputAutosize2 = _interopRequireDefault(_reactInputAutosize);

var _MultiDownshift = require("../MultiDownshift");

var _MultiDownshift2 = _interopRequireDefault(_MultiDownshift);

var _utilities = require("./utilities");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MultiSelectDropdown = function (_React$Component) {
  _inherits(MultiSelectDropdown, _React$Component);

  function MultiSelectDropdown() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MultiSelectDropdown);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MultiSelectDropdown.__proto__ || Object.getPrototypeOf(MultiSelectDropdown)).call.apply(_ref, [this].concat(args))), _this), _this.input = _react2.default.createRef(), _this.state = {
      hasFocus: false
    }, _this.getClassName = (0, _utilityClassNames2.default)(MultiSelectDropdown.displayName), _this.itemToString = function (item) {
      return item ? item.value : "";
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MultiSelectDropdown, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          handleChange = _props.handleChange,
          name = _props.name,
          noOptionsMessage = _props.noOptionsMessage,
          options = _props.options,
          placeholder = _props.placeholder;
      var hasFocus = this.state.hasFocus;


      return _react2.default.createElement(
        _MultiDownshift2.default,
        { onChange: handleChange, itemToString: this.itemToString },
        function (_ref2) {
          var getInputProps = _ref2.getInputProps,
              getToggleButtonProps = _ref2.getToggleButtonProps,
              getMenuProps = _ref2.getMenuProps,
              getRemoveButtonProps = _ref2.getRemoveButtonProps,
              removeItem = _ref2.removeItem,
              isOpen = _ref2.isOpen,
              inputValue = _ref2.inputValue,
              selectedItems = _ref2.selectedItems,
              getItemProps = _ref2.getItemProps,
              highlightedIndex = _ref2.highlightedIndex,
              toggleMenu = _ref2.toggleMenu;

          var dropdownOnClickKeypressHandler = function dropdownOnClickKeypressHandler() {
            toggleMenu();
            if (!isOpen) {
              _this2.input.current.focus();
            }
          };

          return _react2.default.createElement(
            "div",
            { className: _this2.getClassName() },
            _react2.default.createElement(
              "div",
              {
                className: _this2.getClassName({
                  descendantName: "dropdown",
                  states: (0, _classnames2.default)({
                    open: isOpen,
                    focus: hasFocus,
                    populated: selectedItems.length > 0
                  })
                }),
                onClick: dropdownOnClickKeypressHandler,
                onKeyPress: dropdownOnClickKeypressHandler,
                role: "listbox",
                "data-testid": "dropdown"
              },
              _react2.default.createElement(
                "div",
                {
                  className: _this2.getClassName({
                    descendantName: "inner"
                  })
                },
                selectedItems.length > 0 || inputValue ? selectedItems.map(function (item) {
                  return _react2.default.createElement(
                    "div",
                    {
                      key: item.value,
                      className: _this2.getClassName({
                        descendantName: "multiValue"
                      }),
                      "data-testid": "multiValue"
                    },
                    _react2.default.createElement(
                      "div",
                      {
                        className: _this2.getClassName({
                          descendantName: "multiValueInner"
                        })
                      },
                      _react2.default.createElement(
                        "button",
                        getRemoveButtonProps({
                          item: item,
                          className: _this2.getClassName({
                            descendantName: "multiValueClose"
                          }),
                          "data-testid": "multiValueClose"
                        }),
                        _react2.default.createElement(_cross2.default, { height: 18, width: 18 })
                      ),
                      _react2.default.createElement(
                        "span",
                        {
                          className: _this2.getClassName({
                            descendantName: "multiValueLabel"
                          })
                        },
                        item.label
                      )
                    )
                  );
                }) : _react2.default.createElement(
                  "span",
                  {
                    className: _this2.getClassName({
                      descendantName: "placeholder"
                    })
                  },
                  placeholder
                ),
                _react2.default.createElement(_reactInputAutosize2.default, getInputProps({
                  ref: _this2.input,
                  onKeyUp: function onKeyUp(event) {
                    if (event.key === "Backspace" && !inputValue) {
                      removeItem(selectedItems[selectedItems.length - 1]);
                    }
                  },
                  onFocus: function onFocus() {
                    return _this2.setState({ hasFocus: true });
                  },
                  onBlur: function onBlur() {
                    return _this2.setState({ hasFocus: false });
                  },
                  inputClassName: _this2.getClassName({
                    descendantName: "input"
                  }),
                  "data-input": "autosize",
                  "data-testid": "textInput",
                  id: name,
                  value: inputValue || ""
                }))
              ),
              _react2.default.createElement(
                "button",
                _extends({}, getToggleButtonProps({
                  // prevents the menu from immediately toggling
                  // closed (due to our custom click handler above).
                  onClick: function onClick(event) {
                    event.stopPropagation();
                  }
                }), {
                  className: _this2.getClassName({
                    descendantName: "controllerButton"
                  }),
                  "data-testid": "controllerButton"
                }),
                _react2.default.createElement(
                  "span",
                  {
                    className: _this2.getClassName({
                      descendantName: "controllerArrow",
                      states: (0, _classnames2.default)({ open: isOpen })
                    })
                  },
                  _react2.default.createElement(_chevronDown2.default, { height: 28, width: 28 })
                )
              )
            ),
            _react2.default.createElement(
              "ul",
              _extends({}, getMenuProps(), {
                className: _this2.getClassName({
                  descendantName: "menu",
                  states: (0, _classnames2.default)({ open: isOpen })
                }),
                "data-testid": "menu"
              }),
              isOpen ? function () {
                var items = (0, _utilities.getItems)({ options: options, filter: inputValue });

                return items.length ? items.map(function (item, index) {
                  return _react2.default.createElement(
                    "li",
                    _extends({
                      key: item.value
                    }, getItemProps({
                      item: item,
                      index: index
                    }), {
                      className: _this2.getClassName({
                        descendantName: "item",
                        states: (0, _classnames2.default)({
                          active: highlightedIndex === index,
                          selected: selectedItems.some(function (i) {
                            return _this2.itemToString(i) === _this2.itemToString(item);
                          })
                        })
                      }),
                      "data-testid": "menuItem"
                    }),
                    item.label
                  );
                }) : _react2.default.createElement(
                  "li",
                  {
                    className: _this2.getClassName({
                      descendantName: "noOptionsMessage"
                    }),
                    "data-testid": "noOptionsMessage"
                  },
                  noOptionsMessage
                );
              }() : null
            )
          );
        }
      );
    }
  }]);

  return MultiSelectDropdown;
}(_react2.default.Component);

MultiSelectDropdown.displayName = "MultiSelectDropdown";

MultiSelectDropdown.propTypes = {
  /**
   * On change event handler
   */
  handleChange: _propTypes2.default.func.isRequired,

  /**
   * Text input name/id
   */
  name: _propTypes2.default.string.isRequired,

  /**
   * Message displayed in the filter if there is no option resulting from the typeahead input
   */
  noOptionsMessage: _propTypes2.default.string.isRequired,

  /**
   * Data to generate listed options
   */
  options: _propTypes2.default.arrayOf(_propTypes2.default.shape({
    label: _propTypes2.default.string,
    value: _propTypes2.default.string
  })),

  /**
   * Placeholder for the filter input
   */
  placeholder: _propTypes2.default.string.isRequired
};

exports.default = MultiSelectDropdown;