"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _TableWithFilters = require("./TableWithFilters");

var _TableWithFilters2 = _interopRequireDefault(_TableWithFilters);

var _TableWithFilters3 = require("./TableWithFilters.rehydrator");

var _TableWithFilters4 = _interopRequireDefault(_TableWithFilters3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_TableWithFilters2.default);
exports.rehydrator = _TableWithFilters4.default;