"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _HorizontalOverflow = require("./HorizontalOverflow");

var _HorizontalOverflow2 = _interopRequireDefault(_HorizontalOverflow);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_HorizontalOverflow2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var childrenNode = domNode.querySelector("[data-rehydratable-children]");
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var props = {
      isRehydrating: true
    };

    var background = domNode.getAttribute("data-background");

    if (background) {
      props.background = background;
    }

    return _react2.default.createElement(
      _HorizontalOverflow2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;