"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _EMCMLoginServiceConfiguration = require("./EMCMLoginServiceConfiguration");

var _EMCMLoginServiceConfiguration2 = _interopRequireDefault(_EMCMLoginServiceConfiguration);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _class = function (_EMCMLoginServiceConf) {
  _inherits(_class, _EMCMLoginServiceConf);

  function _class() {
    _classCallCheck(this, _class);

    var _this = _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).call(this));

    _this.emcm.authPageLocation = "https://tax-tr-com-ppe-ams.ewp.thomsonreuters.com/auth";
    _this.emcm.location = "/bin/ewp";
    _this.estore.location = "https://store.tax.thomsonreuters.com/accounting";
    _this.identityService.clientId = "trtaewp-ppe";
    _this.identityService.location = "https://mytr-id-nonprod-identity-int.thomsonreuters.com";
    _this.onepass.location = "https://signon.qa.thomsonreuters.com/v2";
    _this.onepass.productId = "MKTTAXEWP";
    _this.transferIframeLocations.push("https://store.tax.thomsonreuters.com/accounting/login?transfertoken=");
    _this.transferIframeLocations.push("https://site.tax.thomsonreuters.com/federatedSSO.php?transfertoken=");
    _this.signoutIframeLocations.push("https://store.tax.thomsonreuters.com/accounting/logout");
    _this.signoutIframeLocations.push("https://site.tax.thomsonreuters.com/?signout=true");
    return _this;
  }

  return _class;
}(_EMCMLoginServiceConfiguration2.default);

exports.default = _class;