"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

var _Item = require("./Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableItem = (0, _componentRehydratable2.default)(_Item2.default);

RehydratableItem.Header = _Item2.default.Header;

exports.default = RehydratableItem;