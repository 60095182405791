"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _DropdownItem = require("./DropdownItem");

var _DropdownItem2 = _interopRequireDefault(_DropdownItem);

var _DropdownHeader = require("./DropdownHeader");

var _DropdownHeader2 = _interopRequireDefault(_DropdownHeader);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var itemsArrayShape = {
  href: _propTypes2.default.string,
  value: _propTypes2.default.any,
  label: _propTypes2.default.string.isRequried
};

var Dropdown = function (_Component) {
  _inherits(Dropdown, _Component);

  /* eslint-disable max-len */
  function Dropdown(props) {
    _classCallCheck(this, Dropdown);

    var _this = _possibleConstructorReturn(this, (Dropdown.__proto__ || Object.getPrototypeOf(Dropdown)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(Dropdown.displayName);

    _this.state = {
      expanded: false,
      value: _this.props.value
    };

    _this.collapse = _this.collapse.bind(_this);
    _this.getItemForCurrentValue = _this.getItemForCurrentValue.bind(_this);
    _this.maybeCollapse = _this.maybeCollapse.bind(_this);
    _this.onItemClicked = _this.onItemClicked.bind(_this);
    _this.renderHeader = _this.renderHeader.bind(_this);
    _this.toggleExpand = _this.toggleExpand.bind(_this);
    return _this;
  }
  /* eslint-enable max-len */

  _createClass(Dropdown, [{
    key: "collapse",
    value: function collapse() {
      this.setState({ expanded: false });
    }

    // Collapse if next focus target is not a menu item (i.e. itemInner)

  }, {
    key: "maybeCollapse",
    value: function maybeCollapse(event) {
      // document.activeElement is IE11 fallback
      var relatedTarget = event.relatedTarget || document.activeElement;

      if (typeof event === "undefined" || typeof relatedTarget === "undefined" || relatedTarget === null || relatedTarget.className.indexOf(this.getClassName({ descendantName: "itemInner" })) < 0) {
        this.collapse();
      }
    }
  }, {
    key: "getItemForCurrentValue",
    value: function getItemForCurrentValue() {
      var _this2 = this;

      var value = this.state.value || this.props.value;

      if (typeof value === "undefined" || value === null) {
        return;
      }

      return this.props.items.find(function (item) {
        return item.value === (_this2.state.value || _this2.props.value);
      });
    }
  }, {
    key: "onItemClicked",
    value: function onItemClicked(item) {
      this.setState({
        expanded: false,
        value: item.value
      });

      if (this.props.onValueChanged) {
        this.props.onValueChanged(item.value);
      }
    }
  }, {
    key: "toggleExpand",
    value: function toggleExpand() {
      this.setState({ expanded: !this.state.expanded });
    }
  }, {
    key: "renderHeader",
    value: function renderHeader(text, expanded, toggleExpand) {
      return _react2.default.createElement(_DropdownHeader2.default, {
        text: text,
        expanded: expanded,
        toggleExpand: toggleExpand,
        getClassName: this.getClassName
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          block = _props.block,
          items = _props.items,
          _props$onItemClicked = _props.onItemClicked,
          onItemClicked = _props$onItemClicked === undefined ? this.onItemClicked : _props$onItemClicked,
          placeholder = _props.placeholder,
          _props$renderHeader = _props.renderHeader,
          renderHeader = _props$renderHeader === undefined ? this.renderHeader : _props$renderHeader,
          size = _props.size,
          value = _props.value;
      var expanded = this.state.expanded;


      var currentItem = this.getItemForCurrentValue();
      var label = void 0;

      if (currentItem) {
        label = currentItem.label;
      } else {
        label = placeholder;
      }

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            modifiers: (0, _classnames2.default)(_defineProperty({
              block: block
            }, size, true)),
            states: (0, _classnames2.default)({
              expanded: expanded
            })
          }),
          onBlur: this.maybeCollapse
          // Rehyratable props
          , "data-block": JSON.stringify(block),
          "data-items": JSON.stringify(items),
          "data-placeholder": JSON.stringify(placeholder),
          "data-size": JSON.stringify(size),
          "data-value": JSON.stringify(value)
        },
        renderHeader(label, expanded, this.toggleExpand),
        _react2.default.createElement(
          "ul",
          { className: this.getClassName({ descendantName: "items" }) },
          items.map(function (item, i) {
            return _react2.default.createElement(_DropdownItem2.default, {
              getClassName: _this3.getClassName,
              item: item,
              key: i,
              onItemClicked: onItemClicked,
              onBlur: _this3.maybeCollapse
            });
          })
        )
      );
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.value !== prevState.value) {
        return { value: nextProps.value };
      }

      return null;
    }
  }]);

  return Dropdown;
}(_react.Component);

Dropdown.displayName = "Dropdown";
Dropdown.propTypes = {
  /**
   * Render the Dropdown with 100% width.
   */
  block: _propTypes2.default.bool,
  /**
   * Array of dropdown items. Items can contain value or href, not both.
   */
  items: _propTypes2.default.arrayOf(_propTypes2.default.shape(itemsArrayShape)).isRequired,
  /**
   * Method override for clicking the items. Receives an `item` arguement.
   */
  onItemClicked: _propTypes2.default.func,
  /**
   * Callback, called when value changes (unless set by property). Receives a `value` arguement.
   */
  onValueChanged: _propTypes2.default.func,
  /**
   * Placeholder
   */
  placeholder: _propTypes2.default.string.isRequired,
  /**
   * Method to override the header of the dropdown. Useful for reusing the dropdown elsewhere. Receives `text`, `expanded` and `toggleExpand()` arguements.
   */
  renderHeader: _propTypes2.default.func,
  /**
   * Size
   */
  size: _propTypes2.default.oneOf(["large", "small"]),
  /**
   * Value of the selected item. Will always override the existing value when set.
   */
  value: _propTypes2.default.any
};
Dropdown.defaultProps = {
  block: true,
  size: "large"
};
exports.default = Dropdown;