"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentSearchInput = require("@wel-ui/component-search-input");

var _componentSearchInput2 = _interopRequireDefault(_componentSearchInput);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _hamburger = require("@wel-ui/icons/lib/ui/hamburger");

var _hamburger2 = _interopRequireDefault(_hamburger);

var _search = require("@wel-ui/icons/lib/ui/search");

var _search2 = _interopRequireDefault(_search);

var _Column = require("./components/Column");

var _Column2 = _interopRequireDefault(_Column);

var _FeaturedItem = require("./components/FeaturedItem");

var _FeaturedItem2 = _interopRequireDefault(_FeaturedItem);

var _Flyouts = require("./components/Flyouts");

var _Flyouts2 = _interopRequireDefault(_Flyouts);

var _Menu = require("./components/Menu");

var _Menu2 = _interopRequireDefault(_Menu);

var _NavList = require("./components/NavList");

var _NavList2 = _interopRequireDefault(_NavList);

var _NavItem = require("./components/NavItem");

var _NavItem2 = _interopRequireDefault(_NavItem);

var _Section = require("./components/Section");

var _Section2 = _interopRequireDefault(_Section);

var _SectionStack = require("./components/SectionStack");

var _SectionStack2 = _interopRequireDefault(_SectionStack);

var _setOpenMenuContext = require("./setOpenMenuContext");

var _currentOpenMenuContext = require("./currentOpenMenuContext");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SiteHeader = function (_Component) {
  _inherits(SiteHeader, _Component);

  function SiteHeader(props) {
    _classCallCheck(this, SiteHeader);

    var _this = _possibleConstructorReturn(this, (SiteHeader.__proto__ || Object.getPrototypeOf(SiteHeader)).call(this, props));

    _this.state = {
      currentMenu: null,
      expanded: false,
      searching: false
    };

    _this.searchRef = null;
    _this.buttonsRef = null;

    _this.setOpenMenu = _this.setOpenMenu.bind(_this);
    _this.handleSearchClick = _this.handleSearchClick.bind(_this);
    _this.handleSearchBlur = _this.handleSearchBlur.bind(_this);
    _this.handleToggleClick = _this.handleToggleClick.bind(_this);
    return _this;
  }

  _createClass(SiteHeader, [{
    key: "handleSearchBlur",
    value: function handleSearchBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      // This prevents blur from triggering an additional setState in the event
      // that the search button is tapped on mobile.
      if (relatedTarget && (this.searchRef && this.searchRef.contains(relatedTarget) || this.buttonsRef && this.buttonsRef.contains(relatedTarget))) {
        return;
      }

      this.setState({
        searching: false
      });
    }
  }, {
    key: "handleSearchClick",
    value: function handleSearchClick() {
      this.setState({
        searching: !this.state.searching
      });
    }
  }, {
    key: "handleToggleClick",
    value: function handleToggleClick() {
      var expanded = !this.state.expanded;

      window.scrollTo(0, 0);

      if (expanded) {
        document.body.classList.add("u-bodyModalOpenNarrowOnly");
      } else {
        document.body.classList.remove("u-bodyModalOpenNarrowOnly");
      }

      this.setState({
        expanded: expanded
      });
    }
  }, {
    key: "setOpenMenu",
    value: function setOpenMenu(menuId) {
      if (this.menusEl) {
        // Scroll back to 0, so that panels are in view and
        // cover the available space and can't be over-scrolled.
        this.menusEl.scrollTop = 0;
      }

      this.setState({
        currentMenu: menuId
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var getClassName = (0, _utilityClassNames2.default)("SiteHeader");

      return _react2.default.createElement(
        _setOpenMenuContext.Provider,
        { value: this.setOpenMenu },
        _react2.default.createElement(
          _currentOpenMenuContext.Provider,
          { value: this.state.currentMenu },
          _react2.default.createElement(
            "div",
            {
              role: "banner",
              className: getClassName({
                states: (0, _classnames2.default)({
                  expanded: this.state.expanded,
                  expandedMenu: this.state.currentMenu !== null,
                  searching: this.state.searching
                }),
                modifiers: (0, _classnames2.default)({
                  noSearch: !this.props.search,
                  withSiteName: Boolean(this.props.siteName)
                })
              }),
              "data-search": this.props.search,
              "data-search-form-action": this.props.searchFormAction,
              "data-search-form-method": this.props.searchFormMethod,
              "data-search-input-name": this.props.searchInputName,
              "data-typeahead-url": this.props.typeaheadUrl
            },
            _react2.default.createElement(
              "nav",
              { className: getClassName({ descendantName: "inner" }) },
              _react2.default.createElement(
                "a",
                {
                  className: getClassName({ descendantName: "logo" }),
                  href: this.props.logoHref
                },
                _react2.default.createElement(
                  "div",
                  { className: getClassName({ descendantName: "logoInner" }) },
                  _react2.default.createElement(
                    "span",
                    {
                      className: getClassName({
                        descendantName: "logoInnerLabel",
                        utilities: "hiddenVisually"
                      })
                    },
                    this.props.logoText
                  )
                )
              ),
              this.props.siteName ? _react2.default.createElement(
                "a",
                {
                  className: getClassName({
                    descendantName: "siteName",
                    utilities: "typographySmallCaps"
                  }),
                  href: this.props.logoHref
                },
                this.props.siteName
              ) : null,
              _react2.default.createElement(
                "div",
                {
                  className: getClassName({ descendantName: "buttons" }),
                  ref: function ref(_ref) {
                    return _this2.buttonsRef = _ref;
                  }
                },
                _react2.default.createElement(
                  "button",
                  {
                    "aria-label": "Toggle button",
                    className: getClassName({ descendantName: "toggleButton" }),
                    onClick: this.handleToggleClick
                  },
                  this.state.expanded ? _react2.default.createElement(_cross2.default, null) : _react2.default.createElement(_hamburger2.default, null)
                ),
                this.props.search ? _react2.default.createElement(
                  "button",
                  {
                    "aria-label": "Search",
                    className: getClassName({ descendantName: "searchButton" }),
                    onClick: this.handleSearchClick
                  },
                  this.state.searching ? _react2.default.createElement(_cross2.default, null) : _react2.default.createElement(_search2.default, null)
                ) : null
              ),
              this.state.searching ? null : _react2.default.createElement(
                "ul",
                {
                  className: getClassName({ descendantName: "menus" }),
                  ref: function ref(_ref2) {
                    _this2.menusEl = _ref2;
                  },
                  role: "menubar"
                },
                this.props.children
              ),
              this.state.searching ? _react2.default.createElement(
                "div",
                {
                  className: getClassName({ descendantName: "search" }),
                  ref: function ref(_ref3) {
                    return _this2.searchRef = _ref3;
                  }
                },
                _react2.default.createElement(
                  "form",
                  {
                    action: this.props.searchFormAction,
                    type: "get",
                    role: "search"
                  },
                  _react2.default.createElement(_componentSearchInput2.default
                  // When the user clicks the search button, and the search
                  // input appears, the expectation is that the input will be in
                  // focus.
                  , { autoFocus: true // eslint-disable-line jsx-a11y/no-autofocus
                    , name: this.props.searchInputName,
                    typeahead: Boolean(this.props.typeaheadUrl),
                    typeaheadUrl: this.props.typeaheadUrl,
                    onBlur: this.handleSearchBlur
                  })
                )
              ) : null
            )
          )
        )
      );
    }
  }]);

  return SiteHeader;
}(_react.Component);

SiteHeader.displayName = "SiteHeader";
SiteHeader.propTypes = {
  /**
   * The SiteHeader contents. These should be `SiteHeader.Menu`s
   */
  children: _propTypes2.default.node,

  /**
   * URL for the logo link
   */
  logoHref: _propTypes2.default.string.isRequired,

  /**
   * Alternative text for the logo
   */
  logoText: _propTypes2.default.string,

  /**
   * Enable or disable search.
   */
  search: _propTypes2.default.bool,

  /**
   * Search form action. If unspecified, will submit to the current page address.
   */
  searchFormAction: _propTypes2.default.string,

  /**
   * Search form type
   */
  searchFormMethod: _propTypes2.default.string,

  /**
   * Search input name
   */
  searchInputName: _propTypes2.default.string,

  /**
   * Site name for the header.
   */
  siteName: _propTypes2.default.string,

  /**
   * Search Typeahead URL. If unspecified, typeahead will be disabled.
   */
  typeaheadUrl: _propTypes2.default.string
};
SiteHeader.defaultProps = {
  logoText: "Thomson Reuters",
  search: true,
  searchFormAction: null,
  searchFormMethod: "get",
  searchInputName: "q",
  typeaheadUrl: null
};


SiteHeader.Column = _Column2.default;
SiteHeader.FeaturedItem = _FeaturedItem2.default;
SiteHeader.Flyouts = _Flyouts2.default;
SiteHeader.Menu = _Menu2.default;
SiteHeader.NavList = _NavList2.default;
SiteHeader.NavItem = _NavItem2.default;
SiteHeader.Section = _Section2.default;
SiteHeader.SectionStack = _SectionStack2.default;

exports.default = SiteHeader;