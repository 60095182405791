"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.siteHeaderRehydrator = exports.menuRehydrator = exports.flyoutsPanelRehydrator = exports.flyoutsRehydrator = undefined;

var _SiteHeader = require("./SiteHeader");

var _SiteHeader2 = _interopRequireDefault(_SiteHeader);

var _SiteHeader3 = require("./SiteHeader.rehydrator");

var _SiteHeader4 = _interopRequireDefault(_SiteHeader3);

var _Menu = require("./components/Menu");

var _Flyouts = require("./components/Flyouts");

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableSiteHeader = (0, _componentRehydratable2.default)(_SiteHeader2.default);

RehydratableSiteHeader.Column = _SiteHeader2.default.Column;
RehydratableSiteHeader.FeaturedItem = _SiteHeader2.default.FeaturedItem;
RehydratableSiteHeader.Flyouts = _SiteHeader2.default.Flyouts;
RehydratableSiteHeader.Menu = _SiteHeader2.default.Menu;
RehydratableSiteHeader.NavList = _SiteHeader2.default.NavList;
RehydratableSiteHeader.NavItem = _SiteHeader2.default.NavItem;
RehydratableSiteHeader.Section = _SiteHeader2.default.Section;
RehydratableSiteHeader.SectionStack = _SiteHeader2.default.SectionStack;

exports.default = RehydratableSiteHeader;
exports.flyoutsRehydrator = _Flyouts.flyoutsRehydrator;
exports.flyoutsPanelRehydrator = _Flyouts.flyoutsPanelRehydrator;
exports.menuRehydrator = _Menu.menuRehydrator;
exports.siteHeaderRehydrator = _SiteHeader4.default;