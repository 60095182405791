"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextareaControl = require("./TextareaControl");

var _TextareaControl2 = _interopRequireDefault(_TextareaControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextareaControl2.default;