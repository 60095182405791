"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentHorizontalOverflow = require("@wel-ui/component-horizontal-overflow");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TabList = function TabList(_ref) {
  var children = _ref.children,
      layoutVariant = _ref.layoutVariant;

  var getClassName = (0, _utilityClassNames2.default)("TabsTabList");

  return _react2.default.createElement(
    "div",
    {
      role: "presentation",
      className: getClassName({ modifiers: (0, _classnames2.default)(layoutVariant) })
    },
    _react2.default.createElement(
      _componentHorizontalOverflow.NonRehydratableHorizontalOverflow,
      null,
      _react2.default.createElement(
        "ul",
        { role: "tablist", className: getClassName({ descendantName: "list" }) },
        children
      )
    )
  );
};

TabList.propTypes = {
  /**
   * The children of this `TabList`. Should be of type `Tab`
   */
  children: _propTypes2.default.node,

  /**
   * Optional modifier to switch layout variants
   */
  layoutVariant: _propTypes2.default.oneOf(["verticalAtDesktop"])
};

TabList.displayName = "Tabs.TabList";

exports.default = TabList;