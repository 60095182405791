"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _componentAnchor = require("@wel-ui/component-anchor");

var _componentAnchor2 = _interopRequireDefault(_componentAnchor);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var CardBase = function CardBase(_ref) {
  var _classNames;

  var borderless = _ref.borderless,
      href = _ref.href,
      size = _ref.size,
      children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(CardBase.displayName);

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)((_classNames = {}, _defineProperty(_classNames, size, true), _defineProperty(_classNames, "borderless", borderless), _classNames))
      })
    },
    _react2.default.createElement(
      _componentAnchor2.default,
      {
        className: getClassName({
          descendantName: href ? "link" : "wrapper"
        }),
        fallbackElementTag: "div",
        href: href || null
      },
      _react2.default.createElement(
        "div",
        {
          className: getClassName({
            descendantName: "inner"
          })
        },
        children
      )
    )
  );
};

CardBase.displayName = "CardBase";

CardBase.sizes = ["small", "medium", "large"];

/* eslint-disable max-len */
CardBase.propTypes = {
  /**
   * Hide the border
   */
  borderless: _propTypes2.default.bool,
  /**
   * Card URL
   */
  href: _propTypes2.default.string,
  /**
   * Child node
   */
  children: _propTypes2.default.node,
  /**
   * Size of the Card. Will set the minumum height and grow to fit content.
   */
  size: _propTypes2.default.oneOf(CardBase.sizes).isRequired
};
/* eslint-enable max-len */

CardBase.defaultProps = {
  borderless: false
};

exports.default = CardBase;