"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BasePlayerEmbed = function (_Component) {
  _inherits(BasePlayerEmbed, _Component);

  function BasePlayerEmbed() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BasePlayerEmbed);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BasePlayerEmbed.__proto__ || Object.getPrototypeOf(BasePlayerEmbed)).call.apply(_ref, [this].concat(args))), _this), _this.handleIframeRef = function (ref) {
      _this.iframe = ref;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BasePlayerEmbed, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.loadVideo();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (this.props.src !== prevProps.src) {
        this.loadVideo();
      }
    }
  }, {
    key: "loadVideo",
    value: function loadVideo() {
      this.iframe.src = this.props.src;

      if (this.props.onFrameReady) {
        this.props.onFrameReady(this.iframe);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react2.default.createElement("iframe", {
        allowFullScreen: true,
        allow: "autoplay; fullscreen",
        className: this.props.getClassName({ descendantName: "frame" }),
        frameBorder: "0",
        height: 281,
        id: this.props.id,
        ref: this.handleIframeRef,
        width: 500
      });
    }
  }]);

  return BasePlayerEmbed;
}(_react.Component);

BasePlayerEmbed.propTypes = {
  getClassName: _propTypes2.default.func.isRequired,
  id: _propTypes2.default.string,
  onFrameReady: _propTypes2.default.func,
  src: _propTypes2.default.string.isRequired
};
exports.default = BasePlayerEmbed;