"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextControl = require("./TextControl");

var _TextControl2 = _interopRequireDefault(_TextControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextControl2.default;