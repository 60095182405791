"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentAnchor = require("@wel-ui/component-anchor");

var _componentAnchor2 = _interopRequireDefault(_componentAnchor);

var _arrowRight = require("@wel-ui/icons/lib/ui/arrow-right");

var _arrowRight2 = _interopRequireDefault(_arrowRight);

var _plus = require("@wel-ui/icons/lib/ui/plus");

var _plus2 = _interopRequireDefault(_plus);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Link = function Link(_ref) {
  var block = _ref.block,
      children = _ref.children,
      contentLocation = _ref.contentLocation,
      download = _ref.download,
      href = _ref.href,
      iconLeft = _ref.iconLeft,
      iconRight = _ref.iconRight,
      modalLabel = _ref.modalLabel,
      newWindow = _ref.newWindow,
      type = _ref.type;

  var getClassName = (0, _utilityClassNames2.default)(Link.displayName);

  var withIcon = iconLeft || iconRight || type !== "inline";

  var icon = iconRight || iconLeft;

  switch (type) {
    case "action":
      icon = _react2.default.createElement(_plus2.default, null);
      break;
    case "primary":
    case "secondary":
      icon = _react2.default.createElement(_arrowRight2.default, null);
      break;
  }

  // eslint-disable-next-line react/prop-types
  var Wrapper = function Wrapper(props) {
    return _react2.default.createElement(
      _componentAnchor2.default,
      {
        className: getClassName({
          modifiers: (0, _classnames2.default)({ block: block, iconLeft: iconLeft, withIcon: withIcon }, type)
        }),
        contentLocation: contentLocation,
        download: download,
        fallbackElementTag: "span",
        href: href,
        modalLabel: modalLabel,
        newWindow: newWindow
      },
      props.children
    );
  };

  if (withIcon) {
    return _react2.default.createElement(
      Wrapper,
      null,
      iconLeft && _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "icon" }) },
        icon
      ),
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "inner" }) },
        children
      ),
      !iconLeft && _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "icon" }) },
        icon
      )
    );
  }

  // Deliberately without descendants
  return _react2.default.createElement(
    Wrapper,
    null,
    children
  );
};

Link.defaultProps = {
  type: "inline"
};

Link.displayName = "Link";

Link.types = ["primary", "secondary", "action", "inline"];

/* eslint-disable max-len */
Link.propTypes = {
  /**
   * Enable block behaviour.
   */
  block: _propTypes2.default.bool,

  /**
   * Link text.
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * content location to AJAX load content into modal, should provide href
   * for a fallback where possible.
   */
  contentLocation: _propTypes2.default.string,

  /**
   * Instruct the browser to download the link URL instead of navigating to it.
   * If the prop is given a string value, it is used as the pre-filled file name
   * in the Save prompt.
   */
  download: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.string]),

  /**
   * URL for the link.
   */
  href: _propTypes2.default.string,

  /**
   * Arbitrary Icon component to display to left of text.
   */
  iconLeft: _propTypes2.default.node,

  /**
   * Arbitrary Icon component to display to right of text.
   */
  iconRight: _propTypes2.default.node,

  /**
   * Aria label for modal dialog, required if `contentLocation` is defined.
   */
  modalLabel: _propTypes2.default.string,

  /**
   * Type of link.
   */
  type: _propTypes2.default.oneOf(Link.types),

  /**
   * Open link in new tab/window.
   */
  newWindow: _propTypes2.default.bool
};
/* eslint-enable max-len */

exports.default = Link;