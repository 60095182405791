"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submit = exports.fireFormSubmitFailure = exports.fireFormSubmitSuccess = exports.fireFormStart = undefined;

var _submit = require("./submit");

var _submit2 = _interopRequireDefault(_submit);

var _analytics = require("./analytics");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.fireFormStart = _analytics.fireFormStart;
exports.fireFormSubmitSuccess = _analytics.fireFormSubmitSuccess;
exports.fireFormSubmitFailure = _analytics.fireFormSubmitFailure;
exports.submit = _submit2.default;