"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _DismissibleBanner = require("./DismissibleBanner");

var _DismissibleBanner2 = _interopRequireDefault(_DismissibleBanner);

var _DismissibleBanner3 = require("./DismissibleBanner.rehydrator");

var _DismissibleBanner4 = _interopRequireDefault(_DismissibleBanner3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableDismissibleBanner = (0, _componentRehydratable2.default)(_DismissibleBanner2.default);

RehydratableDismissibleBanner.LinkItem = _DismissibleBanner2.default.LinkItem;

exports.default = RehydratableDismissibleBanner;
exports.rehydrator = _DismissibleBanner4.default;