"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (scriptSrc) {
  if (typeof document === "undefined") {
    return Promise.reject(new Error("mountScript requires document to be defined. It can't be run on a server."));
  }

  return new Promise(function (resolve, reject) {
    var scriptElement = document.createElement("script");

    scriptElement.onload = function () {
      resolve(scriptElement);
    };

    scriptElement.onerror = function () {
      reject();
    };

    scriptElement.src = scriptSrc;
    document.body.appendChild(scriptElement);
  });
};