"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Column = function Column(_ref) {
  var children = _ref.children,
      hiddenOnMobile = _ref.hiddenOnMobile;

  var getClassName = (0, _utilityClassNames2.default)("SiteHeaderColumn");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)({
          hiddenOnMobile: hiddenOnMobile
        })
      })
    },
    children
  );
};

Column.propTypes = {
  /**
   * The contents of the column. If this is a typical navigation menu, you should use `SiteHeader.NavList` here.
   */
  children: _propTypes2.default.node,

  /**
   * Whether or not this column is hidden at smaller breakpoints.
   */
  hiddenOnMobile: _propTypes2.default.bool
};

Column.displayName = "SiteHeader.Column";

exports.default = Column;