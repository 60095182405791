"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = exports.GlobalFooterItem = undefined;

var _GlobalFooter = require("./GlobalFooter");

var _GlobalFooter2 = _interopRequireDefault(_GlobalFooter);

var _GlobalFooterItem = require("./GlobalFooterItem");

var _GlobalFooterItem2 = _interopRequireDefault(_GlobalFooterItem);

var _GlobalFooter3 = require("./GlobalFooter.rehydrator");

var _GlobalFooter4 = _interopRequireDefault(_GlobalFooter3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_GlobalFooter2.default);
exports.GlobalFooterItem = _GlobalFooterItem2.default;
exports.rehydrator = _GlobalFooter4.default;