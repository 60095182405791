"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownHeader = function DropdownHeader(_ref) {
  var getClassName = _ref.getClassName,
      text = _ref.text,
      expanded = _ref.expanded,
      toggleExpand = _ref.toggleExpand;
  return _react2.default.createElement(
    "button",
    {
      className: getClassName({ descendantName: "header" }),
      onClick: toggleExpand,
      tabIndex: "0"
    },
    _react2.default.createElement(
      "span",
      { className: getClassName({ descendantName: "headerText" }) },
      text
    ),
    _react2.default.createElement(
      "span",
      { className: getClassName({ descendantName: "headerIcon" }) },
      expanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
    )
  );
};

DropdownHeader.displayName = "DropdownHeader";

DropdownHeader.propTypes = {
  expanded: _propTypes2.default.bool.isRequired,
  getClassName: _propTypes2.default.func.isRequired,
  text: _propTypes2.default.string.isRequired,
  toggleExpand: _propTypes2.default.func.isRequired
};

exports.default = DropdownHeader;