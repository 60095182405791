"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _Figure = require("./Figure");

var _Figure2 = _interopRequireDefault(_Figure);

var _Figure3 = require("./Figure.rehydrator");

var _Figure4 = _interopRequireDefault(_Figure3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableFigure = (0, _componentRehydratable2.default)(_Figure2.default);

exports.default = RehydratableFigure;
exports.rehydrator = _Figure4.default;