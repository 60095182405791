"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SelectControl = require("./SelectControl");

var _SelectControl2 = _interopRequireDefault(_SelectControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SelectControl2.default;