"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var callIfDefined = exports.callIfDefined = function callIfDefined(func) {
  for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  if (func) {
    func.apply(undefined, args);
  }
};

var canPublish = exports.canPublish = function canPublish() {
  return typeof window !== "undefined" && window.PubSub;
};

var safePublish = exports.safePublish = function safePublish() {
  if (canPublish()) {
    var _window$PubSub;

    (_window$PubSub = window.PubSub).publish.apply(_window$PubSub, arguments);
  }
};