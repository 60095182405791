"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _LinkCardFooter = require("./LinkCardFooter");

var _LinkCardFooter2 = _interopRequireDefault(_LinkCardFooter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _LinkCardFooter2.default;