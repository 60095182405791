"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _FilterGroup = require("./components/FilterGroup");

var _FilterGroup2 = _interopRequireDefault(_FilterGroup);

var _FilterItem = require("./components/FilterItem");

var _FilterItem2 = _interopRequireDefault(_FilterItem);

var _FilterSection = require("./components/FilterSection");

var _FilterSection2 = _interopRequireDefault(_FilterSection);

var _FilterSectionOverflow = require("./components/FilterSectionOverflow");

var _FilterSectionOverflow2 = _interopRequireDefault(_FilterSectionOverflow);

var _Filters = require("./components/Filters");

var _Filters2 = _interopRequireDefault(_Filters);

var _Link = require("./components/Link");

var _Link2 = _interopRequireDefault(_Link);

var _Section = require("./components/Section");

var _Section2 = _interopRequireDefault(_Section);

var _Selected = require("./components/Selected");

var _Selected2 = _interopRequireDefault(_Selected);

var _SelectedGroup = require("./components/SelectedGroup");

var _SelectedGroup2 = _interopRequireDefault(_SelectedGroup);

var _SelectedItem = require("./components/SelectedItem");

var _SelectedItem2 = _interopRequireDefault(_SelectedItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SearchFilters = function (_Component) {
  _inherits(SearchFilters, _Component);

  function SearchFilters(props) {
    _classCallCheck(this, SearchFilters);

    var _this = _possibleConstructorReturn(this, (SearchFilters.__proto__ || Object.getPrototypeOf(SearchFilters)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(SearchFilters.displayName);
    return _this;
  }

  _createClass(SearchFilters, [{
    key: "render",
    value: function render() {
      return _react2.default.createElement(
        "div",
        { className: this.getClassName() },
        this.props.children
      );
    }
  }]);

  return SearchFilters;
}(_react.Component);

SearchFilters.displayName = "SearchFilters";
SearchFilters.propTypes = {
  /**
   * The contents of this `SearchFilters` element.
   */
  children: _propTypes2.default.node
};


SearchFilters.FilterGroup = _FilterGroup2.default;
SearchFilters.FilterItem = _FilterItem2.default;
SearchFilters.FilterSection = _FilterSection2.default;
SearchFilters.FilterSectionOverflow = _FilterSectionOverflow2.default;
SearchFilters.Filters = _Filters2.default;
SearchFilters.Link = _Link2.default;
SearchFilters.Section = _Section2.default;
SearchFilters.Selected = _Selected2.default;
SearchFilters.SelectedGroup = _SelectedGroup2.default;
SearchFilters.SelectedItem = _SelectedItem2.default;

exports.default = SearchFilters;