"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

// For information on defaultValue vs. value, defaultChecked vs. checked,
// and defaultValue vs. selected, see React's documentation on uncontrolled
// form elements: https://reactjs.org/docs/uncontrolled-components.html

exports.default = {
  input: function input(el, attributes) {
    var toReturn = _extends({}, attributes);

    // Never pass value or checked. These props would switch React form
    // elements into controlled mode.
    if (typeof toReturn.checked !== "undefined") {
      delete toReturn.checked;
    }

    if (typeof toReturn.value !== "undefined") {
      delete toReturn.value;
    }

    // Use the DOM element properties instead of attributes. These may reflect
    // changes the user has already made before we could rehydrate.
    if (el.checked) {
      toReturn.defaultChecked = el.checked;
    }

    if (el.value) {
      toReturn.defaultValue = el.value;
    }

    return toReturn;
  },

  option: function option(el, attributes) {
    var toReturn = _extends({}, attributes);

    // <option> elements will have their "selected" attribute hoisted to the
    // <select> element that contains them, as a defaultValue.
    if (typeof toReturn.selected !== "undefined") {
      delete toReturn.selected;
    }

    return toReturn;
  },

  select: function select(el, attributes) {
    var toReturn = _extends({}, attributes);

    // Passing value would switch <select> into controlled mode.
    if (typeof toReturn.value !== "undefined") {
      delete toReturn.value;
    }

    // Use the DOM element properties instead of attributes. These may reflect
    // changes the user has already made before we could rehydrate.
    if (el.value) {
      toReturn.defaultValue = el.value;
    }

    return toReturn;
  },

  textarea: function textarea(el, attributes) {
    var toReturn = _extends({}, attributes);

    // Passing value would switch <textarea> into controlled mode.
    if (typeof toReturn.value !== "undefined") {
      delete toReturn.value;
    }

    // Use the DOM element properties instead of attributes. These may reflect
    // changes the user has already made before we could rehydrate.
    if (el.value) {
      toReturn.defaultValue = el.value;
    }

    return toReturn;
  }
};