"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VideoPlayerCore = require("./VideoPlayerCore");

var _VideoPlayerCore2 = _interopRequireDefault(_VideoPlayerCore);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableVideoPlayerCore = (0, _componentRehydratable2.default)(_VideoPlayerCore2.default);

exports.default = RehydratableVideoPlayerCore;