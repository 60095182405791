"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cart = undefined;

var _fetchJsonp = require("fetch-jsonp");

var _fetchJsonp2 = _interopRequireDefault(_fetchJsonp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var cart = exports.cart = function cart(apiBase) {
  return (0, _fetchJsonp2.default)(apiBase + "/cart/cart.json", {
    cache: "no-cache",
    credentials: "include"
  });
};