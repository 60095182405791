"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentHeading = require("@wel-ui/component-heading");

var _componentHeading2 = _interopRequireDefault(_componentHeading);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// wel
/**
 * @define Expandable.Header
 * Expandable.Item.Header Component
 */

// Vendor
var Header = function Header(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick,
      isExpanded = _ref.isExpanded,
      headingRank = _ref.headingRank;

  var getClassName = (0, _utilityClassNames2.default)(Header.displayName);

  var chevronClassName = getClassName({ descendantName: "chevron" });

  return _react2.default.createElement(
    "button",
    {
      onClick: onClick,
      className: getClassName({
        states: isExpanded ? "expanded" : "collapsed"
      })
    },
    _react2.default.createElement(
      _componentHeading2.default,
      { rank: headingRank, type: "s" },
      _react2.default.createElement(
        "span",
        {
          className: getClassName({
            descendantName: "headingInner"
          })
        },
        children
      )
    ),
    _react2.default.createElement(
      "div",
      { className: chevronClassName },
      isExpanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
    )
  );
};

Header.propTypes = {
  /**
   * Children elements to be displayed within internal Heading component
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Callback when expanded state is toggled via a lcick
   */
  onClick: _propTypes2.default.func.isRequired,
  /**
   * Heading rank to use for the default heading
   */
  headingRank: _propTypes2.default.oneOf(_componentHeading2.default.ranks),
  /**
   * Expanded state for controlled component mode
   */
  isExpanded: _propTypes2.default.bool.isRequired
};

Header.displayName = "Expandable.Item.Header";
exports.default = Header;