"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

var _ChapterNavInner = require("./ChapterNavInner");

var _ChapterNavInner2 = _interopRequireDefault(_ChapterNavInner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_ChapterNavInner2.default);