"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.panelRehydrator = undefined;

var _Panel = require("./Panel");

var _Panel2 = _interopRequireDefault(_Panel);

var _Panel3 = require("./Panel.rehydrator");

var _Panel4 = _interopRequireDefault(_Panel3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Panel2.default;
exports.panelRehydrator = _Panel4.default;