"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownItem = function DropdownItem(_ref) {
  var getClassName = _ref.getClassName,
      item = _ref.item,
      onItemClicked = _ref.onItemClicked,
      onBlur = _ref.onBlur;

  var span = _react2.default.createElement(
    "span",
    { className: getClassName({ descendantName: "itemInnerText" }) },
    item.label
  );

  var clickable = void 0;

  // Switch between <a> and <button> based on whether has href or value
  if (item.href) {
    clickable = _react2.default.createElement(
      "a",
      {
        className: getClassName({ descendantName: "itemInner" }),
        href: item.href,
        tabIndex: "0"
      },
      span
    );
  } else {
    clickable = _react2.default.createElement(
      "button",
      {
        className: getClassName({ descendantName: "itemInner" }),
        tabIndex: "0"
        // onClick conflicts with onBlur, so use onMouseDown and onKeyDown. See https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
        , onKeyDown: function onKeyDown(event) {
          var enterKeyCode = 13;
          var spacebarKeyCode = 32;

          if (event.keyCode === enterKeyCode || event.keyCode === spacebarKeyCode) {
            onItemClicked(item);
          }
        },
        onMouseDown: function onMouseDown() {
          return onItemClicked(item);
        },
        onBlur: onBlur
      },
      span
    );
  }

  return _react2.default.createElement(
    "li",
    { className: getClassName({ descendantName: "item" }) },
    clickable
  );
};

DropdownItem.displayName = "DropdownItem";

DropdownItem.propTypes = {
  getClassName: _propTypes2.default.func.isRequired,
  item: _propTypes2.default.shape({
    label: _propTypes2.default.string.isRequired,
    href: _propTypes2.default.string
  }),
  onBlur: _propTypes2.default.func.isRequired,
  onItemClicked: _propTypes2.default.func.isRequired
};

exports.default = DropdownItem;