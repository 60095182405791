"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Svg = require("./Svg.js");

var _Svg2 = _interopRequireDefault(_Svg);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Svg2.default;