"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentLink = require("@wel-ui/component-link");

var _componentLink2 = _interopRequireDefault(_componentLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkCardFooter = function LinkCardFooter(_ref) {
  var type = _ref.type,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)(LinkCardFooter.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      _componentLink2.default,
      { type: type },
      title
    )
  );
};

LinkCardFooter.displayName = "LinkCardFooter";

/* eslint-disable max-len */
LinkCardFooter.propTypes = {
  /**
   * Type of link
   */
  type: _propTypes2.default.oneOf(["primary", "action"]),
  /**
   * Title text
   */
  title: _propTypes2.default.string.isRequired
};
/* eslint-enable max-len */

LinkCardFooter.defaultProps = {
  type: "primary"
};

exports.default = LinkCardFooter;