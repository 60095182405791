"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorPoints = undefined;

var _Image = require("./Image");

var _Image2 = _interopRequireDefault(_Image);

var _anchorPoints = require("./anchorPoints.js");

var _anchorPoints2 = _interopRequireDefault(_anchorPoints);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Image2.default;
exports.anchorPoints = _anchorPoints2.default;