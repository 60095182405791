"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _CampaignControl = require("./CampaignControl");

var _CampaignControl2 = _interopRequireDefault(_CampaignControl);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityRehydrator = require("@wel-ui/utility-rehydrator");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_CampaignControl2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var service = (0, _utilityRehydrator.getService)("CampaignTrackingParameterService");

    var props = {
      isRehydrating: true,
      defaultValue: domNode.getAttribute("data-default-value"),
      name: domNode.getAttribute("name"),
      parameterToTrack: domNode.getAttribute("data-parameter-to-track"),
      service: service
    };

    return _react2.default.createElement(_CampaignControl2.default, props);
  }
};

exports.default = rehydrator;