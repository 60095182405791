"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _componentImage = require("@wel-ui/component-image");

var _componentImage2 = _interopRequireDefault(_componentImage);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FeaturedItem = function FeaturedItem(_ref) {
  var children = _ref.children,
      imageAnchor = _ref.imageAnchor,
      imageSrc = _ref.imageSrc,
      imageSrcSet = _ref.imageSrcSet;

  var getClassName = (0, _utilityClassNames2.default)(FeaturedItem.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "image" }) },
      _react2.default.createElement(_componentImage2.default, {
        anchor: imageAnchor,
        alt: "",
        fit: "cover",
        height: 120,
        sizes: _componentImage2.default.sizes.oneThird,
        src: imageSrc,
        srcSet: imageSrcSet
      })
    ),
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "content" }) },
      children
    )
  );
};

FeaturedItem.defaultProps = {
  imageAnchor: "C"
};

FeaturedItem.propTypes = {
  /**
   * Content of the featured item
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * Anchor to pass through to Image
   */
  imageAnchor: _propTypes2.default.oneOf(_componentImage.anchorPoints),

  /**
   * Src to pass through to Image
   */
  imageSrc: _propTypes2.default.string.isRequired,

  /**
   * SrcSet to pass through to Image
   */
  imageSrcSet: _propTypes2.default.string
};

FeaturedItem.displayName = "SiteHeader.FeaturedItem";

exports.default = FeaturedItem;