"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Chapter = function Chapter(_ref) {
  var active = _ref.active,
      href = _ref.href,
      prefix = _ref.prefix,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("LongformChapterNavChapter");

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({ states: (0, _classnames2.default)({ active: active }) }),
      "data-active": active ? JSON.stringify(active) : null,
      "data-href": JSON.stringify(href),
      "data-prefix": prefix ? JSON.stringify(prefix) : null,
      "data-title": JSON.stringify(title),
      "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(Chapter),
      "data-rehydratable-in-place": true
    },
    _react2.default.createElement(
      "a",
      { className: getClassName({ descendantName: "inner" }), href: href },
      prefix ? _react2.default.createElement(
        "span",
        {
          className: getClassName({
            descendantName: "prefix",
            utilities: "typographySmallCaps"
          })
        },
        prefix
      ) : null,
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "title" }) },
        title
      )
    )
  );
};

Chapter.displayName = "Longform.ChapterNav.Chapter";

Chapter.propTypes = {
  /**
   * Set this chapter to active. Use for setting the default chapter, or defining the current chapter if not using ChapterNav's `spy` functionality. Will be overwritten when using `spy`.
   */
  active: _propTypes2.default.bool,
  /**
   * URL for the chapter. Should be an anchor if using ChapterNav on a single page (such as with `spy`) or a URL if not. If using on multiple pages, you will need to set the `active` Chapter manually.
   */
  href: _propTypes2.default.string.isRequired,
  /**
   * Prefix for the chapter, such as "1.". Numbers work better than the longhand.
   */
  prefix: _propTypes2.default.string,
  /**
   * Title of the chapter.
   */
  title: _propTypes2.default.string.isRequired
};

exports.default = Chapter;