"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItems = undefined;

var _matchSorter = require("match-sorter");

var _matchSorter2 = _interopRequireDefault(_matchSorter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getItems = function getItems(_ref) {
  var filter = _ref.filter,
      options = _ref.options;

  return filter ? (0, _matchSorter2.default)(options, filter, {
    keys: ["value"]
  }) : options;
};

exports.getItems = getItems;