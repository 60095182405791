"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _PageNavInner = require("./PageNavInner");

var _PageNavInner2 = _interopRequireDefault(_PageNavInner);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)("PageNavInner");

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_PageNavInner2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "items" }));

    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var actionsNode = domNode.querySelector("." + getClassName({ descendantName: "actions" }));

    var shareNode = domNode.querySelector("." + getClassName({ descendantName: "shareInner" }));

    var props = {
      isRehydrating: true,
      spy: readData("spy"),
      children: reactifiedChildren
    };

    if (actionsNode) {
      props.actions = (0, _componentRehydratable.rehydrateChildren)(actionsNode, extra);
    }

    if (shareNode) {
      props.share = (0, _componentRehydratable.rehydrateChildren)(shareNode, extra);
    }

    return _react2.default.createElement(_PageNavInner2.default, props);
  }
};

exports.default = rehydrator;