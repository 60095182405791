"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VerticalSpacing = function VerticalSpacing(_ref) {
  var children = _ref.children,
      size = _ref.size;

  var getClassName = (0, _utilityClassNames2.default)(VerticalSpacing.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: size }) },
    children
  );
};

VerticalSpacing.displayName = "VerticalSpacing";

VerticalSpacing.sizes = ["xs", "s", "m", "l", "xl"];

VerticalSpacing.propTypes = {
  /**
   * Markup elements to render in VerticalSpacing
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * Spacing t-shirt size
   */
  size: _propTypes2.default.oneOf(VerticalSpacing.sizes)
};

VerticalSpacing.defaultProps = {
  size: "m"
};

exports.default = VerticalSpacing;