"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _componentAnchor = require("@wel-ui/component-anchor");

var _componentAnchor2 = _interopRequireDefault(_componentAnchor);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Button = function (_Component) {
  _inherits(Button, _Component);

  function Button(props) {
    _classCallCheck(this, Button);

    var _this = _possibleConstructorReturn(this, (Button.__proto__ || Object.getPrototypeOf(Button)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(Button.displayName);
    return _this;
  }

  _createClass(Button, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          active = _props.active,
          block = _props.block,
          children = _props.children,
          color = _props.color,
          contentLocation = _props.contentLocation,
          disabled = _props.disabled,
          href = _props.href,
          icon = _props.icon,
          iconOnly = _props.iconOnly,
          kind = _props.kind,
          loading = _props.loading,
          onClick = _props.onClick,
          modalLabel = _props.modalLabel,
          newWindow = _props.newWindow;


      var bodyProps = {
        className: this.getClassName({
          descendantName: "body",
          utilities: icon && iconOnly && "hiddenVisually"
        })
      };

      var iconProps = {
        className: this.getClassName({ descendantName: "icon" })
      };

      return _react2.default.createElement(
        _componentAnchor2.default,
        {
          href: href,
          className: this.getClassName({
            modifiers: (0, _classnames2.default)(color, block ? "block" : null, icon && iconOnly ? "iconOnly" : null),
            states: (0, _classnames2.default)({
              active: active,
              disabled: disabled,
              loading: loading
            })
          }),
          contentLocation: contentLocation,
          disabled: disabled,
          modalLabel: modalLabel,
          newWindow: newWindow,
          onClick: onClick,
          kind: kind
        },
        _react2.default.createElement(
          "span",
          bodyProps,
          children
        ),
        icon && _react2.default.createElement(
          "span",
          iconProps,
          icon
        )
      );
    }
  }]);

  return Button;
}(_react.Component);

Button.displayName = "Button";
Button.propTypes = {
  active: _propTypes2.default.bool,
  block: _propTypes2.default.bool,
  children: _propTypes2.default.string.isRequired,
  contentLocation: _propTypes2.default.string,
  color: _propTypes2.default.oneOf(["primary", "secondary"]).isRequired,
  disabled: _propTypes2.default.bool,
  href: _propTypes2.default.string,
  icon: _propTypes2.default.node,
  iconOnly: _propTypes2.default.bool,
  kind: _propTypes2.default.oneOf(["button", "link", "submit"]).isRequired,
  loading: _propTypes2.default.bool,
  modalLabel: _propTypes2.default.string,
  newWindow: _propTypes2.default.bool,
  onClick: _propTypes2.default.func
};
exports.default = Button;