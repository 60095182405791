"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseItem = require("../BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _cart = require("@wel-ui/icons/lib/ui/cart");

var _cart2 = _interopRequireDefault(_cart);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _utilityAuthentication = require("@wel-ui/utility-authentication");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Cart = function (_Component) {
  _inherits(Cart, _Component);

  function Cart(props) {
    _classCallCheck(this, Cart);

    var _this = _possibleConstructorReturn(this, (Cart.__proto__ || Object.getPrototypeOf(Cart)).call(this, props));

    _this.loginStateChange = _this.loginStateChange.bind(_this);

    if (_this.props.loginManager) {
      _this.props.loginManager.onChange(_this.loginStateChange);
    }
    return _this;
  }

  _createClass(Cart, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.loginManager) {
        this.props.loginManager.offChange(this.loginStateChange);
      }
    }
  }, {
    key: "loginStateChange",
    value: function loginStateChange() {
      if (this.props.loginManager) {
        this.forceUpdate();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderCart");

      var Wrapper = function Wrapper(_ref) {
        var children = _ref.children;
        return _react2.default.createElement(
          "li",
          {
            className: getClassName(),
            "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(Cart),
            "data-rehydratable-in-place": true,
            "data-label": _this2.props.label
          },
          children
        );
      };

      if (!this.props.loginManager) {
        return _react2.default.createElement(Wrapper, null);
      }

      var label = void 0;

      if (this.props.loginManager.authState === _utilityAuthentication.AuthState.UNKNOWN) {
        label = this.props.label;
      } else {
        label = this.props.label + " (" + this.props.loginManager.itemsInCart + ")";
      }

      return _react2.default.createElement(
        Wrapper,
        null,
        _react2.default.createElement(_BaseItem2.default, _extends({}, this.props, {
          longLabel: label,
          label: "",
          icon: _react2.default.createElement(_cart2.default, null),
          href: this.props.loginManager.cartUrl
        }))
      );
    }
  }]);

  return Cart;
}(_react.Component);

Cart.displayName = "GlobalHeader.Cart";
Cart.propTypes = {
  /**
   * The label to show on this Cart.
   */
  label: _propTypes2.default.string.isRequired,

  /**
   * LoginManager instance, required at rehydration.
   */
  loginManager: _propTypes2.default.object
};
exports.default = Cart;