"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _RehydratableChapterNavInner = require("./RehydratableChapterNavInner");

var _RehydratableChapterNavInner2 = _interopRequireDefault(_RehydratableChapterNavInner);

var _Chapter = require("./components/Chapter");

var _Chapter2 = _interopRequireDefault(_Chapter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var ChapterNav = function ChapterNav(_ref) {
  var sticky = _ref.sticky,
      props = _objectWithoutProperties(_ref, ["sticky"]);

  var getClassName = (0, _utilityClassNames2.default)("LongformChapterNav");

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: (0, _classnames2.default)({ sticky: sticky }) }) },
    _react2.default.createElement(_RehydratableChapterNavInner2.default, props)
  );
};

ChapterNav.displayName = "Longform.ChapterNav";

ChapterNav.propTypes = {
  /**
   * Child nodes. Should be `ChapterNav.Chapter` components.
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * Enable sticky behaviour to make the ChapterNav stick to the top of the screen.
   */
  sticky: _propTypes2.default.bool,
  /**
   * Enable scroll spy to update the current chapter as you scroll the page.
   */
  spy: _propTypes2.default.bool
};

ChapterNav.defaultProps = {
  sticky: true,
  spy: true
};

ChapterNav.Chapter = _Chapter2.default;

exports.default = ChapterNav;