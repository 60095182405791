"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BaseItem = require("../BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Item = function Item(props) {
  var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderItem");

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(_BaseItem2.default, props)
  );
};

Item.propTypes = {
  /**
   * The children to show in this `Item`'s menu
   */
  children: _propTypes2.default.node,

  /**
   * A destination that this `Item` links to. Acts as a fallback href if children are specified.
   */
  href: _propTypes2.default.string,

  /**
   * Icon node. Should be an icon from `@wel-ui/icons`.
   */
  icon: _propTypes2.default.node,

  /**
   * The label to show on this Item.
   */
  label: _propTypes2.default.string,

  /**
   * A long variant of the label to show on this Item. This will only be shown above narrow breakpoints, and will override `label`.
   */
  longLabel: _propTypes2.default.string,

  /**
   * Narrow variant of the menu on desktop. Used for single column menus. If this is omitted, `Item` will do it's best to set this automatically.
   */
  narrow: _propTypes2.default.bool
};

Item.displayName = "GlobalHeader.Item";

exports.default = Item;