"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Login = require("./Login");

var _Login2 = _interopRequireDefault(_Login);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityRehydrator = require("@wel-ui/utility-rehydrator");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Login2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var loginManager = (0, _utilityRehydrator.getService)("LoginManager");

    var rehydratedProps = {
      isRehydrating: true
    };

    var accountLabel = domNode.getAttribute("data-account-label");

    if (accountLabel && accountLabel.length) {
      rehydratedProps.accountLabel = accountLabel;
    }

    var accountOverviewLabel = domNode.getAttribute("data-account-overview-label");

    if (accountOverviewLabel && accountOverviewLabel.length) {
      rehydratedProps.accountOverviewLabel = accountOverviewLabel;
    }

    var helloLabel = domNode.getAttribute("data-hello-label");

    if (helloLabel && helloLabel.length) {
      rehydratedProps.helloLabel = helloLabel;
    }

    var loginLabel = domNode.getAttribute("data-login-label");

    if (loginLabel && loginLabel.length) {
      rehydratedProps.loginLabel = loginLabel;
    }

    var logoutLabel = domNode.getAttribute("data-logout-label");

    if (logoutLabel && logoutLabel.length) {
      rehydratedProps.logoutLabel = logoutLabel;
    }

    return _react2.default.createElement(_Login2.default, _extends({}, rehydratedProps, { loginManager: loginManager }));
  }
};

exports.default = rehydrator;