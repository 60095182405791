"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _SiteFooter = require("./SiteFooter");

var _SiteFooter2 = _interopRequireDefault(_SiteFooter);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_SiteFooter2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var getClassName = (0, _utilityClassNames2.default)("SiteFooter");

    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "listsInner" }));
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    var props = {
      isRehydrating: true,
      searchFormAction: domNode.getAttribute("data-search-form-action"),
      searchFormMethod: domNode.getAttribute("data-search-form-method"),
      searchInputName: domNode.getAttribute("data-search-input-name"),
      typeaheadUrl: domNode.getAttribute("data-typeahead-url")
    };

    return _react2.default.createElement(
      _SiteFooter2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;