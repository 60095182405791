"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Item = function Item(_ref) {
  var children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)("SocialDropdown");

  return _react2.default.createElement(
    "li",
    { className: getClassName({ descendantName: "item" }) },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "itemInner" }) },
      children
    )
  );
};

Item.displayName = "SocialDropdown.Item";

Item.propTypes = {
  /**
   * Child nodes, made up of <SocialShare />.
   */
  children: _propTypes2.default.node
};

exports.default = Item;