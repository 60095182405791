import Button from "@wel-ui/component-button";
import Group from "@wel-ui/component-group";
import React, { Component } from "react";
import getClassNameFactory from "@wel-ui/utility-class-names";
import loginState from "../../loginState";
import RichText from "@wel-ui/component-rich-text";
import VerticalSpacing from "@wel-ui/component-vertical-spacing";

class CartInfo extends Component {
  static displayName = "CartInfo";

  constructor(props) {
    super(props);

    this.handleAddItemToCart = this.handleAddItemToCart.bind(this);
    this.handleRemoveItemFromCart = this.handleRemoveItemFromCart.bind(this);
    this.loginStateChange = this.loginStateChange.bind(this);

    if (loginState) {
      loginState.onChange(this.loginStateChange);
    }
  }

  componentWillUnmount() {
    if (loginState) {
      loginState.offChange(this.loginStateChange);
    }
  }

  handleAddItemToCart() {
    loginState.service.addItemToCart();
  }

  handleRemoveItemFromCart() {
    loginState.service.removeItemFromCart();
  }

  loginStateChange() {
    this.forceUpdate();
  }

  render() {
    const getClassName = getClassNameFactory("CartInfo");

    const Wrapper = ({ children }) => (
      <div className={getClassName()}>{children}</div>
    );

    if (!loginState) {
      return <Wrapper />;
    }

    return (
      <Wrapper>
        <VerticalSpacing size="m">
          <RichText>
            <p>Add and remove cart items using the buttons below.</p>
            <p>
              Cart item count: <b>{loginState.itemsInCart}</b>
            </p>
          </RichText>
        </VerticalSpacing>
        <VerticalSpacing size="m">
          <Group>
            <Group.Item>
              <Button
                kind="button"
                color="primary"
                onClick={this.handleAddItemToCart}
              >
                Add item
              </Button>
            </Group.Item>
            <Group.Item>
              <Button
                kind="button"
                color="primary"
                onClick={this.handleRemoveItemFromCart}
              >
                Remove item
              </Button>
            </Group.Item>
          </Group>
        </VerticalSpacing>
      </Wrapper>
    );
  }
}

export default CartInfo;
