"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GtmAnalytics = function () {
  // eslint-disable-next-line no-console
  function GtmAnalytics() {
    var logger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : console.log;

    _classCallCheck(this, GtmAnalytics);

    this.logger = logger;
  }

  _createClass(GtmAnalytics, [{
    key: "sendEvent",
    value: function sendEvent(category, action, label) {
      var layers = ["dataLayer"];

      if (typeof window === "undefined") {
        return;
      }

      // Iterate over all potential dataLayer variables and check if they exist

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = layers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var layer = _step.value;

          if (typeof window[layer] !== "undefined") {
            // Send the event into GTM
            window[layer].push({
              event: "analyticsEvent",
              eventCategory: category,
              eventAction: action,
              eventLabel: label,
              eventValue: null,
              eventNonInt: false
            });
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  }]);

  return GtmAnalytics;
}();

exports.default = GtmAnalytics;