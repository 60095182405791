"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tabsPanelRehydrator = exports.tabsRehydrator = undefined;

var _Tabs = require("./Tabs");

var _Tabs2 = _interopRequireDefault(_Tabs);

var _Tabs3 = require("./Tabs.rehydrator");

var _Tabs4 = _interopRequireDefault(_Tabs3);

var _Panel = require("./components/Panel");

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RehydratableTabs = (0, _componentRehydratable2.default)(_Tabs2.default);

RehydratableTabs.Panel = _Tabs2.default.Panel;

exports.default = RehydratableTabs;
exports.tabsRehydrator = _Tabs4.default;
exports.tabsPanelRehydrator = _Panel.panelRehydrator;