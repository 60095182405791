"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Item = require("../Item");

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// wel
var List = function List(_ref) {
  var children = _ref.children,
      ordered = _ref.ordered;

  var getClassName = (0, _utilityClassNames2.default)(List.displayName);

  var ListElement = ordered ? "ol" : "ul";

  return _react2.default.createElement(
    ListElement,
    {
      className: getClassName({ modifiers: (0, _classnames2.default)({ ordered: ordered }) })
    },
    _react2.default.Children.map(children, function (child, index) {
      return child.type === _Item2.default && _react2.default.createElement(
        "li",
        { className: getClassName({ descendantName: "itemContainer" }) },
        ordered && _react2.default.createElement(
          "span",
          {
            className: getClassName({
              descendantName: "itemNumber"
            })
          },
          index + 1
        ),
        child
      );
    })
  );
};

// Local
/**
 * @define Expandable
 * Expandable component for displaying multiple expandable items.
 *
 */

// Vendor


List.displayName = "Expandable.List";

List.propTypes = {
  /**
   * The content of the list items. These should be of type `Expandable.Item`.
   */
  children: _propTypes2.default.node,
  /**
   * Ordered list modifier
   */
  ordered: _propTypes2.default.bool
};

exports.default = List;