"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Link = function Link(_ref) {
  var children = _ref.children,
      disabled = _ref.disabled,
      href = _ref.href;

  var getClassName = (0, _utilityClassNames2.default)("SearchFiltersLink");

  var ElementType = href ? "a" : "span";

  var linkProps = {
    className: getClassName({
      modifiers: disabled ? "disabled" : ""
    })
  };

  if (href) {
    linkProps.href = href;
    linkProps.rel = "nofollow";
  }

  return _react2.default.createElement(
    ElementType,
    linkProps,
    children
  );
};

Link.displayName = "SearchFilters.Link";

Link.propTypes = {
  /**
   * The content of this Link
   */
  children: _propTypes2.default.node,

  /**
   * Show as disabled
   */
  disabled: _propTypes2.default.bool,

  /**
   * The destination for the title of this group.
   */
  href: _propTypes2.default.string
};

exports.default = Link;