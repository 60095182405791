"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GlobalFooter = function (_Component) {
  _inherits(GlobalFooter, _Component);

  /* eslint-disable max-len */
  function GlobalFooter(props) {
    _classCallCheck(this, GlobalFooter);

    var _this = _possibleConstructorReturn(this, (GlobalFooter.__proto__ || Object.getPrototypeOf(GlobalFooter)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(GlobalFooter.displayName);
    _this.ref = null;

    _this.state = {
      expanded: false
    };
    return _this;
  }
  /* eslint-enable max-len */

  _createClass(GlobalFooter, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate() {
      if (!this.ref) {
        return;
      }

      if (this.state.expanded) {
        var componentBottomY = this.ref.offsetTop + this.ref.offsetHeight;

        // if bottom of component below viewport
        if (componentBottomY > window.innerHeight) {
          this.ref.scrollIntoView(true);
        }
      }
    }
  }, {
    key: "toggleExpand",
    value: function toggleExpand() {
      this.setState({ expanded: !this.state.expanded });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          children = _props.children,
          logoHref = _props.logoHref,
          logoText = _props.logoText,
          isRehydrating = _props.isRehydrating;
      var expanded = this.state.expanded;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            modifiers: (0, _classnames2.default)({
              expanded: expanded
            })
          }),
          "data-logo-href": logoHref,
          "data-logo-text": logoText,
          ref: function ref(_ref) {
            return _this2.ref = _ref;
          }
        },
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "inner" }) },
          _react2.default.createElement(
            "div",
            { className: this.getClassName({ descendantName: "top" }) },
            _react2.default.createElement(
              "a",
              {
                className: this.getClassName({
                  descendantName: "logo"
                }),
                href: logoHref
              },
              _react2.default.createElement(
                "div",
                {
                  className: this.getClassName({ descendantName: "logoInner" })
                },
                _react2.default.createElement(
                  "span",
                  {
                    className: this.getClassName({
                      descendantName: "logoLabel",
                      utilities: "hiddenVisually"
                    })
                  },
                  logoText
                )
              )
            ),
            _react2.default.createElement(
              "button",
              {
                className: this.getClassName({ descendantName: "button" }),
                onClick: this.toggleExpand.bind(this),
                "aria-haspopup": true,
                "aria-expanded": expanded
              },
              _react2.default.createElement(
                "span",
                { className: this.getClassName({ descendantName: "caret" }) },
                expanded ? _react2.default.createElement(_chevronUp2.default, null) : _react2.default.createElement(_chevronDown2.default, null)
              )
            )
          ),
          _react2.default.createElement(
            "nav",
            null,
            _react2.default.createElement(
              _componentRehydratable.RehydratableChildren,
              null,
              isRehydrating ? children : _react2.default.createElement(
                "ul",
                { className: this.getClassName({ descendantName: "items" }) },
                children
              )
            )
          )
        )
      );
    }
  }]);

  return GlobalFooter;
}(_react.Component);

GlobalFooter.displayName = "GlobalFooter";
GlobalFooter.propTypes = {
  /**
   * GlobalFooterItems representing the links
   */
  children: _propTypes2.default.node.isRequired,
  /**
   * URL for the logo link
   */
  logoHref: _propTypes2.default.string.isRequired,
  /**
   * Alternative text for the logo
   */
  logoText: _propTypes2.default.string,
  /**
   * Whether or not the logo is rehydrating. Do not use this.
   */
  isRehydrating: _propTypes2.default.bool
};
GlobalFooter.defaultProps = {
  logoText: "Thomson Reuters",
  isRehydrating: false
};
exports.default = GlobalFooter;