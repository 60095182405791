"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextareaControl = function TextareaControl(_ref) {
  var defaultValue = _ref.defaultValue,
      disabled = _ref.disabled,
      id = _ref.id,
      labelText = _ref.labelText,
      name = _ref.name,
      onChange = _ref.onChange,
      required = _ref.required,
      value = _ref.value;

  var getClassName = (0, _utilityClassNames2.default)(TextareaControl.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName() },
    _react2.default.createElement(
      "label",
      { className: getClassName({ descendantName: "label" }), htmlFor: id },
      _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "labelText" }) },
        labelText,
        " ",
        !required && _react2.default.createElement(
          "span",
          { className: getClassName({ descendantName: "optional" }) },
          "(Optional)"
        )
      ),
      _react2.default.createElement("textarea", {
        className: getClassName({ descendantName: "input" }),
        defaultValue: defaultValue,
        disabled: disabled,
        id: id,
        name: name,
        onChange: onChange,
        required: required,
        value: value
      })
    )
  );
};

/* eslint-disable max-len */
TextareaControl.propTypes = {
  /**
   * Initialize <textarea> with a value
   */
  defaultValue: _propTypes2.default.string,
  /**
   * Disable the <textarea>
   */
  disabled: _propTypes2.default.bool,
  /**
   * Id attribute for <textarea> (and <label> `for` attribute)
   */
  id: _propTypes2.default.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: _propTypes2.default.string.isRequired,
  /**
   * Name attribute for <textarea>, required for serialisation
   */
  name: _propTypes2.default.string.isRequired,
  /**
   * Listen for changes on the <textarea>.
   */
  onChange: _propTypes2.default.func,
  /**
   * Set <textarea> to be required
   */
  required: _propTypes2.default.bool,
  /**
   * Explicitly set the value of the <textarea>. Will switch to controlled mode
   */
  value: _propTypes2.default.string
};
/* eslint-enable max-len */

TextareaControl.displayName = "Form.TextareaControl";

exports.default = TextareaControl;