"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _utilityAuthentication = require("@wel-ui/utility-authentication");

var _BaseItem = require("../BaseItem");

var _BaseItem2 = _interopRequireDefault(_BaseItem);

var _Column = require("../Column");

var _Column2 = _interopRequireDefault(_Column);

var _NavList = require("../NavList");

var _NavList2 = _interopRequireDefault(_NavList);

var _NavItem = require("../NavItem");

var _NavItem2 = _interopRequireDefault(_NavItem);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Login = function (_Component) {
  _inherits(Login, _Component);

  function Login(props) {
    _classCallCheck(this, Login);

    var _this = _possibleConstructorReturn(this, (Login.__proto__ || Object.getPrototypeOf(Login)).call(this, props));

    _this.handleLogin = _this.handleLogin.bind(_this);
    _this.handleLogout = _this.handleLogout.bind(_this);
    _this.loginStateChange = _this.loginStateChange.bind(_this);

    if (_this.props.loginManager) {
      _this.props.loginManager.onChange(_this.loginStateChange);
    }
    return _this;
  }

  _createClass(Login, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.loginManager) {
        this.props.loginManager.offChange(this.loginStateChange);
      }
    }
  }, {
    key: "handleLogin",
    value: function handleLogin() {
      if (this.props.loginManager) {
        this.props.loginManager.login();
      }
    }
  }, {
    key: "handleLogout",
    value: function handleLogout() {
      if (this.props.loginManager) {
        this.props.loginManager.logout();
      }
    }
  }, {
    key: "loginStateChange",
    value: function loginStateChange() {
      this.forceUpdate();
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderLogin");

      var Wrapper = function Wrapper(_ref) {
        var children = _ref.children;
        return _react2.default.createElement(
          "li",
          {
            className: getClassName(),
            "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(Login),
            "data-rehydratable-in-place": true,
            "data-account-label": _this2.props.accountLabel,
            "data-account-overview-label": _this2.props.accountOverviewLabel,
            "data-hello-label": _this2.props.helloLabel,
            "data-login-label": _this2.props.loginLabel,
            "data-logout-label": _this2.props.logoutLabel
          },
          children
        );
      };

      if (!this.props.loginManager) {
        return _react2.default.createElement(Wrapper, null);
      }

      return _react2.default.createElement(
        Wrapper,
        null,
        this.props.loginManager.authState === _utilityAuthentication.AuthState.LOGGED_IN ? _react2.default.createElement(
          _BaseItem2.default,
          _extends({}, this.props, {
            label: this.props.accountLabel,
            longLabel: this.props.helloLabel + ", " + this.props.loginManager.userName
          }),
          _react2.default.createElement(
            _Column2.default,
            null,
            _react2.default.createElement(
              _NavList2.default,
              null,
              this.props.accountOverviewLabel && _react2.default.createElement(_NavItem2.default, {
                title: this.props.accountOverviewLabel,
                href: this.props.loginManager.accountOverviewUrl
              }),
              this.props.logoutLabel && _react2.default.createElement(_NavItem2.default, {
                title: this.props.logoutLabel,
                onClick: this.handleLogout,
                href: this.props.loginManager.logoutUrl
              })
            )
          )
        ) : _react2.default.createElement(_BaseItem2.default, _extends({}, this.props, {
          label: this.props.loginLabel,
          onClick: this.handleLogin,
          href: this.props.loginManager.loginUrl
        }))
      );
    }
  }]);

  return Login;
}(_react.Component);

Login.displayName = "GlobalHeader.Login";
Login.propTypes = {
  /**
   * The account label to show on this Login.
   */
  accountLabel: _propTypes2.default.string.isRequired,

  /**
   * The account overview label to show on this Login.
   */
  accountOverviewLabel: _propTypes2.default.string.isRequired,

  /**
   * The hello label to show on this Login.
   */
  helloLabel: _propTypes2.default.string.isRequired,

  /**
   * The login label to show on this Login.
   */
  loginLabel: _propTypes2.default.string.isRequired,

  /**
   * The logout label to show on this Login.
   */
  logoutLabel: _propTypes2.default.string.isRequired,

  /**
   * LoginManager instance, required at rehydration.
   */
  loginManager: _propTypes2.default.object
};
exports.default = Login;