"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VerticalSpacing = require("./VerticalSpacing");

var _VerticalSpacing2 = _interopRequireDefault(_VerticalSpacing);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _VerticalSpacing2.default;