"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavItem = function NavItem(_ref) {
  var title = _ref.title,
      href = _ref.href;

  var getClassName = (0, _utilityClassNames2.default)("SiteFooterNavItem");

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(
      "a",
      { className: getClassName({ descendantName: "link" }), href: href },
      title
    )
  );
};

NavItem.propTypes = {
  /**
   * The destination that this `NavItem` links to
   */
  href: _propTypes2.default.string.isRequired,

  /**
   * The title of this `NavItem`
   */
  title: _propTypes2.default.string.isRequired
};

NavItem.displayName = "SiteFooter.NavItem";

exports.default = NavItem;