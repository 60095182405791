"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentSvg = require("@wel-ui/component-svg");

var _componentSvg2 = _interopRequireDefault(_componentSvg);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var Icon = function Icon(props) {
  return _react2.default.createElement(_componentSvg2.default, props, Icon.svg);
};

Icon.propTypes = {
  height: _propTypes2.default.number,
  label: _propTypes2.default.string,
  width: _propTypes2.default.number
};

Icon.displayName = "NucleoSmallUserInterfaceAlertCircleExc";

Icon.svg = _react2.default.createElement(
  "svg",
  { viewBox: "0 0 24 24" },
  _react2.default.createElement(
    "g",
    { className: "nc-icon-wrapper", fill: "#4d4d4d" },
    _react2.default.createElement("path", {
      d: "M12 1a11 11 0 1 0 0 22 11 11 0 1 0 0-22z",
      fill: "none",
      stroke: "#4d4d4d",
      strokeLinecap: "round",
      strokeWidth: "2"
    }),
    _react2.default.createElement("path", {
      d: "M12 7v6",
      fill: "none",
      stroke: "#ff5900",
      strokeLinecap: "round",
      strokeWidth: "2",
      "data-color": "color-2"
    }),
    _react2.default.createElement("path", {
      d: "M12 16a1 1 0 1 0 0 2 1 1 0 1 0 0-2z",
      fill: "#ff5900",
      "data-color": "color-2",
      "data-stroke": "none"
    })
  )
);

exports.default = Icon;
