"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CardBase = require("./CardBase");

var _CardBase2 = _interopRequireDefault(_CardBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _CardBase2.default;