"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ArticleCardFooter = require("./ArticleCardFooter");

var _ArticleCardFooter2 = _interopRequireDefault(_ArticleCardFooter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ArticleCardFooter2.default;