"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Standfirst = function Standfirst(_ref) {
  var children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(Standfirst.displayName);

  return _react2.default.createElement(
    "p",
    { className: getClassName() },
    children
  );
};

Standfirst.displayName = "Standfirst";

Standfirst.propTypes = {
  /**
   *  Children should be restricted to text, <a>, <em> and <strong>
   */
  children: _propTypes2.default.node
};

exports.default = Standfirst;