"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _SearchInput = require("./SearchInput");

var _SearchInput2 = _interopRequireDefault(_SearchInput);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_SearchInput2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var props = {
      isRehydrating: true,
      autoFocus: readData("auto-focus"),
      colorTheme: readData("color-theme"),
      name: readData("name"),
      placeholder: readData("placeholder"),
      typeahead: readData("typeahead"),
      typeaheadUrl: readData("typeahead-url"),
      value: readData("value")
    };

    return _react2.default.createElement(_SearchInput2.default, props);
  }
};

exports.default = rehydrator;