"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _VideoPlayerCore = require("./VideoPlayerCore");

var _VideoPlayerCore2 = _interopRequireDefault(_VideoPlayerCore);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  // The element we're rehydrating is VideoPlayerCore, but we'll replace it with
  // VideoPlayerCoreWithContext. Since context doesn't render an element, the
  // markup is identical, and multiple rehydrations will work normally.
  dataName: (0, _componentRehydratable.getRehydratableName)(_VideoPlayerCore.VideoPlayerCore),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var props = {
      autoplayInModal: domNode.getAttribute("data-autoplay-in-modal") === "true" || false,
      duration: domNode.getAttribute("data-duration"),
      gatedCookieName: domNode.getAttribute("data-gated-cookie-name"),
      gatedFormContentLocation: domNode.getAttribute("data-gated-form-content-location"),
      gatedFormId: domNode.getAttribute("data-gated-form-id"),
      videoId: domNode.getAttribute("data-video-id"),
      kind: domNode.getAttribute("data-kind"),
      playLabel: domNode.getAttribute("data-play-label"),
      analytics: extra.analytics,
      isRehydrating: true
    };
    var childrenNode = domNode.querySelector("[data-rehydratable-children]");
    var reactifiedChildren = (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra);

    return _react2.default.createElement(
      _VideoPlayerCore2.default,
      props,
      reactifiedChildren
    );
  }
};

exports.default = rehydrator;