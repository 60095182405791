"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _nodeToReact = require("./nodeToReact");

var _nodeToReact2 = _interopRequireDefault(_nodeToReact);

var _RehydratableChildren = require("../RehydratableChildren");

var _RehydratableChildren2 = _interopRequireDefault(_RehydratableChildren);

var _utilityRehydrator = require("@wel-ui/utility-rehydrator");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var rehydrateChildren = function rehydrateChildren(node, extra) {
  var customHandlers = [{
    shouldProcessNode: function shouldProcessNode(innerNode) {
      return innerNode.hasAttribute("data-rehydratable-children");
    },

    // eslint-disable-next-line react/display-name
    processNode: function processNode(innerNode, children) {
      return _react2.default.createElement.apply(_react2.default, [_RehydratableChildren2.default, {}].concat(_toConsumableArray(children)));
    }
  }, {
    replaceChildren: true,
    shouldProcessNode: function shouldProcessNode(innerNode) {
      return innerNode.hasAttribute("data-rehydratable");
    },

    processNode: function processNode(innerNode) {
      return (0, _utilityRehydrator.rehydratableToReactElement)(innerNode, extra);
    }
  }];

  var childrenContainer = (0, _nodeToReact2.default)(node, customHandlers);

  return childrenContainer.props.children;
};

exports.default = rehydrateChildren;