"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Asset = function Asset(_ref) {
  var border = _ref.border,
      caption = _ref.caption,
      children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(Asset.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName({ modifiers: (0, _classnames2.default)({ border: border }) }) },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "inner" }) },
      children
    ),
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "caption" }) },
      caption
    )
  );
};

Asset.propTypes = {
  /**
   * Render an optional border around the asset
   */
  border: _propTypes2.default.bool,

  /**
   * A caption to attach to the asset. Children should be restricted to text,
   * <a>, <em> and <strong>
   */
  caption: _propTypes2.default.node,

  /**
   * The asset. Children should be restricted to Image or VideoPlayer
   */
  children: _propTypes2.default.node.isRequired
};

Asset.displayName = "Longform.Asset";

exports.default = Asset;