"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _smallRight = require("@wel-ui/icons/lib/ui/small-right");

var _smallRight2 = _interopRequireDefault(_smallRight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Tab = function (_Component) {
  _inherits(Tab, _Component);

  function Tab(props) {
    _classCallCheck(this, Tab);

    var _this = _possibleConstructorReturn(this, (Tab.__proto__ || Object.getPrototypeOf(Tab)).call(this, props));

    _this.handleClick = _this.handleClick.bind(_this);
    _this.handleKeyDown = _this.handleKeyDown.bind(_this);
    return _this;
  }

  _createClass(Tab, [{
    key: "handleClick",
    value: function handleClick(e) {
      this.props.onClick(e, this.props.relatedId);
    }
  }, {
    key: "handleKeyDown",
    value: function handleKeyDown(e) {
      this.props.onKeyDown(e);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(oldProps) {
      // When a tab is selected, make it gain the focus. This ensures that a
      // keyboard user doesn't get lost on the page. In particular, it covers the
      // case of focusing the tab when switching tabs with arrow keys.
      if (this.anchorRef && !oldProps.selected && this.props.selected) {
        this.anchorRef.focus();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          layoutVariant = _props.layoutVariant,
          relatedId = _props.relatedId,
          selected = _props.selected,
          title = _props.title;

      var getClassName = (0, _utilityClassNames2.default)("TabsTab");

      var anchorProps = {
        className: getClassName({
          descendantName: "link"
        }),
        href: "#" + relatedId,
        onClick: this.handleClick,
        onKeyDown: this.handleKeyDown,
        id: "tab-" + relatedId,
        role: "tab"
      };

      if (selected) {
        anchorProps["aria-selected"] = true;
      } else {
        anchorProps.tabIndex = "-1";
      }

      return _react2.default.createElement(
        "li",
        {
          role: "presentation",
          className: getClassName({
            modifiers: (0, _classnames2.default)(layoutVariant),
            states: (0, _classnames2.default)({
              selected: selected
            })
          })
        },
        _react2.default.createElement(
          "a",
          _extends({}, anchorProps, { ref: function ref(_ref) {
              return _this2.anchorRef = _ref;
            }, target: "_self" }),
          title,
          layoutVariant === "verticalAtDesktop" && _react2.default.createElement(
            "span",
            { className: getClassName({ descendantName: "icon" }) },
            _react2.default.createElement(_smallRight2.default, { width: 24 })
          )
        )
      );
    }
  }]);

  return Tab;
}(_react.Component);

Tab.propTypes = {
  /**
   * Optional modifier to switch layout variants
   */
  layoutVariant: _propTypes2.default.oneOf(["verticalAtDesktop"]),

  /**
   * Event handler for clicking this tab
   */
  onClick: _propTypes2.default.func,

  /**
   * Event handler for pressing a key while focused on this tab
   */
  onKeyDown: _propTypes2.default.func,

  /**
   * The title to display on this tab
   */
  title: _propTypes2.default.string.isRequired,

  /**
   * The HTML ID of the associated tab panel
   */
  relatedId: _propTypes2.default.string.isRequired,

  /**
   * Whether or not this tab is the currently selected tab
   */
  selected: _propTypes2.default.bool
};
Tab.defaultProps = {
  onClick: function onClick() {},
  onKeyDown: function onKeyDown() {}
};
Tab.displayName = "Tabs.Tab";
exports.default = Tab;