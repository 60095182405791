"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TickerItem = require("./TickerItem");

var _TickerItem2 = _interopRequireDefault(_TickerItem);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_TickerItem2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode) {
    var props = {
      isRehydrating: true,
      dateTime: domNode.getAttribute("data-date-time"),
      href: domNode.getAttribute("data-href"),
      lang: domNode.getAttribute("data-lang"),
      lead: domNode.getAttribute("data-lead") === "true",
      title: domNode.getAttribute("data-title")
    };

    return _react2.default.createElement(_TickerItem2.default, props);
  }
};

exports.default = rehydrator;