"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Table = require("./Table");

var _Table2 = _interopRequireDefault(_Table);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Table2.default;