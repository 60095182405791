"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rehydrator = undefined;

var _Dropdown = require("./Dropdown");

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _Dropdown3 = require("./Dropdown.rehydrator");

var _Dropdown4 = _interopRequireDefault(_Dropdown3);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _componentRehydratable2 = _interopRequireDefault(_componentRehydratable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _componentRehydratable2.default)(_Dropdown2.default);
exports.rehydrator = _Dropdown4.default;