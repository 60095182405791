"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Figure = require("./Figure");

var _Figure2 = _interopRequireDefault(_Figure);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getClassName = (0, _utilityClassNames2.default)("Figure");

var rehydrator = {
  dataName: (0, _componentRehydratable.getRehydratableName)(_Figure2.default),

  // eslint-disable-next-line react/display-name
  elementToReact: function elementToReact(domNode, extra) {
    var readData = function readData(prop) {
      var data = domNode.getAttribute("data-" + prop);

      return data ? JSON.parse(data) : null;
    };

    var captionNode = domNode.querySelector("." + getClassName({ descendantName: "caption" }));
    var childrenNode = domNode.querySelector("." + getClassName({ descendantName: "imageWrapper" }));
    var reactifiedCaption = captionNode ? (0, _componentRehydratable.rehydrateChildren)(captionNode, extra) : null;
    var reactifiedChildren = childrenNode ? (0, _componentRehydratable.rehydrateChildren)(childrenNode, extra) : null;

    var props = {
      attribution: domNode.getAttribute("data-attribution"),
      caption: reactifiedCaption,
      children: reactifiedChildren,
      isRehydrating: true,
      expandable: readData("expandable"),
      expandableUrl: domNode.getAttribute("data-expandable-url")
    };

    return _react2.default.createElement(_Figure2.default, props);
  }
};

exports.default = rehydrator;