"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _arrowLeft = require("@wel-ui/icons/lib/ui/arrow-left");

var _arrowLeft2 = _interopRequireDefault(_arrowLeft);

var _arrowRight = require("@wel-ui/icons/lib/ui/arrow-right");

var _arrowRight2 = _interopRequireDefault(_arrowRight);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var NavItem = function NavItem(_ref) {
  var borderlessAtStandard = _ref.borderlessAtStandard,
      direction = _ref.direction,
      disabled = _ref.disabled,
      href = _ref.href,
      onClick = _ref.onClick,
      rest = _objectWithoutProperties(_ref, ["borderlessAtStandard", "direction", "disabled", "href", "onClick"]);

  var getClassName = (0, _utilityClassNames2.default)(NavItem.displayName);

  var ItemInnerName = "span";
  var itemInnerProps = {};

  if (!disabled) {
    if (href) {
      ItemInnerName = "a";
      itemInnerProps.href = href;
    } else if (onClick) {
      ItemInnerName = "button";
      itemInnerProps.onClick = onClick;
    }
  }

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)(_defineProperty({ borderlessAtStandard: borderlessAtStandard }, direction, true)),
        states: (0, _classnames2.default)({ disabled: disabled })
      })
    },
    _react2.default.createElement(
      ItemInnerName,
      _extends({
        className: getClassName({
          descendantName: "inner",
          states: (0, _classnames2.default)({ disabled: disabled })
        })
      }, itemInnerProps, rest),
      direction === "left" ? _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "icon" }) },
        _react2.default.createElement(_arrowLeft2.default, null)
      ) : null,
      direction === "right" ? _react2.default.createElement(
        "span",
        { className: getClassName({ descendantName: "icon" }) },
        _react2.default.createElement(_arrowRight2.default, null)
      ) : null
    )
  );
};

NavItem.displayName = "Pagination.NavItem";

/* eslint-disable max-len */
NavItem.propTypes = {
  /**
   * Hide border in the standard view
   */
  borderlessAtStandard: _propTypes2.default.bool,

  /**
   * Direction of the nav item. Left or right.
   */
  direction: _propTypes2.default.oneOf(["left", "right"]).isRequired,

  /**
   * Flag the page as disabled. Will disable the link.
   */
  disabled: _propTypes2.default.bool,

  /**
   * Page URL.
   */
  href: _propTypes2.default.string,

  /**
   * On click event handler.
   */
  onClick: _propTypes2.default.func
};
/* eslint-enable max-len */

NavItem.defaultProps = {
  disabled: false
};

exports.default = NavItem;