"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _lodash = require("lodash.debounce");

var _lodash2 = _interopRequireDefault(_lodash);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _componentSection = require("@wel-ui/component-section");

var _componentSection2 = _interopRequireDefault(_componentSection);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HorizontalOverflow = function (_Component) {
  _inherits(HorizontalOverflow, _Component);

  function HorizontalOverflow(props) {
    _classCallCheck(this, HorizontalOverflow);

    var _this = _possibleConstructorReturn(this, (HorizontalOverflow.__proto__ || Object.getPrototypeOf(HorizontalOverflow)).call(this, props));

    _this.getClassName = (0, _utilityClassNames2.default)(HorizontalOverflow.displayName);

    _this.resizeDebounceTimeout = 200;
    _this.toggleOverflowIndicators = _this.toggleOverflowIndicators.bind(_this);
    _this.toggleOverflowIndicatorsDebounced = (0, _lodash2.default)(_this.toggleOverflowIndicators, _this.resizeDebounceTimeout).bind(_this);

    _this.state = { overflowEnd: false, overflowStart: false };
    return _this;
  }

  _createClass(HorizontalOverflow, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      window.addEventListener("resize", this.toggleOverflowIndicatorsDebounced);
      this.toggleOverflowIndicators();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      window.removeEventListener("resize", this.toggleOverflowIndicatorsDebounced);
    }
  }, {
    key: "toggleOverflowIndicators",
    value: function toggleOverflowIndicators() {
      if (this.innerEl.offsetWidth >= this.innerEl.scrollWidth) {
        this.setState({
          overflowEnd: false,
          overflowStart: false
        });

        return;
      }

      if (this.innerEl.scrollLeft === 0) {
        this.setState({ overflowStart: false });
      } else {
        this.setState({ overflowStart: true });
      }

      if (this.innerEl.scrollLeft >= this.innerEl.scrollWidth - this.innerEl.offsetWidth) {
        this.setState({ overflowEnd: false });
      } else {
        this.setState({ overflowEnd: true });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          background = _props.background,
          children = _props.children;


      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            modifiers: (0, _classnames2.default)(background),
            states: (0, _classnames2.default)({
              overflowEnd: this.state.overflowEnd,
              overflowStart: this.state.overflowStart
            })
          }),
          "data-background": background,
          ref: function ref(_ref2) {
            _this2.outerEl = _ref2;
          }
        },
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName({ descendantName: "inner" }),
            onScroll: this.toggleOverflowIndicators,
            ref: function ref(_ref) {
              _this2.innerEl = _ref;
            }
          },
          _react2.default.createElement(
            _componentRehydratable.RehydratableChildren,
            null,
            children
          )
        )
      );
    }
  }]);

  return HorizontalOverflow;
}(_react.Component);

HorizontalOverflow.displayName = "HorizontalOverflow";
HorizontalOverflow.propTypes = {
  background: _propTypes2.default.oneOf(_componentSection2.default.backgrounds),
  children: _propTypes2.default.node.isRequired
};
exports.default = HorizontalOverflow;