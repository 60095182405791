"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var defaultFetchOptions = function defaultFetchOptions(cors) {
  var options = {
    cache: "no-cache",
    credentials: "include",
    redirect: "follow",
    referrer: "no-referrer"
  };

  if (cors) {
    options.mode = "cors";
  }

  return options;
};

var userlogin = exports.userlogin = function userlogin(apiBase, cors, jwt) {
  var requestJson = { tokenId: jwt };

  return fetch(apiBase + "/userlogin", _extends({}, defaultFetchOptions(cors), {
    body: JSON.stringify(requestJson),
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  }));
};

var userlogout = exports.userlogout = function userlogout(apiBase, cors) {
  return fetch(apiBase + "/userlogout", _extends({}, defaultFetchOptions(cors), {
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  }));
};

var getuserdetails = exports.getuserdetails = function getuserdetails(apiBase, cors) {
  return fetch(apiBase + "/getuserdetails", _extends({}, defaultFetchOptions(cors)));
};