import React from "react";
import UserInfo from "./UserInfo";
import { getRehydratableName } from "@wel-ui/component-rehydratable";

const rehydrator = {
  dataName: getRehydratableName(UserInfo),

  // eslint-disable-next-line react/display-name
  elementToReact: () => {
    const props = {
      isRehydrating: true
    };

    return <UserInfo {...props} />;
  }
};

export default rehydrator;
