"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SectionStack = require("./SectionStack");

var _SectionStack2 = _interopRequireDefault(_SectionStack);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _SectionStack2.default;