"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _TickerItem = require("./components/TickerItem");

var _TickerItem2 = _interopRequireDefault(_TickerItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Ticker = function (_Component) {
  _inherits(Ticker, _Component);

  function Ticker(props) {
    _classCallCheck(this, Ticker);

    var _this = _possibleConstructorReturn(this, (Ticker.__proto__ || Object.getPrototypeOf(Ticker)).call(this, props));

    _this.state = {
      currentTime: _this.props.initialTime,
      isAnimating: false,
      isClosed: false,
      isPaused: false
    };

    _this.pixelsPerSecond = 60;

    _this.getClassName = (0, _utilityClassNames2.default)(Ticker.displayName);
    _this.handleCloseClick = _this.handleCloseClick.bind(_this);
    _this.handleContentEnter = _this.handleContentEnter.bind(_this);
    _this.handleContentLeave = _this.handleContentLeave.bind(_this);
    _this.handleItemsBlur = _this.handleItemsBlur.bind(_this);
    _this.handleItemsFocus = _this.handleItemsFocus.bind(_this);
    _this.updateTime = _this.updateTime.bind(_this);
    return _this;
  }

  _createClass(Ticker, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      // We're in the browser - let's animate.
      this.setState({
        isAnimating: true,
        animationDuration: this.itemsInnerRef.offsetWidth / this.pixelsPerSecond
      });
    }
  }, {
    key: "handleCloseClick",
    value: function handleCloseClick() {
      this.setState({
        isClosed: true
      });
    }
  }, {
    key: "handleContentEnter",
    value: function handleContentEnter() {
      this.setState({
        isPaused: true
      });
    }
  }, {
    key: "handleContentLeave",
    value: function handleContentLeave() {
      this.setState({
        isPaused: false
      });
    }
  }, {
    key: "handleItemsFocus",
    value: function handleItemsFocus() {
      this.setState({
        isPaused: true
      });
    }
  }, {
    key: "handleItemsBlur",
    value: function handleItemsBlur() {
      this.itemsRef.scrollLeft = 0;
      this.setState({
        isPaused: false
      });
    }
  }, {
    key: "updateTime",
    value: function updateTime() {
      this.setState({
        currentTime: new Date().toISOString()
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var itemsStyle = {
        animationDuration: (this.state.animationDuration || 0) + "s"
      };

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName({
            states: (0, _classnames2.default)({
              animating: this.state.isAnimating,
              closed: this.state.isClosed,
              paused: this.state.isPaused
            })
          }),
          "data-initial-time": this.props.initialTime
        },
        _react2.default.createElement(
          "div",
          { className: this.getClassName({ descendantName: "logo" }) },
          _react2.default.createElement("div", { className: this.getClassName({ descendantName: "logoInner" }) })
        ),
        _react2.default.createElement(
          "div",
          {
            className: this.getClassName({ descendantName: "content" }),
            onMouseEnter: this.handleContentEnter,
            onMouseLeave: this.handleContentLeave
          },
          _react2.default.createElement(
            "div",
            {
              className: this.getClassName({ descendantName: "items" }),
              ref: function ref(_ref2) {
                _this2.itemsRef = _ref2;
              }
            },
            _react2.default.createElement(
              "ul",
              {
                className: this.getClassName({ descendantName: "itemsInner" }),
                onAnimationIteration: this.updateTime,
                onFocus: this.handleItemsFocus,
                onBlur: this.handleItemsBlur,
                ref: function ref(_ref) {
                  _this2.itemsInnerRef = _ref;
                },
                style: itemsStyle
              },
              _react2.default.Children.map(this.props.children, function (item) {
                if (item.type === _TickerItem2.default) {
                  return _react2.default.cloneElement(item, {
                    currentTime: _this2.state.currentTime
                  });
                }

                return item;
              })
            )
          ),
          _react2.default.createElement("div", { className: this.getClassName({ descendantName: "fade" }) })
        ),
        _react2.default.createElement(
          "button",
          {
            className: this.getClassName({ descendantName: "close" }),
            onClick: this.handleCloseClick,
            "aria-label": "Close news ticker"
          },
          _react2.default.createElement(
            "span",
            { className: this.getClassName({ descendantName: "closeIcon" }) },
            _react2.default.createElement(_cross2.default, { label: "Close" })
          )
        )
      );
    }
  }]);

  return Ticker;
}(_react.Component);

Ticker.displayName = "Ticker";
Ticker.propTypes = {
  /**
   * Items to display in this ticker. Each should be a `Ticker.Item`.
   */
  children: _propTypes2.default.node,
  /**
   * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time.
   */
  initialTime: _propTypes2.default.string
};
Ticker.defaultProps = {
  currentTime: new Date().toISOString()
};


Ticker.Item = _TickerItem2.default;

exports.default = Ticker;