"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TabList = function TabList(_ref) {
  var children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(TabList.displayName);

  return _react2.default.createElement(
    "div",
    { role: "presentation" },
    _react2.default.createElement(
      "ul",
      { role: "tablist", className: getClassName({ descendantName: "list" }) },
      children
    )
  );
};

TabList.propTypes = {
  /**
   * The children of this `TabList`. Should be of type `Tab`
   */
  children: _propTypes2.default.node
};

TabList.displayName = "SiteHeader.Flyouts.TabList";

exports.default = TabList;