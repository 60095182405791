"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NavList = function NavList(_ref) {
  var children = _ref.children,
      title = _ref.title;

  var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderNavList");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        modifiers: (0, _classnames2.default)({
          noTitle: !title
        })
      })
    },
    title ? _react2.default.createElement(
      "h3",
      {
        className: getClassName({
          descendantName: "title"
        }) + " u-typographySmallCaps"
      },
      title
    ) : null,
    _react2.default.createElement(
      "ul",
      { className: getClassName({ descendantName: "items" }) },
      children
    )
  );
};

NavList.propTypes = {
  /**
   * The contents of this `NavList`. These should be `GlobalHeader.NavItem`s.
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * The title to display above the list.
   */
  title: _propTypes2.default.string
};

NavList.displayName = "GlobalHeader.NavList";

exports.default = NavList;