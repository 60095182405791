"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PairedCard = require("./PairedCard");

var _PairedCard2 = _interopRequireDefault(_PairedCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PairedCard2.default;