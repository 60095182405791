"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Child = function Child(_ref) {
  var children = _ref.children,
      width = _ref.width;

  var getClassName = (0, _utilityClassNames2.default)("Longform");

  return _react2.default.createElement(
    "div",
    {
      className: getClassName({
        descendantName: "child",
        modifiers: (0, _classnames2.default)({
          narrow: width === "narrow",
          wide: width === "wide",
          full: width === "full"
        })
      })
    },
    children
  );
};

Child.propTypes = {
  /**
   * Child nodes to wrap in Child.
   */
  children: _propTypes2.default.node,
  /**
   * Width of the component.
   */
  width: _propTypes2.default.oneOf(["full", "narrow", "wide"])
};

Child.defaultProps = {
  width: "narrow"
};

Child.displayName = "Longform.Child";

exports.default = Child;