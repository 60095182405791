"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _checkCircle = require("@wel-ui/icons/lib/nucleo/small/user-interface/check-circle-08");

var _checkCircle2 = _interopRequireDefault(_checkCircle);

var _alertCircleExc = require("@wel-ui/icons/lib/nucleo/small/user-interface/alert-circle-exc");

var _alertCircleExc2 = _interopRequireDefault(_alertCircleExc);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Alert = function Alert(_ref) {
  var state = _ref.state,
      children = _ref.children;

  var getClassName = (0, _utilityClassNames2.default)(Alert.displayName);

  return _react2.default.createElement(
    "div",
    { className: getClassName({ states: state }), role: "alert" },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "icon" }) },
      {
        success: _react2.default.createElement(_checkCircle2.default, { label: state.label, width: 24 }),
        failure: _react2.default.createElement(_alertCircleExc2.default, { label: state.label, width: 24 })
      }[state]
    ),
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "content" }) },
      children
    )
  );
};

Alert.displayName = "Alert";

Alert.propTypes = {
  /**
   * Message to show.
   */
  children: _propTypes2.default.node,

  /**
   * The state of the alert.
   */
  state: _propTypes2.default.oneOf(["success", "failure"]).isRequired
};

exports.default = Alert;