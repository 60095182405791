"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Header = require("../Header");

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * @define Expandable.Item
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Expandable.Item Component
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

// Vendor


// wel


// local


var Item = function (_Component) {
  _inherits(Item, _Component);

  function Item() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Item);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Item.__proto__ || Object.getPrototypeOf(Item)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isExpanded: _this.props.isExpanded
    }, _this.getClassName = (0, _utilityClassNames2.default)(Item.displayName), _this.onClick = function () {
      _this.setState(function (state) {
        return { isExpanded: !state.isExpanded };
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Item, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (this.props.isExpanded !== prevProps.isExpanded) {
        this.setState({ isExpanded: this.props.isExpanded });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          children = _props.children,
          header = _props.header,
          title = _props.title,
          headingRank = _props.headingRank;
      var isExpanded = this.state.isExpanded;

      var expandedClassName = this.getClassName({
        descendantName: "content",
        states: "expanded"
      });
      var collapsedClassName = this.getClassName({
        descendantName: "content",
        states: "collapsed"
      });

      return _react2.default.createElement(
        "div",
        {
          className: this.getClassName(),
          "data-is-expanded": isExpanded,
          "data-heading-rank": headingRank
        },
        header({
          isExpanded: isExpanded,
          onClick: this.onClick,
          title: title,
          headingRank: headingRank
        }),
        _react2.default.createElement(
          "div",
          { className: isExpanded ? expandedClassName : collapsedClassName },
          children
        )
      );
    }
  }]);

  return Item;
}(_react.Component);

Item.defaultProps = {
  header: function header(props) {
    return _react2.default.createElement(
      _Header2.default,
      props,
      props.title || "Show More"
    );
  },
  isExpanded: false,
  headingRank: "3"
};


Item.propTypes = {
  /**
   * Children elements to be displayed within the ExpandableContent
   */
  children: _propTypes2.default.object.isRequired,
  /**
   * Header render prop function to be used as the header
   */
  header: _propTypes2.default.func,
  /**
   * Children elements to be displayed within subcomponent's internal
   * Heading component
   */
  title: _propTypes2.default.node,
  /**
   * Expanded state for controlled component mode
   */
  isExpanded: _propTypes2.default.bool,
  /**
   * Heading rank to use for the default heading - default is 3
   */
  headingRank: _propTypes2.default.string
};

Item.Header = _Header2.default;
Item.displayName = "Expandable.Item";
exports.default = Item;