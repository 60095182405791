"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _Link = require("../Link");

var _Link2 = _interopRequireDefault(_Link);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FilterItem = function FilterItem(_ref) {
  var borderless = _ref.borderless,
      children = _ref.children,
      disabled = _ref.disabled,
      href = _ref.href;

  var getClassName = (0, _utilityClassNames2.default)("SearchFiltersFilterItem");

  return _react2.default.createElement(
    "li",
    {
      className: getClassName({
        modifiers: borderless ? "borderless" : ""
      })
    },
    _react2.default.createElement(
      "div",
      {
        className: getClassName({
          descendantName: "link"
        })
      },
      _react2.default.createElement(
        _Link2.default,
        { href: disabled ? null : href, disabled: disabled },
        children
      )
    )
  );
};

FilterItem.displayName = "SearchFilters.FilterItem";

FilterItem.propTypes = {
  /**
   * Whether this item should have a border
   */
  borderless: _propTypes2.default.bool,

  /**
   * The title of this group.
   */
  children: _propTypes2.default.string,

  /**
   * Show as disabled
   */
  disabled: _propTypes2.default.bool,

  /**
   * The destination for this item.
   */
  href: _propTypes2.default.string
};

exports.default = FilterItem;