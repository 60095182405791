"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _chevronDown = require("@wel-ui/icons/lib/ui/chevron-down");

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require("@wel-ui/icons/lib/ui/chevron-up");

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _componentRehydratable = require("@wel-ui/component-rehydratable");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BaseItem = function (_Component) {
  _inherits(BaseItem, _Component);

  function BaseItem(props, context) {
    _classCallCheck(this, BaseItem);

    var _this = _possibleConstructorReturn(this, (BaseItem.__proto__ || Object.getPrototypeOf(BaseItem)).call(this, props, context));

    _this.handleBlur = _this.handleBlur.bind(_this);
    _this.handleBaseItemClick = _this.handleBaseItemClick.bind(_this);
    _this.toggleMenuExpand = _this.toggleMenuExpand.bind(_this);

    _this.ref = _react2.default.createRef();

    _this.state = {
      expanded: false
    };
    return _this;
  }

  _createClass(BaseItem, [{
    key: "handleBlur",
    value: function handleBlur(e) {
      // document.activeElement is IE11 fallback
      var relatedTarget = e.relatedTarget || document.activeElement;

      // React's `blur` event bubbles - it's actually closer to the `onfocusout`
      // event.
      //
      // We want to close the item when it loses focus. When something blurs, we
      // check the `relatedTarget` on the event, which corresponds to the newly
      // focused node. If it's contained inside the item, then the item still
      // has focus, and should stay open. Otherwise, we close it.
      if (this.ref.current && relatedTarget && this.ref.current.contains(relatedTarget)) {
        return;
      }

      document.body.classList.remove("u-bodyModalOpenNarrowOnly");

      this.setState({ expanded: false });
    }
  }, {
    key: "handleBaseItemClick",
    value: function handleBaseItemClick(e) {
      e.preventDefault();

      if (this.props.onClick) {
        this.props.onClick();
      } else {
        this.toggleMenuExpand();
      }
    }
  }, {
    key: "toggleMenuExpand",
    value: function toggleMenuExpand() {
      window.scrollTo(0, 0);

      if (this.state.expanded) {
        document.body.classList.remove("u-bodyModalOpenNarrowOnly");
      } else {
        document.body.classList.add("u-bodyModalOpenNarrowOnly");
      }

      this.setState({
        expanded: !this.state.expanded
      });
    }
  }, {
    key: "render",
    value: function render() {
      var anchorProps = {};
      var getClassName = (0, _utilityClassNames2.default)("GlobalHeaderBaseItem");

      var AnchorElementType = "a";

      if (this.props.children || !this.props.href) {
        AnchorElementType = "button";

        anchorProps.onClick = this.handleBaseItemClick;
      }

      if (this.props.children) {
        anchorProps["aria-haspopup"] = true;
        anchorProps["aria-expanded"] = this.state.expanded;
      }

      // We need to use a tabindex to ensure onBlur fires in Safari.
      anchorProps.tabIndex = 0;

      var chevron = void 0;

      if (this.props.children && this.state.expanded) {
        chevron = _react2.default.createElement(_chevronUp2.default, null);
      } else if (this.props.children && !this.state.expanded) {
        chevron = _react2.default.createElement(_chevronDown2.default, null);
      }

      return _react2.default.createElement(
        "div",
        {
          className: getClassName({
            modifiers: (0, _classnames2.default)({
              dropdown: this.props.children,
              narrow: typeof this.props.narrow === "undefined" ? _react2.default.Children.count(this.props.children) === 1 : this.props.narrow
            }),
            states: (0, _classnames2.default)({
              expanded: this.state.expanded
            })
          }),
          "data-rehydratable": (0, _componentRehydratable.getRehydratableName)(BaseItem),
          "data-rehydratable-in-place": true,
          "data-href": this.props.href,
          "data-label": this.props.label,
          "data-long-label": this.props.longLabel,
          "data-narrow": this.props.narrow,
          onBlur: this.handleBlur,
          ref: this.ref
          /**
           * tabIndex required so as to be blurable but not selectable,
           * we rely upon blur for closing menu when releasing focus.
           */
          , tabIndex: "-1"
        },
        _react2.default.createElement(
          AnchorElementType,
          _extends({
            className: getClassName({ descendantName: "link" }),
            href: this.props.href
          }, anchorProps),
          this.props.label && _react2.default.createElement(
            "span",
            {
              className: getClassName({
                descendantName: "linkLabel"
              })
            },
            this.props.label
          ),
          (this.props.longLabel || this.props.label) && _react2.default.createElement(
            "span",
            {
              className: getClassName({
                descendantName: "longLinkLabel"
              })
            },
            this.props.longLabel || this.props.label
          ),
          this.props.icon && _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "linkIcon" }) },
            this.props.icon
          ),
          chevron && _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "linkChevron" }) },
            chevron
          )
        ),
        this.props.children && _react2.default.createElement(
          "div",
          {
            tabIndex: "-1",
            className: getClassName({ descendantName: "content" }),
            role: "menu"
          },
          _react2.default.createElement(
            "div",
            { className: getClassName({ descendantName: "contentInner" }) },
            this.props.children
          )
        )
      );
    }
  }]);

  return BaseItem;
}(_react.Component);

BaseItem.displayName = "GlobalHeader.BaseItem";
BaseItem.propTypes = {
  /**
   * The children to show in this `BaseItem`'s menu
   */
  children: _propTypes2.default.node,

  /**
   * A destination that this `BaseItem` links to. Acts as a fallback href if children are specified.
   */
  href: _propTypes2.default.string,

  /**
   * Icon node. Should be an icon from `@wel-ui/icons`.
   */
  icon: _propTypes2.default.node,

  /**
   * The label to show on this BaseItem.
   */
  label: _propTypes2.default.string,

  /**
   * A long variant of the label to show on this BaseItem. This will only be shown above narrow breakpoints, and will override `label`.
   */
  longLabel: _propTypes2.default.string,

  /**
   * Narrow variant of the menu on desktop. Used for single column menus. If this is omitted, `BaseItem` will do its best to set this automatically.
   */
  narrow: _propTypes2.default.bool,

  /**
   * On click function. Cannot be used when BaseItem contains children.
   */
  onClick: _propTypes2.default.func
};
exports.default = BaseItem;