"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _componentModal = require("@wel-ui/component-modal");

var _componentModal2 = _interopRequireDefault(_componentModal);

var _componentAjaxContent = require("@wel-ui/component-ajax-content");

var _componentAjaxContent2 = _interopRequireDefault(_componentAjaxContent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Anchor = function (_Component) {
  _inherits(Anchor, _Component);

  function Anchor(props) {
    _classCallCheck(this, Anchor);

    var _this = _possibleConstructorReturn(this, (Anchor.__proto__ || Object.getPrototypeOf(Anchor)).call(this, props));

    _this.handleCloseModal = function () {
      _this.setState({ isModalOpen: false });
    };

    _this.handleOnClick = function (event) {
      if (_this.props.onClick) {
        event.preventDefault();

        _this.props.onClick();

        return;
      }

      if (_this.props.contentLocation) {
        event.preventDefault();

        _this.setState({ isModalOpen: true });
      }
    };

    _this.state = { isModalOpen: false };

    _this.getClassName = (0, _utilityClassNames2.default)(Anchor.displayName);
    return _this;
  }

  _createClass(Anchor, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          className = _props.className,
          contentLocation = _props.contentLocation,
          disabled = _props.disabled,
          download = _props.download,
          fallbackElementTag = _props.fallbackElementTag,
          href = _props.href,
          kind = _props.kind,
          modalLabel = _props.modalLabel,
          newWindow = _props.newWindow,
          onChange = _props.onChange,
          onClick = _props.onClick,
          children = _props.children;


      var elementProps = {};
      var Element = void 0;

      if (kind === "link") {
        Element = fallbackElementTag;
        if (href) {
          Element = "a";
          elementProps.href = href;
          elementProps.download = download;
        }

        if (newWindow) {
          elementProps.target = "_blank";
          elementProps.rel = "noopener";
        }
      } else {
        Element = "button";
        elementProps.type = kind;
        elementProps.disabled = disabled;
      }

      var shouldRehydrate = Boolean(contentLocation);

      /**
       * `data-content-modal` provides us an identifier which
       * the wel-trdotcom global modal click handlers can
       * match against.
       */

      if (shouldRehydrate) {
        elementProps["data-content-location"] = contentLocation;
        elementProps["data-modal-label"] = modalLabel;
        elementProps["data-content-modal"] = true;
      }

      var shouldHandleClick = contentLocation || onClick;

      if (shouldHandleClick) {
        elementProps.onClick = this.handleOnClick;
      }

      return _react2.default.createElement(
        Element,
        _extends({
          className: this.getClassName() + " " + className
        }, elementProps),
        children,
        this.state.isModalOpen && _react2.default.createElement(
          _componentModal2.default,
          {
            ariaLabel: modalLabel,
            closeLabel: "Close",
            closeThisComponent: this.handleCloseModal
          },
          _react2.default.createElement(_componentAjaxContent2.default, {
            failedContent: "Failed to retrieve content",
            loadingLabel: "Loading",
            location: contentLocation,
            onChange: onChange
          })
        )
      );
    }
  }]);

  return Anchor;
}(_react.Component);

Anchor.displayName = "Anchor";
Anchor.propTypes = {
  /**
   * Component className, required. This is a utility component
   * it falls outside of the WEL composition model and so, applies
   * only functionality, zero styling.
   */
  className: _propTypes2.default.string.isRequired,

  /**
   * Children
   */
  children: _propTypes2.default.node.isRequired,

  /**
   * content location to AJAX load content into modal, should provide href
   * for a fallback where possible.
   */
  contentLocation: _propTypes2.default.string,

  /**
   * Instruct the browser to download the link URL instead of navigating to it.
   * If the prop is given a string value, it is used as the pre-filled file name
   * in the Save prompt. only works where `kind=link`
   */
  download: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.string]),

  /**
   * disabled, only works where `kind=button`
   */
  disabled: _propTypes2.default.bool,

  /**
   * if no href is provided, what element tag to fallback to.
   */
  fallbackElementTag: _propTypes2.default.oneOf(["span", "div"]),

  /**
   * anchor href, if used the element will always be an `<a>` tag.
   */
  href: _propTypes2.default.string,

  /**
   * Aria label for modal dialog, required if `contentLocation` is defined.
   */
  modalLabel: _propTypes2.default.string,

  /**
   * kind of link/button, defaults to `link`
   */
  kind: _propTypes2.default.oneOf(["link", "button", "submit"]),

  /**
   * Add an onChange callback. Called on loading new AJAX content
   */
  onChange: _propTypes2.default.func,

  /**
   * onClick callback, if this is defined `contentLocation` for modal windows
   * will be ignored.
   */
  onClick: _propTypes2.default.func,

  /**
   * Open link in new tab/window.
   */
  newWindow: _propTypes2.default.bool
};
Anchor.defaultProps = {
  kind: "link",
  fallbackElementTag: "span"
};
exports.default = Anchor;