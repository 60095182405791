"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _utilityClassNames = require("@wel-ui/utility-class-names");

var _utilityClassNames2 = _interopRequireDefault(_utilityClassNames);

var _cross = require("@wel-ui/icons/lib/ui/cross");

var _cross2 = _interopRequireDefault(_cross);

var _Link = require("../Link");

var _Link2 = _interopRequireDefault(_Link);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SelectedItem = function SelectedItem(_ref) {
  var children = _ref.children,
      href = _ref.href;

  var getClassName = (0, _utilityClassNames2.default)("SearchFiltersSelectedItem");

  return _react2.default.createElement(
    "li",
    { className: getClassName() },
    _react2.default.createElement(
      "div",
      { className: getClassName({ descendantName: "title" }) },
      _react2.default.createElement(
        _Link2.default,
        null,
        children
      )
    ),
    _react2.default.createElement(
      "a",
      { href: href, className: getClassName({ descendantName: "remove" }) },
      _react2.default.createElement(_cross2.default, { label: "Remove filter" })
    )
  );
};

SelectedItem.displayName = "SearchFilters.SelectedItem";

SelectedItem.propTypes = {
  /**
   * The destination of this `SelectedItem`.
   */
  href: _propTypes2.default.string,

  /**
   * The title to display
   */
  children: _propTypes2.default.node
};

exports.default = SelectedItem;